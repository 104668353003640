import React from 'react';
import Loadable from 'react-loadable';

import Loading from 'skd-ui/Loading/Loading';

export default Loadable({
    loader: () => {
        require('leaflet/dist/leaflet.css');
        require('leaflet.markercluster/dist/MarkerCluster.css');
        require('leaflet.markercluster/dist/MarkerCluster.Default.css');
        require('src/controllers/Map/Map.scss');
        return import(/*webpackChunkName: 'Map'*/'src/controllers/Map/Map');
    },
    loading: Loading,
});
