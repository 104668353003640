import {
    ReactElement,
} from 'react';

// do not rename! this enum values used in app/components/Alerts/Alerts.scss
export enum AlertState {
    Ok = 'ok',
    Warn = 'warn',
    Error = 'error',
}

export interface IAlert {
    id?: string; // need to remove alert
    header?: string;
    timeoutToHide?: number;
    text?: string;
    children?: ReactElement;
    state: AlertState;
    autohide?: boolean;
}

export enum AlertActions {
    Add = 'alerts_add',
    Remove = 'alerts_remove',
}

