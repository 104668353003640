export enum ToolbarEnv {
    Home = 'Home',
    LogIn = 'LogIn',
    SignUp = 'SignUp',
    LogOut = 'LogOut',
    CreateUser = 'CreateUser',
    API = 'API',
    Orders = 'Orders',
    Accounting = 'Accounting',
    CustomPrices = 'CustomPrices',
    Users = 'Users',
    Account = 'Account',
    Analytics = 'Analytics',
    Help = 'Help',
    Map = 'Map',
}
