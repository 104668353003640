import 'src/components/Alerts/Alerts.scss';

import {
    AlertState,
} from 'src/types/alerts';

import React from 'react';
import {
    observer,
} from 'mobx-react-lite';

import {
    defaultTimeoutToHide,
} from 'src/constants/alerts';

import Toast from 'react-bootstrap/Toast';
import ToastHeader from 'react-bootstrap/ToastHeader';
import ToastBody from 'react-bootstrap/ToastBody';

import AlertsStore from 'src/stores/Alerts';

const b = require('b_').with('alerts');

function _Alerts() {
    const mobxAlerts = AlertsStore.alerts;

    return (
        <div className={b()}>
            {mobxAlerts.map(alert => {
                const {
                    id,
                    state,
                    header,
                    text,
                    children,
                    timeoutToHide,
                    autohide,
                } = alert;

                const toastProps: any = {
                    animation: true,
                    autohide: typeof autohide === 'boolean' ? autohide : state !== AlertState.Error,
                    delay: timeoutToHide || defaultTimeoutToHide,
                    onClose: () => AlertsStore.removeAlert(id),
                };

                return (
                    <div className={b('alert', {[`type-${state}`]: true})} key={id}>
                        <Toast {...toastProps}>
                            <ToastHeader>
                                <strong>
                                    {header}
                                </strong>
                            </ToastHeader>
                            <ToastBody>
                                <>
                                    {text && text}
                                    {children && children}
                                </>
                            </ToastBody>
                        </Toast>
                    </div>
                );
            })}
        </div>
    );
}

const Alerts = observer(_Alerts);
export default Alerts;
