import 'skd-ui/Dropdown/Dropdown.scss';

import {
    IProps,
    IDropdownOption,
    DropdownValue,
} from 'skd-ui/Dropdown/Dropdown.types';

import React, {
    ChangeEvent,
} from 'react';

export {
    IDropdownOption,
    IProps as DropdownProps,
};

const b = require('b_').with('dropdown');
// todo: optgroup для группировки
export default function Dropdown(props: IProps) {
    const {
        options,
        readOnly = false,
        value,
        returnType,
        required = false,
        emptyOptionName = '',
        label = '',
        dataTest,
    } = props;

    function handleChange(event: ChangeEvent<HTMLSelectElement>) {
        const {
            // always string
            value,
        } = event.target;

        if (value && emptyOptionName && value === emptyOptionName) {
            return props.onChange(null);
        }

        if (returnType) {
            switch (returnType) {
                case 'string':
                    return props.onChange(value);

                case 'number':
                    return props.onChange(Number(value));

                case 'boolean':
                    return props.onChange(Boolean(Number(value)));
            }
        }

        if (typeof props.value === 'boolean') {
            return props.onChange(Boolean(Number(value)));
        }
        if (typeof props.value === 'number') {
            return props.onChange(Number(value));
        }
        if (value === '') {
            return props.onChange(null);
        }
        props.onChange(value);
    }

    const normalizedValue = normalizeValue(value);

    const selectProps = {
        className: b(label ? 'select' : '', {'read-only': readOnly, required, 'empty-value': !normalizedValue}),
        onChange: handleChange,
        disabled: readOnly,
        value: normalizedValue,
        'data-test': dataTest ? `select-${dataTest}` : undefined,
    };

    const shouldRenderEmptyOption = props.shouldRenderEmptyOption || !normalizedValue;

    const selectComponent = (
        <select {...selectProps}>
            {shouldRenderEmptyOption && (
                <option
                    className={b('empty-option')}
                    data-test="empty-option"
                >
                    {emptyOptionName}
                </option>
            )}
            {options.map(({value, name}) => (
                <option
                    key={normalizeValue(value)}
                    value={normalizeValue(value)}
                    data-test={`${value}-option`}
                >
                    {name}
                </option>
            ))}
        </select>
    );

    if (!label) {
        return selectComponent;
    }

    return (
        <>
            <label>{label}</label>
            {selectComponent}
        </>
    );
}

function normalizeValue(value: DropdownValue) {
    if (typeof value === 'boolean' || typeof value === 'number') {
        return Number(value);
    }
    if (value === null || value === 'null' || value === 'NaN' || typeof value === 'undefined' || value === 'undefined') {
        return '';
    }
    return value;
}
