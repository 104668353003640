import {
    WarehouseType,
    VALID_WAREHOUSE_TYPE_VALUES,
} from 'backend2/types/warehouses';
import {
    IShortOptionValueToFullOptionValues,
} from 'backend2/interfaces/common';
import {
    IFullOrder,
} from 'backend2/interfaces/orders';

import _ from 'lodash';

export enum OrderType {
    Delivery = 'delivery',
    Pickup = 'pickup',
    Fulfillment = 'fulfillment',
}

export enum OrderKind {
    DeliveryWarehouseWarehouse = 'delivery/warehouse-warehouse',
    DeliveryWarehouseDoor = 'delivery/warehouse-door',
    DeliveryDoorDoor = 'delivery/door-door',
    PickupSkd = 'pickup/skd',
    FulfillmentCDEK = 'fulfillment/CDEK',
    FulfillmentPochta = 'fulfillment/pochta',
}

// contract with database: orderType/orderStatusStage/orderStatusType/orderStatusKind?
export enum OrderStatus {
    // Ожидает подтверждения
    CommonProcessingInitial = 'common/processing/initial',
    // Отказ на этапе подтверждения
    CommonProcessingRefusal = 'common/processing/refusal',
    // Отказ с тарификацией на этапе подтверждения
    CommonProcessingRefusalWithBilling = 'common/processing/refusal/with_billing',
    // Отказ без тарификации на этапе подтверждения
    CommonProcessingRefusalWithoutBilling = 'common/processing/refusal/without_billing',
    // Отмена на этапе подтверждения
    CommonProcessingCancel = 'common/processing/cancel',
    // Отмена с тарификацией на этапе подтверждения
    CommonProcessingCancelWithBilling = 'common/processing/cancel/with_billing',
    // Отмена без тарификации на этапе подтверждения
    CommonProcessingCancelWithoutBilling = 'common/processing/cancel/without_billing',
    // Недозвон на этапе подтверждения
    CommonProcessingCouldNotReach = 'common/processing/could_not_reach',
    // Недозвон с тарификацией на этапе подтверждения
    CommonProcessingCouldNotReachWithBilling = 'common/processing/could_not_reach/with_billing',
    // Недозвон без тарификации на этапе подтверждения
    CommonProcessingCouldNotReachWithoutBilling = 'common/processing/could_not_reach/without_billing',
    // Принят
    CommonProcessingAccepted = 'common/processing/accepted',

    // В пути к отправителю
    DeliveryTakingOnTheWay = 'delivery/taking/on_the_way',
    // Отказ на этапе забора
    DeliveryTakingRefusal = 'delivery/taking/refusal',
    // Отказ с тарификацией на этапе забора
    DeliveryTakingRefusalWithBilling = 'delivery/taking/refusal/with_billing',
    // Отказ без тарификации на этапе забора
    DeliveryTakingRefusalWithoutBilling = 'delivery/taking/refusal/without_billing',
    // Отмена на этапе забора
    DeliveryTakingCancel = 'delivery/taking/cancel',
    // Отмена с тарификацией на этапе забора
    DeliveryTakingCancelWithBilling = 'delivery/taking/cancel/with_billing',
    // Отмена без тарификации на этапе забора
    DeliveryTakingCancelWithoutBilling = 'delivery/taking/cancel/without_billing',
    // Недозвон на этапе забора
    DeliveryTakingCouldNotReach = 'delivery/taking/could_not_reach',
    // Недозвон с тарификацией на этапе забора
    DeliveryTakingCouldNotReachWithBilling = 'delivery/taking/could_not_reach/with_billing',
    // Недозвон без тарификации на этапе забора
    DeliveryTakingCouldNotReachWithoutBilling = 'delivery/taking/could_not_reach/without_billing',
    // Получен курьером
    DeliveryTakingDone = 'delivery/taking/done',

    // В пути к получателю
    DeliveryDeliveryOnTheWay = 'delivery/delivery/on_the_way',
    // Отказ на этапе доставки
    DeliveryDeliveryRefusal = 'delivery/delivery/refusal',
    // Отказ с тарификацией на этапе доставки
    DeliveryDeliveryRefusalWithBilling = 'delivery/delivery/refusal/with_billing',
    // Отказ без тарификации на этапе доставки
    DeliveryDeliveryRefusalWithoutBilling = 'delivery/delivery/refusal/without_billing',
    // Отмена на этапе доставки
    DeliveryDeliveryCancel = 'delivery/delivery/cancel',
    // Отмена с тарификацией на этапе доставки
    DeliveryDeliveryCancelWithBilling = 'delivery/delivery/cancel/with_billing',
    // Отмена без тарификации на этапе доставки
    DeliveryDeliveryCancelWithoutBilling = 'delivery/delivery/cancel/without_billing',
    // Недозвон на этапе доставки
    DeliveryDeliveryCouldNotReach = 'delivery/delivery/could_not_reach',
    // Недозвон с тарификацией на этапе доставки
    DeliveryDeliveryCouldNotReachWithBilling = 'delivery/delivery/could_not_reach/with_billing',
    // Недозвон без тарификации на этапе доставки
    DeliveryDeliveryCouldNotReachWithoutBilling = 'delivery/delivery/could_not_reach/without_billing',
    // Доставлено
    DeliveryDeliveryDone = 'delivery/delivery/done',
    // Доставлено без оплаты
    DeliveryDeliveryDoneWithoutPayment = 'delivery/delivery/done/without_payment',
    // Доставлено полный выкуп
    DeliveryDeliveryDoneFullRedemption = 'delivery/delivery/done/full_redemption',
    // Доставлено частичный выкуп
    DeliveryDeliveryDonePartialRedemption = 'delivery/delivery/done/partial_redemption',

    // В пути на склад
    PickupTakingOnTheWay = 'pickup/taking/on_the_way',
    // Прибыл на склад
    PickupTakingDone = 'pickup/taking/done',
    // Отмена на этапе забора
    PickupTakingCancel = 'pickup/taking/cancel',
    // Отмена с тарификацией на этапе забора
    PickupTakingCancelWithBilling = 'pickup/taking/cancel/with_billing',
    // Отмена без тарификации на этапе забора
    PickupTakingCancelWithoutBilling = 'pickup/taking/cancel/without_billing',

    // Готов к выдаче
    PickupDeliveryWaitClient = 'pickup/issuing/wait_client',
    // Отказ на этапе выдачи
    PickupDeliveryRefusal = 'pickup/issuing/refusal',
    // Отказ с тарификацией на этапе выдачи
    PickupDeliveryRefusalWithBilling = 'pickup/issuing/refusal/with_billing',
    // Отказ без тарификацией на этапе выдачи
    PickupDeliveryRefusalWithoutBilling = 'pickup/issuing/refusal/without_billing',
    // Отмена на этапе выдачи
    PickupDeliveryCancel = 'pickup/issuing/cancel',
    // Отмена с тарификацией на этапе выдачи
    PickupDeliveryCancelWithBilling = 'pickup/issuing/cancel/with_billing',
    // Отмена без тарификации на этапе выдачи
    PickupDeliveryCancelWithoutBilling = 'pickup/issuing/cancel/without_billing',
    // Недозвон на этапе выдачи
    PickupDeliveryCouldNotReach = 'pickup/issuing/could_not_reach',
    // Недозвон с тарификацией на этапе выдачи
    PickupDeliveryCouldNotReachWithBilling = 'pickup/issuing/could_not_reach/with_billing',
    // Недозвон без тарификации на этапе выдачи
    PickupDeliveryCouldNotReachWithoutBilling = 'pickup/issuing/could_not_reach/without_billing',
    // Выдано
    PickupDeliveryDone = 'pickup/issuing/done',
    // Выдано без оплаты
    PickupDeliveryDoneWithoutPayment = 'pickup/issuing/done/without_payment',
    // Выдано полный выкуп
    PickupDeliveryDoneFullRedemption = 'pickup/issuing/done/full_redemption',
    // Выдано частичный выкуп
    PickupDeliveryDonePartialRedemption = 'pickup/issuing/done/partial_redemption',

    // Сборка заказа
    FulfillmentAssemblingAssembling = 'fulfillment/assembling/assembling',
    // Отмена на этапе сборки
    FulfillmentAssemblingCancel = 'fulfillment/assembling/cancel',
    // Отмена с тарификацией на этапе сборки
    FulfillmentAssemblingCancelWithBilling = 'fulfillment/assembling/cancel/with_billing',
    // Отмена без тарификации на этапе сборки
    FulfillmentAssemblingCancelWithoutBilling = 'fulfillment/assembling/cancel/without_billing',
    // Готов к оправке
    FulfillmentAssemblingDone = 'fulfillment/assembling/done',

    // Отправлено
    FulfillmentSending = 'fulfillment/sending/sent_by',
    // Вернулся отправителю
    FulfillmentSendingReturned = 'fulfillment/sending/returned',
    // Отмена на этапе отправки
    FulfillmentSendingCancel = 'fulfillment/sending/cancel',
    // Отмена с тарификацией на этапе отправки
    FulfillmentSendingCancelWithBilling = 'fulfillment/sending/cancel/with_billing',
    // Отмена без тарификации на этапе отправки
    FulfillmentSendingWithoutBilling = 'fulfillment/sending/cancel/without_billing',
    // Доставлен партнером
    FulfillmentSendingDone = 'fulfillment/sending/done',
    // Доставлен партнером без оплаты
    FulfillmentSendingDoneWithoutPayment = 'fulfillment/sending/done/without_payment',
    // Доставлен партнером полный выкуп
    FulfillmentSendingDoneFullRedemption = 'fulfillment/sending/done/full_redemption',
    // Доставлен партнером частичный выкуп
    FulfillmentSendingDonePartialRedemption = 'fulfillment/sending/done/partial_redemption',
}

// todo: extend enum from OrderType
export enum OrderStatusOrderType {
    Common = 'common',
    Delivery = 'delivery',
    Pickup = 'pickup',
    Fulfillment = 'fulfillment',
}
export enum OrderStatusStage {
    Processing = 'processing',
    Taking = 'taking',
    Delivery = 'delivery',
    Issuing = 'issuing',
    Assembling = 'assembling',
    Sending = 'sending',
}
export enum OrderStatusStatusType {
    Initial = 'initial',
    // Отмена(всегда со стороны skd или заказчика)
    Cancel = 'cancel',
    Accepted = 'accepted',
    OnTheWay = 'on_the_way',
    Done = 'done',
    // Отказ(всегда со стороны клиента)
    Refusal = 'refusal',
    CouldNotReach = 'could_not_reach',
    WaitClient = 'wait_client',
    Assembling = 'assembling',
    SentBy = 'sent_by',
    Returned = 'returned',
}
export enum OrderStatusStatusKind {
    WithBilling = 'with_billing',
    WithoutBilling = 'without_billing',
    OnArrival = 'on_arrival',
    BeforeArrival = 'before_arrival',
    WithoutPayment = 'without_payment',
    FullRedemption = 'full_redemption',
    PartialRedemption = 'partial_redemption',
}

export enum StatusTrigger {
    // Проставить "ожидаемый наложенный платеж" в "актуальный наложенный платеж"
    SetOrderActualCashOnDeliveryToFormalized = 'SET_ORDER_ACTUAL_CASH_ON_DELIVERY_TO_FORMALIZED',
    // Проставить "актуальный наложенный платеж" в 0
    SetOrderActualCashOnDeliveryToZero = 'SET_ORDER_ACTUAL_CASH_ON_DELIVERY_TO_ZERO',
    // Проставить "страховой сбор" в 0
    SetOrderInsuranceFeeToZero = 'SET_ORDER_INSURANCE_FEE_TO_ZERO',
    // Проставить "РКО" в 0
    SetOrderRKOToZero = 'SET_ORDER_RKO_TO_ZERO',
    // Проставить "стоимость" в 0
    SetOrderCostToZero = 'SET_ORDER_COST_TO_ZERO',
    // Проставить "оплата курьера" в 0
    SetOrderPaymentToExecutorToZero = 'SET_ORDER_PAYMENT_TO_EXECUTOR_TO_ZERO',
    // Проставить "стоимость сторонней КС" в 0
    SetOrderExternalCostToZero = 'SET_ORDER_EXTERNAL_COST_TO_ZERO',
    // Проставить "страховой сбор сторонней КС" в 0
    SetOrderExternalInsuranceFeeToZero = 'SET_ORDER_EXTERNAL_INSURANCE_FEE_TO_ZERO',
    // Проставить "РКО сторонней КС" в 0
    SetOrderExternalRKOToZero = 'SET_ORDER_EXTERNAL_RKO_TO_ZERO',
    // Закрыть заказ
    CloseOrder = 'CLOSE_ORDER',
    // Уведомить сообщением в телеграм-группу "Сопровождение"
    NotifyToCouriersEscortGroup = 'NOTIFY_TO_COURIERS_ESCORT_GROUP',
    // Сбросить исполнителя заказа
    ResetExecutor = 'RESET_EXECUTOR',
}

export enum OrderUrgency {
    Urgently = 'urgently',
    DayToDay = 'day_to_day',
    PerDay = 'per_day',
    Economy = 'economy',
}

export enum OrderLocation {
    Moscow = 'moscow',
    MetroOutsideTheMKAD = 'metro_outside_the_MKAD',
    MoscowRegion = 'moscow_region',
}

export const VALID_ORDER_TYPE_VALUES = Object.values(OrderType);
export const VALID_ORDER_KIND_VALUES = Object.values(OrderKind);
export const VALID_ORDER_STATUS_VALUES = Object.values(OrderStatus);
export const VALID_ORDER_URGENCY_VALUES = Object.values(OrderUrgency);
export const VALID_ORDER_LOCATION_VALUES = Object.values(OrderLocation);

export const VALID_DELIVERY_TYPE_KINDS = [
    OrderKind.DeliveryWarehouseWarehouse,
    OrderKind.DeliveryWarehouseDoor,
    OrderKind.DeliveryDoorDoor,
];
export const VALID_PICKUP_TYPE_KINDS = [
    OrderKind.PickupSkd,
];
export const VALID_FULFILLMENT_TYPE_KINDS = [
    OrderKind.FulfillmentCDEK,
    OrderKind.FulfillmentPochta,
];

export enum GetOrdersDetailType {
    Default = 'all',
    Common = 'common',
    Type = 'type',
    All = 'all',
    OnlyIds = 'onlyIds',
}

export const VALID_GET_ORDERS_DETAIL_TYPES_VALUES = _.uniq(Object.values(GetOrdersDetailType));

export const VALID_ORDER_DELIVERY_WAREHOUSE_TYPES_VALUES = VALID_WAREHOUSE_TYPE_VALUES;
export const VALID_ORDER_PICKUP_WAREHOUSE_TYPES_VALUES = [
    WarehouseType.PVZ,
];
export const VALID_ORDER_FULFILLMENT_WAREHOUSE_TYPES_VALUES = [
    WarehouseType.PVZ,
    WarehouseType.Skd,
];

export const VALID_ORDER_DELIVERY_KIND_HAS_TAKING_WAREHOUSE_VALUES = [
    OrderKind.DeliveryWarehouseWarehouse,
    OrderKind.DeliveryWarehouseDoor,
    OrderKind.DeliveryDoorDoor,
];
export const VALID_ORDER_DELIVERY_KIND_HAS_DELIVERY_WAREHOUSE_VALUES = [
    OrderKind.DeliveryWarehouseWarehouse,
];

export type StatusesTransitionsConfig = Record<
    OrderStatus,
    {
        // в конфиг предыдущего на проверку отправлять следующий статус
        possibleTransitions: OrderStatus[];
        // в конфиг предыдущего на проверку отправлять следующий статус
        throwErrorIfNextStatusNotValid?: (nextOrderStatus: OrderStatus, fullOrder: IFullOrder) => void;
        // в конфиге следующего статуса вызывать эту проверку
        throwErrorIfOrderNotReadyToTransitThisStatus?: (fullOrder: IFullOrder) => void;
        // в конфиг следующего на проверку отправлять предыдущее состояние заказа(предыдущий статус)
        tryAddKindToStatus?: (prevFullOrder: IFullOrder) => OrderStatus;
        // после апгрейда выполнить триггеры
        triggers?: StatusTrigger[];
        mustSpecifyComment?: boolean;
    }
>;

export enum Field {
    OrderId = 'order.id',
    OrderTypeEnum = 'order.typeEnum',
    OrderKindEnum = 'order.kindEnum',
    OrderCustomKindId = 'order.customKindId',
    OrderExternalId = 'order.externalId',
    OrderCustomerUserId = 'order.customerUserId',
    OrderStatusEnum = 'order.statusEnum',
    OrderStatusComment = 'order.statusComment',
    OrderWeight = 'order.weight',
    OrderCost = 'order.cost',
    OrderInsuranceFee = 'order.insuranceFee',
    OrderRKO = 'order.rko',
    OrderShouldAutoUpdateCost = 'order.shouldAutoUpdateCost',
    OrderShouldAutoUpdateInsuranceFee = 'order.shouldAutoUpdateInsuranceFee',
    OrderShouldAutoUpdateRKO = 'order.shouldAutoUpdateRko',
    OrderFormalizedCashOnDelivery = 'order.formalizedCashOnDelivery',
    OrderActualCashOnDelivery = 'order.actualCashOnDelivery',
    OrderAnnouncedValue = 'order.announcedValue',
    OrderAdditionalServices = 'order.additionalServices',
    OrderComposition = 'order.composition',
    OrderFirstClosingTimestamp = 'order.firstClosingTimestamp',
    OrderClosingTimestamp = 'order.closingTimestamp',
    OrderIsClose = 'order.isClose',
    OrderSettlementDate = 'order.settlementDate',
    OrderCustomerComment = 'order.customerComment',
    OrderCourierComment = 'order.courierComment',
    OrderSkdStaffComment = 'order.skdStaffComment',
    OrderTechnicalComment = 'order.technicalComment',
    OrderIsRemoved = 'order.isRemoved',
    OrderCreatedByUserId = 'order.createdByUserId',
    OrderCreatedTimestamp = 'order.createdTimestamp',
    OrderIsVersionOne = 'order.isVersionOne',

    DeliveryOrderId = 'delivery.orderId',
    DeliveryExecutorUserId = 'delivery.executorUserId',
    DeliveryPaymentToExecutor = 'delivery.paymentToExecutor',
    DeliveryUrgencyEnum = 'delivery.urgencyEnum',
    DeliveryShouldAutoUpdatePaymentToExecutor = 'delivery.shouldAutoUpdatePaymentToExecutor',
    DeliveryShouldAutoUpdateUrgencyEnum = 'delivery.shouldAutoUpdateUrgencyEnum',
    DeliveryTakingLocationEnum = 'delivery.takingLocationEnum', DeliveryTakingLocationEnumReq = 'delivery.takingAddressData.locationEnum',
    DeliveryTakingSubway = 'delivery.takingSubway', DeliveryTakingSubwayReq = 'delivery.takingAddressData.subway',
    DeliveryTakingAddress = 'delivery.takingAddress', DeliveryTakingAddressReq = 'delivery.takingAddressData.address',
    DeliveryTakingAddressComment = 'delivery.takingAddressComment', DeliveryTakingAddressCommentReq = 'delivery.takingAddressData.addressComment',
    DeliverySenderName = 'delivery.senderName', DeliverySenderNameReq = 'delivery.takingAddressData.senderOrReceiverName',
    DeliverySenderPhone = 'delivery.senderPhone', DeliverySenderPhoneReq = 'delivery.takingAddressData.senderOrReceiverPhone',
    DeliveryTakingWarehouseId = 'delivery.takingWarehouseId',
    DeliveryTakingLatitude = 'delivery.takingLatitude', DeliveryTakingLatitudeReq = 'delivery.takingAddressData.latitude',
    DeliveryTakingLongitude = 'delivery.takingLongitude', DeliveryTakingLongitudeReq = 'delivery.takingAddressData.longitude',
    DeliveryTakingSubwayDistanceKilometers = 'delivery.takingSubwayDistanceKilometers',

    DeliveryDeliveryLocationEnum = 'delivery.deliveryLocationEnum', DeliveryDeliveryLocationEnumReq = 'delivery.deliveryAddressData.locationEnum',
    DeliveryDeliverySubway = 'delivery.deliverySubway', DeliveryDeliverySubwayReq = 'delivery.deliveryAddressData.subway',
    DeliveryDeliveryAddress = 'delivery.deliveryAddress', DeliveryDeliveryAddressReq = 'delivery.deliveryAddressData.address',
    DeliveryDeliveryAddressComment = 'delivery.deliveryAddressComment', DeliveryDeliveryAddressCommentReq = 'delivery.deliveryAddressData.addressComment',
    DeliveryReceiverName = 'delivery.receiverName', DeliveryReceiverNameReq = 'delivery.deliveryAddressData.senderOrReceiverName',
    DeliveryReceiverPhone = 'delivery.receiverPhone', DeliveryReceiverPhoneReq = 'delivery.deliveryAddressData.senderOrReceiverPhone',
    DeliveryDeliveryWarehouseId = 'delivery.deliveryWarehouseId',
    DeliveryDeliveryLatitude = 'delivery.deliveryLatitude', DeliveryDeliveryLatitudeReq = 'delivery.deliveryAddressData.latitude',
    DeliveryDeliveryLongitude = 'delivery.deliveryLongitude', DeliveryDeliveryLongitudeReq = 'delivery.deliveryAddressData.longitude',
    DeliveryDeliverySubwayDistanceKilometers = 'delivery.deliverySubwayDistanceKilometers',

    DeliveryDistanceKilometers = 'delivery.distanceKilometers',

    DeliveryTakingFrom = 'delivery.takingFrom',
    DeliveryTakingTo = 'delivery.takingTo',
    DeliveryDeliveryFrom = 'delivery.deliveryFrom',
    DeliveryDeliveryTo = 'delivery.deliveryTo',

    PickupOrderId = 'pickup.orderId',
    PickupIssuedByUserId = 'pickup.issuedByUserId',
    PickupWarehouseId = 'pickup.warehouseId',
    PickupClientName = 'pickup.clientName',
    PickupClientPhone = 'pickup.clientPhone',
    PickupStoreUp = 'pickup.storeUp',

    FulfillmentOrderId = 'fulfillment.orderId',
    FulfillmentIssuedByUserId = 'fulfillment.issuedByUserId',
    FulfillmentWarehouseId = 'fulfillment.warehouseId',
    FulfillmentExternalTrackId = 'fulfillment.externalTrackId',
    FulfillmentExternalStatus = 'fulfillment.externalStatus',
    FulfillmentExternalTariffName = 'fulfillment.externalTariffName',
    FulfillmentExternalDeliveryCost = 'fulfillment.externalDeliveryCost',
    FulfillmentExternalActualDeliveryCost = 'fulfillment.externalActualDeliveryCost',
    FulfillmentExternalCarrierCommissionInsuranceFee = 'fulfillment.externalCarrierCommissionInsuranceFee',
    FulfillmentExternalCarrierCommissionRko = 'fulfillment.externalCarrierCommissionRko',
    FulfillmentExternalCarrierCommissionAdditionalServices = 'fulfillment.externalCarrierCommissionAdditionalServices',
    FulfillmentDeliveryAddress = 'fulfillment.deliveryAddress',
    FulfillmentReceiverName = 'fulfillment.receiverName',
    FulfillmentReceiverPhone = 'fulfillment.receiverPhone',
    FulfillmentDepartureDate = 'fulfillment.departureDate',
}

export const VALID_ORDER_FIELD_VALUES = Object.values(Field);
// todo: send all enums, dropdown logic and trarnslate from backend
export const ORDER_FIELD_DICTIONARY: Record<'ru', Record<Field, string>> = {
    ru: {
        [Field.OrderId]: 'id',
        [Field.OrderTypeEnum]: 'Тип заказа',
        [Field.OrderKindEnum]: 'Вид',
        [Field.OrderCustomKindId]: 'Кастомный вид',
        [Field.OrderExternalId]: 'Внешний id',
        [Field.OrderCustomerUserId]: 'Заказчик',
        [Field.OrderStatusEnum]: 'Статус',
        [Field.OrderStatusComment]: 'Коммент к статусу',
        [Field.OrderWeight]: 'Вес',
        [Field.OrderCost]: 'Стоимость',
        [Field.OrderInsuranceFee]: 'Страховой сбор',
        [Field.OrderRKO]: 'РКО',
        [Field.OrderShouldAutoUpdateCost]: 'Считать ли автоматически стоимость',
        [Field.OrderShouldAutoUpdateInsuranceFee]: 'Считать ли автоматически страховой сбор',
        [Field.OrderShouldAutoUpdateRKO]: 'Считать ли автоматически РКО',
        [Field.OrderFormalizedCashOnDelivery]: 'Ожидаемый наложенный платеж',
        [Field.OrderActualCashOnDelivery]: 'Фактический наложенный платеж',
        [Field.OrderAnnouncedValue]: 'Объявленная ценность',
        [Field.OrderAdditionalServices]: 'Доп.услуги',
        [Field.OrderComposition]: 'Состав заказа',
        [Field.OrderFirstClosingTimestamp]: 'Дата, когда в первый закрыли заказ',
        [Field.OrderClosingTimestamp]: 'Дата закрытия',
        [Field.OrderIsClose]: 'Считается ли заказ закрытым ',
        [Field.OrderSettlementDate]: 'Дата учета',
        [Field.OrderCustomerComment]: 'Комментарий заказчика',
        [Field.OrderCourierComment]: 'Комментарий курьера',
        [Field.OrderSkdStaffComment]: 'Комментарий работника SKD',
        [Field.OrderTechnicalComment]: 'Технический комментарий',
        [Field.OrderIsRemoved]: 'Удален ли заказ',
        [Field.OrderCreatedByUserId]: 'Кем создан',
        [Field.OrderCreatedTimestamp]: 'Когда создан',
        [Field.OrderIsVersionOne]: 'Из skd-crm v.1',

        [Field.DeliveryOrderId]: 'id',
        [Field.DeliveryExecutorUserId]: 'Курьер',
        [Field.DeliveryPaymentToExecutor]: 'Оплата курьеру',
        [Field.DeliveryUrgencyEnum]: 'Cрочность',
        [Field.DeliveryShouldAutoUpdatePaymentToExecutor]: 'Считать ли автоматически оплату курьера',
        [Field.DeliveryShouldAutoUpdateUrgencyEnum]: 'Считать ли автоматически срочность',
        [Field.DeliveryTakingLocationEnum]: 'Локация забора', [Field.DeliveryTakingLocationEnumReq]: 'Локация забора',
        [Field.DeliveryTakingSubway]: 'Метро забора', [Field.DeliveryTakingSubwayReq]: 'Метро забора',
        [Field.DeliveryTakingAddress]: 'Адрес забора', [Field.DeliveryTakingAddressReq]: 'Адрес забора',
        [Field.DeliveryTakingAddressComment]: 'Комментарий к адресу забора', [Field.DeliveryTakingAddressCommentReq]: 'Комментарий к адресу забора',
        [Field.DeliverySenderName]: 'Имя отправителя', [Field.DeliverySenderNameReq]: 'Имя отправителя',
        [Field.DeliverySenderPhone]: 'Телефон отправителя', [Field.DeliverySenderPhoneReq]: 'Телефон отправителя',
        [Field.DeliveryTakingWarehouseId]: 'Склад забора',
        [Field.DeliveryTakingLatitude]: 'Долгота забора', [Field.DeliveryTakingLatitudeReq]: 'Долгота забора',
        [Field.DeliveryTakingLongitude]: 'Широта забора', [Field.DeliveryTakingLongitudeReq]: 'Широта забора',
        [Field.DeliveryTakingSubwayDistanceKilometers]: 'Расстояние до метро забора',
        [Field.DeliveryDeliveryLocationEnum]: 'Локация доставки', [Field.DeliveryDeliveryLocationEnumReq]: 'Локация доставки',
        [Field.DeliveryDeliverySubway]: 'Метро доставки', [Field.DeliveryDeliverySubwayReq]: 'Метро доставки',
        [Field.DeliveryDeliveryAddress]: 'Адрес доставки', [Field.DeliveryDeliveryAddressReq]: 'Адрес доставки',
        [Field.DeliveryDeliveryAddressComment]: 'Комментарий к адресу доставки', [Field.DeliveryDeliveryAddressCommentReq]: 'Комментарий к адресу доставки',
        [Field.DeliveryReceiverName]: 'Имя получателя', [Field.DeliveryReceiverNameReq]: 'Имя получателя',
        [Field.DeliveryReceiverPhone]: 'Телефон получателя', [Field.DeliveryReceiverPhoneReq]: 'Телефон получателя',
        [Field.DeliveryDeliveryWarehouseId]: 'Склад доставки',
        [Field.DeliveryDeliveryLatitude]: 'Долгота доставки', [Field.DeliveryDeliveryLatitudeReq]: 'Долгота доставки',
        [Field.DeliveryDeliveryLongitude]: 'Широта доставки', [Field.DeliveryDeliveryLongitudeReq]: 'Широта доставки',
        [Field.DeliveryDeliverySubwayDistanceKilometers]: 'Расстояние до метро доставки',
        [Field.DeliveryDistanceKilometers]: 'Расстояние',
        [Field.DeliveryTakingFrom]: 'Забрать с',
        [Field.DeliveryTakingTo]: 'Забрать до',
        [Field.DeliveryDeliveryFrom]: 'Доставить с',
        [Field.DeliveryDeliveryTo]: 'Доставить до',

        [Field.PickupOrderId]: 'id',
        [Field.PickupIssuedByUserId]: 'Ответственный за выдачу(работник SKD)',
        [Field.PickupWarehouseId]: 'Склад',
        [Field.PickupClientName]: 'Имя клиента',
        [Field.PickupClientPhone]: 'Телефон клиента',
        [Field.PickupStoreUp]: 'Дата хранения',

        [Field.FulfillmentOrderId]: 'id',
        [Field.FulfillmentIssuedByUserId]: 'Ответственный за отправку(работник SKD)',
        [Field.FulfillmentWarehouseId]: 'Склад забора',
        [Field.FulfillmentExternalTrackId]: 'Id сторонней КС',
        [Field.FulfillmentExternalStatus]: 'Статус сторонней КС',
        [Field.FulfillmentExternalTariffName]: 'Название тарифа сторонней КС',
        [Field.FulfillmentExternalDeliveryCost]: 'Стоимость доставки сторонней КС(которое показывается заказчику)',
        [Field.FulfillmentExternalActualDeliveryCost]: 'Стоимость доставки сторонней КС(которое на самом деле для SKD)',
        [Field.FulfillmentExternalCarrierCommissionInsuranceFee]: 'Комиссия сторонней КС по страховому сбору',
        [Field.FulfillmentExternalCarrierCommissionRko]: 'Комиссия сторонней КС по РКО',
        [Field.FulfillmentExternalCarrierCommissionAdditionalServices]: 'Комиссия сторонней КС по дополнительным услугам',
        [Field.FulfillmentDeliveryAddress]: 'Адрес доставки',
        [Field.FulfillmentReceiverName]: 'Имя адресата',
        [Field.FulfillmentReceiverPhone]: 'Телефон адресата',
        [Field.FulfillmentDepartureDate]: 'Дата отправки',
    },
};

export const ORDER_TYPE_ENUM_DICTIONARY: Record<'ru', Record<OrderType, string>> = {
    ru: {
        [OrderType.Delivery]: 'доставка',
        [OrderType.Pickup]: 'самовывоз',
        [OrderType.Fulfillment]: 'фулфилмент',
    },
};

export const ORDER_KIND_ENUM_DICTIONARY: Record<'ru', Record<OrderKind, string>> = {
    ru: {
        [OrderKind.DeliveryWarehouseWarehouse]: 'Склад-склад',
        [OrderKind.DeliveryWarehouseDoor]: 'Склад-дверь',
        [OrderKind.DeliveryDoorDoor]: 'Дверь-дверь',
        [OrderKind.PickupSkd]: 'ПВЗ SKD',
        [OrderKind.FulfillmentCDEK]: 'СДЭК',
        [OrderKind.FulfillmentPochta]: 'Почта',
    },
};

export const ORDER_STATUS_ENUM_DICTIONARY: Record<'ru', Record<OrderStatus, string>> = {
    ru: {
        [OrderStatus.CommonProcessingInitial]: 'Ожидает подтверждения',
        [OrderStatus.CommonProcessingRefusal]: 'Отказ на этапе подтверждения',
        [OrderStatus.CommonProcessingRefusalWithBilling]: 'Отказ с тарификацией на этапе подтверждения',
        [OrderStatus.CommonProcessingRefusalWithoutBilling]: 'Отказ без тарификации на этапе подтверждения',
        [OrderStatus.CommonProcessingCancel]: 'Отмена на этапе подтверждения',
        [OrderStatus.CommonProcessingCancelWithBilling]: 'Отмена с тарификацией на этапе подтверждения',
        [OrderStatus.CommonProcessingCancelWithoutBilling]: 'Отмена без тарификации на этапе подтверждения',
        [OrderStatus.CommonProcessingCouldNotReach]: 'Недозвон на этапе подтверждения',
        [OrderStatus.CommonProcessingCouldNotReachWithBilling]: 'Недозвон с тарификацией на этапе подтверждения',
        [OrderStatus.CommonProcessingCouldNotReachWithoutBilling]: 'Недозвон без тарификации на этапе подтверждения',
        [OrderStatus.CommonProcessingAccepted]: 'Принят',

        [OrderStatus.DeliveryTakingOnTheWay]: 'В пути к отправителю',
        [OrderStatus.DeliveryTakingRefusal]: 'Отказ на этапе забора',
        [OrderStatus.DeliveryTakingRefusalWithBilling]: 'Отказ с тарификацией на этапе забора',
        [OrderStatus.DeliveryTakingRefusalWithoutBilling]: 'Отказ без тарификации на этапе забора',
        [OrderStatus.DeliveryTakingCancel]: 'Отмена на этапе забора',
        [OrderStatus.DeliveryTakingCancelWithBilling]: 'Отмена с тарификацией на этапе забора',
        [OrderStatus.DeliveryTakingCancelWithoutBilling]: 'Отмена без тарификации на этапе забора',
        [OrderStatus.DeliveryTakingCouldNotReach]: 'Недозвон на этапе забора',
        [OrderStatus.DeliveryTakingCouldNotReachWithBilling]: 'Недозвон с тарификацией на этапе забора',
        [OrderStatus.DeliveryTakingCouldNotReachWithoutBilling]: 'Недозвон без тарификации на этапе забора',
        [OrderStatus.DeliveryTakingDone]: 'Получен курьером',

        [OrderStatus.DeliveryDeliveryOnTheWay]: 'В пути к получателю',
        [OrderStatus.DeliveryDeliveryRefusal]: 'Отказ на этапе доставки',
        [OrderStatus.DeliveryDeliveryRefusalWithBilling]: 'Отказ с тарификацией на этапе доставки',
        [OrderStatus.DeliveryDeliveryRefusalWithoutBilling]: 'Отказ без тарификации на этапе доставки',
        [OrderStatus.DeliveryDeliveryCancel]: 'Отмена на этапе доставки',
        [OrderStatus.DeliveryDeliveryCancelWithBilling]: 'Отмена с тарификацией на этапе доставки',
        [OrderStatus.DeliveryDeliveryCancelWithoutBilling]: 'Отмена без тарификации на этапе доставки',
        [OrderStatus.DeliveryDeliveryCouldNotReach]: 'Недозвон на этапе доставки',
        [OrderStatus.DeliveryDeliveryCouldNotReachWithBilling]: 'Недозвон с тарификацией на этапе доставки',
        [OrderStatus.DeliveryDeliveryCouldNotReachWithoutBilling]: 'Недозвон без тарификации на этапе доставки',
        [OrderStatus.DeliveryDeliveryDone]: 'Доставлено',
        [OrderStatus.DeliveryDeliveryDoneWithoutPayment]: 'Доставлено без оплаты',
        [OrderStatus.DeliveryDeliveryDoneFullRedemption]: 'Доставлено полный выкуп',
        [OrderStatus.DeliveryDeliveryDonePartialRedemption]: 'Доставлено частичный выкуп',

        [OrderStatus.PickupTakingOnTheWay]: 'В пути на склад',
        [OrderStatus.PickupTakingDone]: 'Прибыл на склад',
        [OrderStatus.PickupTakingCancel]: 'Отмена на этапе забора',
        [OrderStatus.PickupTakingCancelWithBilling]: 'Отмена с тарификацией на этапе забора',
        [OrderStatus.PickupTakingCancelWithoutBilling]: 'Отмена без тарификации на этапе забора',

        [OrderStatus.PickupDeliveryWaitClient]: 'Готов к выдаче',
        [OrderStatus.PickupDeliveryRefusal]: 'Отказ на этапе выдачи',
        [OrderStatus.PickupDeliveryRefusalWithBilling]: 'Отказ с тарификацией на этапе выдачи',
        [OrderStatus.PickupDeliveryRefusalWithoutBilling]: 'Отказ без тарификации на этапе выдачи',
        [OrderStatus.PickupDeliveryCancel]: 'Отмена на этапе выдачи',
        [OrderStatus.PickupDeliveryCancelWithBilling]: 'Отмена с тарификацией на этапе выдачи',
        [OrderStatus.PickupDeliveryCancelWithoutBilling]: 'Отмена без тарификации на этапе выдачи',
        [OrderStatus.PickupDeliveryCouldNotReach]: 'Недозвон на этапе выдачи',
        [OrderStatus.PickupDeliveryCouldNotReachWithBilling]: 'Недозвон с тарификацией на этапе выдачи',
        [OrderStatus.PickupDeliveryCouldNotReachWithoutBilling]: 'Недозвон без тарификации на этапе выдачи',
        [OrderStatus.PickupDeliveryDone]: 'Выдано',
        [OrderStatus.PickupDeliveryDoneWithoutPayment]: 'Выдано без оплаты',
        [OrderStatus.PickupDeliveryDoneFullRedemption]: 'Выдано полный выкуп',
        [OrderStatus.PickupDeliveryDonePartialRedemption]: 'Выдано частичный выкуп',

        [OrderStatus.FulfillmentAssemblingAssembling]: 'Сборка заказа',
        [OrderStatus.FulfillmentAssemblingCancel]: 'Отмена на этапе сборки',
        [OrderStatus.FulfillmentAssemblingCancelWithBilling]: 'Отмена с тарификацией на этапе сборки',
        [OrderStatus.FulfillmentAssemblingCancelWithoutBilling]: 'Отмена без тарификации на этапе сборки',
        [OrderStatus.FulfillmentAssemblingDone]: 'Готов к оправке',

        [OrderStatus.FulfillmentSending]: 'Отправлено',
        [OrderStatus.FulfillmentSendingReturned]: 'Вернулся отправителю',
        [OrderStatus.FulfillmentSendingCancel]: 'Отмена на этапе отправки',
        [OrderStatus.FulfillmentSendingCancelWithBilling]: 'Отмена с тарификацией на этапе отправки',
        [OrderStatus.FulfillmentSendingWithoutBilling]: 'Отмена без тарификации на этапе отправки',
        [OrderStatus.FulfillmentSendingDone]: 'Доставлен партнером',
        [OrderStatus.FulfillmentSendingDoneWithoutPayment]: 'Доставлен партнером без оплаты',
        [OrderStatus.FulfillmentSendingDoneFullRedemption]: 'Доставлен партнером полный выкуп',
        [OrderStatus.FulfillmentSendingDonePartialRedemption]: 'Доставлен партнером частичный выкуп',
    },
};

export const ORDER_URGENCY_ENUM_DICTIONARY: Record<'ru', Record<OrderUrgency, string>> = {
    ru: {
        [OrderUrgency.Urgently]: 'Срочно',
        [OrderUrgency.DayToDay]: 'День в день',
        [OrderUrgency.PerDay]: 'За день',
        [OrderUrgency.Economy]: 'Эконом',
    },
};

export const ORDER_LOCATION_ENUM_DICTIONARY: Record<'ru', Record<OrderLocation, string>> = {
    ru: {
        [OrderLocation.Moscow]: 'Москва',
        [OrderLocation.MetroOutsideTheMKAD]: 'Метро за МКАД',
        [OrderLocation.MoscowRegion]: 'Московская область',
    },
};

export const ORDER_STATUS_ENUM_SHORT_DICTIONARY: Record<'ru', Record<OrderStatus, string>> = {
    ru: {
        [OrderStatus.CommonProcessingInitial]: 'Ожидает подтверждения',
        [OrderStatus.CommonProcessingRefusal]: 'Отказ',
        [OrderStatus.CommonProcessingRefusalWithBilling]: 'Отказ с тарификацией',
        [OrderStatus.CommonProcessingRefusalWithoutBilling]: 'Отказ без тарификации',
        [OrderStatus.CommonProcessingCancel]: 'Отмена',
        [OrderStatus.CommonProcessingCancelWithBilling]: 'Отмена с тарификацией',
        [OrderStatus.CommonProcessingCancelWithoutBilling]: 'Отмена без тарификации',
        [OrderStatus.CommonProcessingCouldNotReach]: 'Недозвон',
        [OrderStatus.CommonProcessingCouldNotReachWithBilling]: 'Недозвон с тарификацией',
        [OrderStatus.CommonProcessingCouldNotReachWithoutBilling]: 'Недозвон без тарификации',
        [OrderStatus.CommonProcessingAccepted]: 'Принят',

        [OrderStatus.DeliveryTakingOnTheWay]: 'В пути к отправителю',
        [OrderStatus.DeliveryTakingRefusal]: 'Отказ',
        [OrderStatus.DeliveryTakingRefusalWithBilling]: 'Отказ с тарификацией',
        [OrderStatus.DeliveryTakingRefusalWithoutBilling]: 'Отказ без тарификации',
        [OrderStatus.DeliveryTakingCancel]: 'Отмена',
        [OrderStatus.DeliveryTakingCancelWithBilling]: 'Отмена с тарификацией',
        [OrderStatus.DeliveryTakingCancelWithoutBilling]: 'Отмена без тарификации',
        [OrderStatus.DeliveryTakingCouldNotReach]: 'Недозвон',
        [OrderStatus.DeliveryTakingCouldNotReachWithBilling]: 'Недозвон с тарификацией',
        [OrderStatus.DeliveryTakingCouldNotReachWithoutBilling]: 'Недозвон без тарификации',
        [OrderStatus.DeliveryTakingDone]: 'Получен курьером',

        [OrderStatus.DeliveryDeliveryOnTheWay]: 'В пути к получателю',
        [OrderStatus.DeliveryDeliveryRefusal]: 'Отказ',
        [OrderStatus.DeliveryDeliveryRefusalWithBilling]: 'Отказ с тарификацией',
        [OrderStatus.DeliveryDeliveryRefusalWithoutBilling]: 'Отказ без тарификации',
        [OrderStatus.DeliveryDeliveryCancel]: 'Отмена',
        [OrderStatus.DeliveryDeliveryCancelWithBilling]: 'Отмена с тарификацией',
        [OrderStatus.DeliveryDeliveryCancelWithoutBilling]: 'Отмена без тарификации',
        [OrderStatus.DeliveryDeliveryCouldNotReach]: 'Недозвон',
        [OrderStatus.DeliveryDeliveryCouldNotReachWithBilling]: 'Недозвон с тарификацией',
        [OrderStatus.DeliveryDeliveryCouldNotReachWithoutBilling]: 'Недозвон без тарификации',
        [OrderStatus.DeliveryDeliveryDone]: 'Доставлено',
        [OrderStatus.DeliveryDeliveryDoneWithoutPayment]: 'Доставлено без оплаты',
        [OrderStatus.DeliveryDeliveryDoneFullRedemption]: 'Доставлено полный выкуп',
        [OrderStatus.DeliveryDeliveryDonePartialRedemption]: 'Доставлено частичный выкуп',

        [OrderStatus.PickupTakingOnTheWay]: 'В пути на склад',
        [OrderStatus.PickupTakingDone]: 'Прибыл на склад',
        [OrderStatus.PickupTakingCancel]: 'Отмена',
        [OrderStatus.PickupTakingCancelWithBilling]: 'Отмена с тарификацией',
        [OrderStatus.PickupTakingCancelWithoutBilling]: 'Отмена без тарификации',

        [OrderStatus.PickupDeliveryWaitClient]: 'Готов к выдаче',
        [OrderStatus.PickupDeliveryRefusal]: 'Отказ',
        [OrderStatus.PickupDeliveryRefusalWithBilling]: 'Отказ с тарификацией',
        [OrderStatus.PickupDeliveryRefusalWithoutBilling]: 'Отказ без тарификации',
        [OrderStatus.PickupDeliveryCancel]: 'Отмена',
        [OrderStatus.PickupDeliveryCancelWithBilling]: 'Отмена с тарификацией',
        [OrderStatus.PickupDeliveryCancelWithoutBilling]: 'Отмена без тарификации',
        [OrderStatus.PickupDeliveryCouldNotReach]: 'Недозвон',
        [OrderStatus.PickupDeliveryCouldNotReachWithBilling]: 'Недозвон с тарификацией',
        [OrderStatus.PickupDeliveryCouldNotReachWithoutBilling]: 'Недозвон без тарификации',
        [OrderStatus.PickupDeliveryDone]: 'Выдано',
        [OrderStatus.PickupDeliveryDoneWithoutPayment]: 'Выдано без оплаты',
        [OrderStatus.PickupDeliveryDoneFullRedemption]: 'Выдано полный выкуп',
        [OrderStatus.PickupDeliveryDonePartialRedemption]: 'Выдано частичный выкуп',

        [OrderStatus.FulfillmentAssemblingAssembling]: 'Сборка заказа',
        [OrderStatus.FulfillmentAssemblingCancel]: 'Отмена',
        [OrderStatus.FulfillmentAssemblingCancelWithBilling]: 'Отмена с тарификацией',
        [OrderStatus.FulfillmentAssemblingCancelWithoutBilling]: 'Отмена без тарификации',
        [OrderStatus.FulfillmentAssemblingDone]: 'Готов к оправке',

        [OrderStatus.FulfillmentSending]: 'Отправлено',
        [OrderStatus.FulfillmentSendingReturned]: 'Вернулся отправителю',
        [OrderStatus.FulfillmentSendingCancel]: 'Отмена',
        [OrderStatus.FulfillmentSendingCancelWithBilling]: 'Отмена с тарификацией',
        [OrderStatus.FulfillmentSendingWithoutBilling]: 'Отмена без тарификации',
        [OrderStatus.FulfillmentSendingDone]: 'Доставлен партнером',
        [OrderStatus.FulfillmentSendingDoneWithoutPayment]: 'Доставлен партнером без оплаты',
        [OrderStatus.FulfillmentSendingDoneFullRedemption]: 'Доставлен партнером полный выкуп',
        [OrderStatus.FulfillmentSendingDonePartialRedemption]: 'Доставлен партнером частичный выкуп',
    },
};

export const ORDER_STATUS_TRIGGER_DICTIONARY: Record<'ru', Record<StatusTrigger, string>> = {
    ru: {
        [StatusTrigger.SetOrderActualCashOnDeliveryToFormalized]: 'Проставить "ожидаемый наложенный платеж" в "актуальный наложенный платеж"',
        [StatusTrigger.SetOrderActualCashOnDeliveryToZero]: 'Проставить "актуальный наложенный платеж" в 0',
        [StatusTrigger.SetOrderInsuranceFeeToZero]: 'Проставить "страховой сбор" в 0',
        [StatusTrigger.SetOrderRKOToZero]: 'Проставить "РКО" в 0',
        [StatusTrigger.SetOrderCostToZero]: 'Проставить "стоимость" в 0',
        [StatusTrigger.SetOrderPaymentToExecutorToZero]: 'Проставить "оплата курьера" в 0',
        [StatusTrigger.SetOrderExternalCostToZero]: 'Проставить "стоимость сторонней КС" в 0',
        [StatusTrigger.SetOrderExternalInsuranceFeeToZero]: 'Проставить "страховой сбор сторонней КС" в 0',
        [StatusTrigger.SetOrderExternalRKOToZero]: 'Проставить "РКО сторонней КС" в 0',
        [StatusTrigger.CloseOrder]: 'Закрыть заказ',
        [StatusTrigger.NotifyToCouriersEscortGroup]: 'Уведомить сообщением в телеграм-группу "Сопровождение"',
        [StatusTrigger.ResetExecutor]: 'Сбросить исполнителя заказа',
    },
};

export const FRONTEND_ORDER_STATUS_SHORT_DROPDOWN_OPTIONS: IShortOptionValueToFullOptionValues[] = [
    {
        name: {
            ru: 'Выполняется',
        },
        values: [
            OrderStatus.DeliveryTakingOnTheWay,
            OrderStatus.DeliveryTakingDone,
            OrderStatus.DeliveryDeliveryOnTheWay,

            OrderStatus.PickupTakingOnTheWay,
            OrderStatus.PickupTakingDone,
            OrderStatus.PickupDeliveryWaitClient,

            OrderStatus.FulfillmentAssemblingAssembling,
            OrderStatus.FulfillmentAssemblingDone,
            OrderStatus.FulfillmentSending,
        ],
    },
    {
        name: {
            ru: 'На проверке',
        },
        values: [
            OrderStatus.CommonProcessingRefusal,
            OrderStatus.CommonProcessingCancel,
            OrderStatus.CommonProcessingCouldNotReach,

            OrderStatus.DeliveryTakingRefusal,
            OrderStatus.DeliveryTakingCancel,
            OrderStatus.DeliveryTakingCouldNotReach,
            OrderStatus.DeliveryDeliveryRefusal,
            OrderStatus.DeliveryDeliveryCancel,
            OrderStatus.DeliveryDeliveryCouldNotReach,

            OrderStatus.PickupTakingCancel,
            OrderStatus.PickupDeliveryRefusal,
            OrderStatus.PickupDeliveryCancel,
            OrderStatus.PickupDeliveryCouldNotReach,

            OrderStatus.FulfillmentAssemblingCancel,
            OrderStatus.FulfillmentSendingReturned,
            OrderStatus.FulfillmentSendingCancel,
        ],
    },
    {
        name: {
            ru: 'Успешно выполнен',
        },
        values: [
            OrderStatus.DeliveryDeliveryDone,
            OrderStatus.DeliveryDeliveryDoneWithoutPayment,
            OrderStatus.DeliveryDeliveryDoneFullRedemption,
            OrderStatus.DeliveryDeliveryDonePartialRedemption,

            OrderStatus.PickupDeliveryDone,
            OrderStatus.PickupDeliveryDoneWithoutPayment,
            OrderStatus.PickupDeliveryDoneFullRedemption,
            OrderStatus.PickupDeliveryDonePartialRedemption,

            OrderStatus.FulfillmentSendingDone,
            OrderStatus.FulfillmentSendingDoneWithoutPayment,
            OrderStatus.FulfillmentSendingDoneFullRedemption,
            OrderStatus.FulfillmentSendingDonePartialRedemption,
        ],
    },
    {
        name: {
            ru: 'Отмена(любая)',
        },
        values: [
            OrderStatus.CommonProcessingCancel,
            OrderStatus.CommonProcessingCancelWithBilling,
            OrderStatus.CommonProcessingCancelWithoutBilling,

            OrderStatus.DeliveryTakingCancel,
            OrderStatus.DeliveryTakingCancelWithBilling,
            OrderStatus.DeliveryTakingCancelWithoutBilling,
            OrderStatus.DeliveryDeliveryCancel,
            OrderStatus.DeliveryDeliveryCancelWithBilling,
            OrderStatus.DeliveryDeliveryCancelWithoutBilling,

            OrderStatus.PickupTakingCancel,
            OrderStatus.PickupTakingCancelWithBilling,
            OrderStatus.PickupTakingCancelWithoutBilling,
            OrderStatus.PickupDeliveryCancel,
            OrderStatus.PickupDeliveryCancelWithBilling ,
            OrderStatus.PickupDeliveryCancelWithoutBilling,

            OrderStatus.FulfillmentAssemblingCancel,
            OrderStatus.FulfillmentAssemblingCancelWithBilling,
            OrderStatus.FulfillmentAssemblingCancelWithoutBilling,
            OrderStatus.FulfillmentSendingCancel,
            OrderStatus.FulfillmentSendingCancelWithBilling,
            OrderStatus.FulfillmentSendingWithoutBilling,
        ],
    },
    {
        name: {
            ru: 'Отмена с тарификацией',
        },
        values: [
            OrderStatus.CommonProcessingCancelWithBilling,
            OrderStatus.DeliveryTakingCancelWithBilling,
            OrderStatus.DeliveryDeliveryCancelWithBilling,
            OrderStatus.PickupTakingCancelWithBilling,
            OrderStatus.PickupDeliveryCancelWithBilling ,
            OrderStatus.FulfillmentAssemblingCancelWithBilling,
            OrderStatus.FulfillmentSendingCancelWithBilling,
        ],
    },
    {
        name: {
            ru: 'Отмена без тарификации',
        },
        values: [
            OrderStatus.CommonProcessingCancelWithoutBilling,
            OrderStatus.DeliveryTakingCancelWithoutBilling,
            OrderStatus.DeliveryDeliveryCancelWithoutBilling,
            OrderStatus.PickupTakingCancelWithoutBilling,
            OrderStatus.PickupDeliveryCancelWithoutBilling,
            OrderStatus.FulfillmentAssemblingCancelWithoutBilling,
            OrderStatus.FulfillmentSendingWithoutBilling,
        ],
    },
    {
        name: {
            ru: 'Отказ(любой)',
        },
        values: [
            OrderStatus.CommonProcessingRefusal,
            OrderStatus.CommonProcessingRefusalWithBilling,
            OrderStatus.CommonProcessingRefusalWithoutBilling,

            OrderStatus.DeliveryTakingRefusal,
            OrderStatus.DeliveryTakingRefusalWithBilling,
            OrderStatus.DeliveryTakingRefusalWithoutBilling,
            OrderStatus.DeliveryDeliveryRefusal,
            OrderStatus.DeliveryDeliveryRefusalWithBilling,
            OrderStatus.DeliveryDeliveryRefusalWithoutBilling,

            OrderStatus.PickupDeliveryRefusal,
            OrderStatus.PickupDeliveryRefusalWithBilling,
            OrderStatus.PickupDeliveryRefusalWithoutBilling,
        ],
    },
    {
        name: {
            ru: 'Отказ с тарификацией',
        },
        values: [
            OrderStatus.CommonProcessingRefusalWithBilling,
            OrderStatus.DeliveryTakingRefusalWithBilling,
            OrderStatus.DeliveryDeliveryRefusalWithBilling,
            OrderStatus.PickupDeliveryRefusalWithBilling,
        ],
    },
    {
        name: {
            ru: 'Отказ без тарификации',
        },
        values: [
            OrderStatus.CommonProcessingRefusalWithoutBilling,
            OrderStatus.DeliveryTakingRefusalWithoutBilling,
            OrderStatus.DeliveryDeliveryRefusalWithoutBilling,
            OrderStatus.PickupDeliveryRefusalWithoutBilling,
        ],
    },
    {
        name: {
            ru: 'Недозвон(любой)',
        },
        values: [
            OrderStatus.CommonProcessingCouldNotReach,
            OrderStatus.CommonProcessingCouldNotReachWithBilling,
            OrderStatus.CommonProcessingCouldNotReachWithoutBilling,

            OrderStatus.DeliveryTakingCouldNotReach,
            OrderStatus.DeliveryTakingCouldNotReachWithBilling,
            OrderStatus.DeliveryTakingCouldNotReachWithoutBilling,
            OrderStatus.DeliveryDeliveryCouldNotReach,
            OrderStatus.DeliveryDeliveryCouldNotReachWithBilling,
            OrderStatus.DeliveryDeliveryCouldNotReachWithoutBilling,

            OrderStatus.PickupDeliveryCouldNotReach,
            OrderStatus.PickupDeliveryCouldNotReachWithBilling,
            OrderStatus.PickupDeliveryCouldNotReachWithoutBilling,
        ],
    },
    {
        name: {
            ru: 'Недозвон с тарификацией',
        },
        values: [
            OrderStatus.CommonProcessingCouldNotReachWithBilling,
            OrderStatus.DeliveryTakingCouldNotReachWithBilling,
            OrderStatus.DeliveryDeliveryCouldNotReachWithBilling,
            OrderStatus.PickupDeliveryCouldNotReachWithBilling,
        ],
    },
    {
        name: {
            ru: 'Недозвон без тарификации',
        },
        values: [
            OrderStatus.CommonProcessingCouldNotReachWithoutBilling,
            OrderStatus.DeliveryTakingCouldNotReachWithoutBilling,
            OrderStatus.DeliveryDeliveryCouldNotReachWithoutBilling,
            OrderStatus.PickupDeliveryCouldNotReachWithoutBilling,
        ],
    },

];
