import {
    ToolbarEnv,
} from './Toolbar.types';

export default {
    ru: {
        [ToolbarEnv.Home]: 'главная',
        [ToolbarEnv.LogIn]: 'Авторизация',
        [ToolbarEnv.SignUp]: 'Регистрация',
        [ToolbarEnv.CreateUser]: 'Создание пользователя',
        [ToolbarEnv.API]: 'API',
        [ToolbarEnv.Orders]: 'Заказы',
        [ToolbarEnv.Accounting]: 'Учет',
        [ToolbarEnv.CustomPrices]: 'Кастомизация',
        [ToolbarEnv.Users]: 'Пользователи',
        [ToolbarEnv.Account]: 'Мой аккаунт',
        [ToolbarEnv.Analytics]: 'Аналитика',
        [ToolbarEnv.Help]: 'Помощь',
        [ToolbarEnv.Map]: 'Карта',
        [ToolbarEnv.LogOut]: 'Выйти',
    },
};

