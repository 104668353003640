import {
    DeepPartial,
} from 'backend2/types';
import {
    IFullUser,
} from 'backend2/interfaces/users';
import {
    IFullHistory,
} from 'backend2/interfaces/history';

import FullUser from 'backend2/dtos/users/Users/User.dto';
import LoginUserDto from 'backend2/dtos/users/Users/LoginUser.dto';
import LogOutUserDto from 'backend2/dtos/users/Users/LogOutUser.dto';
import GetUsersDto from 'backend2/dtos/users/Users/GetUsers.dto';
import ChangeUserPasswordDto from 'backend2/dtos/users/Users/ChangeUserPassword.dto';
import TokenDto from 'backend2/dtos/users/Users/Token.dto';
import GetHistory from 'backend2/dtos/common/History/GetHistory.dto';

import {
    BackendUrls,
} from 'src/constants/url';

import fetch from 'src/libs/fetch';

export function signUp(body: FullUser) {
    return fetch<IFullUser>({
        method: 'POST',
        url: BackendUrls.UsersSignUp,
        body,
    });
}

export function createUser(body: FullUser) {
    return fetch<IFullUser>({
        method: 'POST',
        url: BackendUrls.CreateUser,
        body,
    });
}

export function logIn(body: LoginUserDto) {
    return fetch<IFullUser>({
        method: 'POST',
        url: BackendUrls.UserLogIn,
        body,
    });
}

export function logOut(body: LogOutUserDto = {fromAll: false}) {
    return fetch({
        method: 'POST',
        url: BackendUrls.UserLogOut,
        body,
    });
}

export function getCurrentUser() {
    return fetch<IFullUser>({
        method: 'GET',
        url: BackendUrls.UserMe,
    });
}

export function getUsers(params?: GetUsersDto) {
    return fetch<IFullUser[]>({
        method: 'GET',
        url: BackendUrls.UserGet,
        params,
    });
}

export function issueSessionByToken(body: TokenDto) {
    return fetch({
        method: 'POST',
        url: BackendUrls.IssueSessionByToken,
        body,
    });
}

export function updateUser(userId: string, patch: DeepPartial<FullUser>) {
    return fetch<IFullUser>({
        method: 'PATCH',
        url: BackendUrls.UpdateUser.replace('{userId}', userId),
        body: patch,
    });
}

export function changeUserType(userId: string, patch: DeepPartial<FullUser>) {
    return fetch<IFullUser>({
        method: 'PUT',
        url: BackendUrls.ChangeUserType.replace('{userId}', userId),
        body: patch,
    });
}

export function changeUserPassword(userId: string, patch: ChangeUserPasswordDto) {
    return fetch<IFullUser>({
        method: 'PUT',
        url: BackendUrls.ChangeUserPassword.replace('{userId}', userId),
        body: patch,
    });
}

export function disableUser(userId: string, comment: string) {
    return fetch<IFullUser>({
        method: 'PUT',
        url: BackendUrls.DisableUser.replace('{userId}', userId),
        body: {
            comment,
        },
    });
}

export function enableUser(userId: string, comment: string) {
    return fetch<IFullUser>({
        method: 'PUT',
        url: BackendUrls.EnableUser.replace('{userId}', userId),
        body: {
            comment,
        },
    });
}

export function archiveUser(userId: string) {
    return fetch<IFullUser>({
        method: 'PUT',
        url: BackendUrls.ArchiveUser.replace('{userId}', userId),
    });
}

export function unarchiveUser(userId: string) {
    return fetch<IFullUser>({
        method: 'PUT',
        url: BackendUrls.UnarchiveUser.replace('{userId}', userId),
    });
}

export function fetchUsersHistory(params?: GetHistory) {
    return fetch<IFullHistory[]>({
        method: 'GET',
        url: BackendUrls.GetUsersHistory,
        params,
    });
}
