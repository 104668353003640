import {
    ICount,
} from 'backend2/interfaces/common';
import {
    IFullHistory,
} from 'backend2/interfaces/history';
import {
    IFullUser,
} from 'backend2/interfaces/users';

import {
    makeAutoObservable,
    runInAction,
} from 'mobx';

import {
    fetchPresets,
} from 'src/stores/fetchPresets';

import {
    getUsers,
    fetchUsersHistory as getUsersHistory,

    updateUser,
    changeUserType,
    changeUserPassword,
    disableUser,
    enableUser,
    archiveUser,
    unarchiveUser,

    logIn,
    logOut,
    getCurrentUser,

    signUp,
    createUser,
} from 'src/dataProviders/users';
import {
    parseUserName,
} from 'src/libs/backend2/parser';

class ObservableUsersStore {
    viewer: IFullUser;
    users: Record<string, IFullUser> = {};
    usersHistory: Record<string, IFullHistory[]> = {};
    _idUsernameMap: Record<string, string> = {};
    hotUserIds: number[] = [];

    constructor() {
        makeAutoObservable(this, null, {autoBind: true});
    }

    get allUsers() {
        return Object.values(this.users);
    }

    get hotUsers() {
        return this.hotUserIds.map(id => this.getUser(id));
    }

    getUser(userId: string | number) {
        return this.users[String(userId)];
    }

    get viewerType() {
        return this.viewer.user.typeEnum;
    }

    get viewerId() {
        return this.viewer.user.id;
    }

    get idUsernameMap() {
        return this._idUsernameMap;
    }

    checkIsUserFetched(userId: string | number) {
        return Boolean(this.users[userId]);
    }

    async fetchUsers(params: Parameters<typeof getUsers>[0], isHot = false) {
        const fullUsers = await getUsers(params);
        runInAction(() => {
            const usersPatch: typeof this.users = {};
            const _idUsernameMapPatch: typeof this._idUsernameMap = {};
            for (const fullUser of fullUsers) {
                usersPatch[String(fullUser.user.id)] = fullUser;
                _idUsernameMapPatch[String(fullUser.user.id)] = parseUserName(fullUser);
            }
            this.users = {...this.users, ...usersPatch};
            this._idUsernameMap = {...this._idUsernameMap, ..._idUsernameMapPatch};
            if (isHot) {
                this.hotUserIds = fullUsers.map(user => user.user.id);
            }
        });
        return fullUsers;
    }

    async fetchUsersCount(params: Parameters<typeof getUsers>[0]) {
        const usersCount = await getUsers({
            ...params,
            isCountMode: true,
        });
        // @ts-ignore
        usersCount.count = BigInt(usersCount.count);
        return usersCount as unknown as ICount;
    }

    async fetchUsersHistory(params: Parameters<typeof getUsersHistory>[0]) {
        const histories = await getUsersHistory(params);
        for (const history of histories) {
            const entityId = String(history.fieldEntityId);
            if (!this.usersHistory[entityId]) {
                this.usersHistory[entityId] = [];
            }
            this.usersHistory[entityId].push(history);
        }
        return histories;
    }

    async fetchUsersHistoryCount(params: Parameters<typeof getUsersHistory>[0]) {
        const usersHistoryCount = await getUsersHistory({
            ...params,
            isCountMode: true,
        });
        // @ts-ignore
        usersHistoryCount.count = BigInt(usersHistoryCount.count);
        return usersHistoryCount as unknown as ICount;
    }

    updateDecorator<T>(func: Function) {
        return async (userId: string | number, body: T) => {
            const nextFullUser = await func.call(this, userId, body);
            this.users[nextFullUser.user.id] = nextFullUser;
        };
    }
    updateUser = this.updateDecorator<Parameters<typeof updateUser>[1]>((...params:  Parameters<typeof updateUser>) => {
        return updateUser(...params);
    });
    changeUserType = this.updateDecorator<Parameters<typeof changeUserType>[1]>((...params:  Parameters<typeof changeUserType>) => {
        return changeUserType(...params);
    });
    changeUserPassword = this.updateDecorator<Parameters<typeof changeUserPassword>[1]>((...params:  Parameters<typeof changeUserPassword>) => {
        return changeUserPassword(...params);
    });
    disableUser = this.updateDecorator<Parameters<typeof disableUser>[1]>((...params:  Parameters<typeof disableUser>) => {
        return disableUser(...params);
    });
    enableUser = this.updateDecorator<Parameters<typeof enableUser>[1]>((...params:  Parameters<typeof enableUser>) => {
        return enableUser(...params);
    });

    actionDecorator<T>(func: Function) {
        return async (userId: string | number, body?: T) => {
            const nextFullUser = await func.call(this, userId, body);
            this.users[nextFullUser.user.id] = nextFullUser;
        };
    }
    archiveUser = this.actionDecorator((...params: Parameters<typeof archiveUser>) => {
        return archiveUser(...params);
    });
    unarchiveUser = this.actionDecorator((...params:  Parameters<typeof unarchiveUser>) => {
        return unarchiveUser(...params);
    });

    async logIn(...params: Parameters<typeof logIn>) {
        const fullUser = await logIn(...params);
        runInAction(() => {
            this.users[fullUser.user.id] = fullUser;
            this.viewer = fullUser;
            void fetchPresets();
        });
    }

    async logOut(...params: Parameters<typeof logOut>) {
        await logOut(...params);

        // todo: clear all stores
    }

    async getCurrentUser() {
        const fullUser = await getCurrentUser();
        runInAction(() => {
            this.users[fullUser.user.id] = fullUser;
            this.viewer = fullUser;
        });
    }

    async signUp(...params: Parameters<typeof signUp>) {
        const fullUser = await signUp(...params);
        this.users[fullUser.user.id] = fullUser;
        return fullUser.user.id;
    }

    async createUser(...params: Parameters<typeof createUser>) {
        const fullUser = await createUser(...params);
        this.users[fullUser.user.id] = fullUser;
        return fullUser.user.id;
    }
}

const UsersStore = new ObservableUsersStore();
export default UsersStore;
