import {
    ICount,
} from 'backend2/interfaces/common';

import {
    checkIsExecutor,
    checkIsCustomer,
} from 'src/libs/backend2/parser';

import OrdersStore from 'src/stores/Orders';
import UsersStore from 'src/stores/Users';
import WarehousesStore from 'src/stores/Warehouses';
import CustomsStore from 'src/stores/Customs';

const FETCH_PRESETS_ITERATION_LIMIT = 1000;
const FETCH_PRESETS_MAX_ITERATION = 10;
const FETCH_PRESETS_ITERATION_DELAY = 3000;

const NOT_FETCHING_COUNT: ICount = {
    count: BigInt(0),
};

export async function fetchPresets() {
    const currentUserType = UsersStore.viewerType;
    const isCurrentUserExecutor = checkIsExecutor(null, currentUserType);
    const isCurrentUserCustomer = checkIsCustomer(null, currentUserType);

    await OrdersStore.getOrderStatusesConfig();

    const [
        promiseSettledNonArchivedUsersCount,
        promiseSettledArchivedUsersCount,
        promiseSettledWarehousesCount,
        promiseSettledRemovedWarehousesCount,
        promiseSettledOrderCustomKindsCount,
        promiseSettledRemovedOrderCustomKindsCount,
        promiseSettledOrderCustomCostsCount,
        promiseSettledRemovedCustomCostsCount,
        promiseSettledOrderCustomsInsuranceFeeCount,
        promiseSettledRemovedCustomsInsuranceFeeCount,
        promiseSettledOrderCustomsRKOCount,
        promiseSettledRemovedOrderCustomsRKOCount,
        promiseSettledOrderCustomsPaymentToExecutorCount,
        promiseSettledRemovedOrderCustomsPaymentToExecutorCount,
    ] = await Promise.allSettled([
        UsersStore.fetchUsersCount({isArchived: false}),
        UsersStore.fetchUsersCount({isArchived: true}),
        WarehousesStore.fetchWarehousesCount({isRemoved: false}),
        WarehousesStore.fetchWarehousesCount({isRemoved: true}),
        CustomsStore.fetchOrderCustomKindsCount({isRemoved: false}),
        CustomsStore.fetchOrderCustomKindsCount({isRemoved: true}),

        (isCurrentUserCustomer || isCurrentUserExecutor) ? Promise.resolve(NOT_FETCHING_COUNT) : CustomsStore.fetchOrdersCustomCostsCount({isRemoved: false}),
        (isCurrentUserCustomer || isCurrentUserExecutor) ? Promise.resolve(NOT_FETCHING_COUNT) : CustomsStore.fetchOrdersCustomCostsCount({isRemoved: true}),
        (isCurrentUserCustomer || isCurrentUserExecutor) ? Promise.resolve(NOT_FETCHING_COUNT) : CustomsStore.fetchOrdersCustomsInsuranceFeeCount({isRemoved: false}),
        (isCurrentUserCustomer || isCurrentUserExecutor) ? Promise.resolve(NOT_FETCHING_COUNT) : CustomsStore.fetchOrdersCustomsInsuranceFeeCount({isRemoved: true}),
        (isCurrentUserCustomer || isCurrentUserExecutor) ? Promise.resolve(NOT_FETCHING_COUNT) : CustomsStore.fetchOrdersCustomsRKOCount({isRemoved: false}),
        (isCurrentUserCustomer || isCurrentUserExecutor) ? Promise.resolve(NOT_FETCHING_COUNT) : CustomsStore.fetchOrdersCustomsRKOCount({isRemoved: true}),
        (isCurrentUserCustomer || isCurrentUserExecutor) ? Promise.resolve(NOT_FETCHING_COUNT) : CustomsStore.fetchOrdersCustomsPaymentToExecutorCount({isRemoved: false}),
        (isCurrentUserCustomer || isCurrentUserExecutor) ? Promise.resolve(NOT_FETCHING_COUNT) : CustomsStore.fetchOrdersCustomsPaymentToExecutorCount({isRemoved: true}),
    ]);

    let fetchedNonArchivedUsersCount = 0n;
    let fetchedArchivedUsersCount = 0n;
    let fetchedWarehousesCount = 0n;
    let fetchedRemovedWarehousesCount = 0n;
    let fetchedOrderCustomKindsCount = 0n;
    let fetchedRemovedOrderCustomKindsCount = 0n;
    let fetchedOrderCustomCostsCount = 0n;
    let fetchedRemovedOrderCustomCostsCount = 0n;
    let fetchedOrderCustomsInsuranceFeeCount = 0n;
    let fetchedRemovedOrderCustomsInsuranceFeeCount = 0n;
    let fetchedOrderCustomsRKOCount = 0n;
    let fetchedRemovedOrderCustomsRKOCount = 0n;
    let fetchedOrderCustomsPaymentToExecutorCount = 0n;
    let fetchedRemovedOrderCustomsPaymentToExecutorCount = 0n;

    const nonArchivedUsersTotalCount = promiseSettledNonArchivedUsersCount.status === 'fulfilled' ? promiseSettledNonArchivedUsersCount.value.count : 1000n;
    const archivedUsersTotalCount = promiseSettledArchivedUsersCount.status === 'fulfilled' ? promiseSettledArchivedUsersCount.value.count : 1000n;
    const warehousesTotalCount = promiseSettledWarehousesCount.status === 'fulfilled' ? promiseSettledWarehousesCount.value.count : 1000n;
    const removedWarehousesTotalCount = promiseSettledRemovedWarehousesCount.status === 'fulfilled' ? promiseSettledRemovedWarehousesCount.value.count : 1000n;
    const orderCustomKindsTotalCount = promiseSettledOrderCustomKindsCount.status === 'fulfilled' ? promiseSettledOrderCustomKindsCount.value.count : 1000n;
    const orderRemovedCustomKindsTotalCount = promiseSettledRemovedOrderCustomKindsCount.status === 'fulfilled' ? promiseSettledRemovedOrderCustomKindsCount.value.count : 1000n;
    const orderCustomCostsTotalCount = promiseSettledOrderCustomCostsCount.status === 'fulfilled' ? promiseSettledOrderCustomCostsCount.value.count : 1000n;
    const removedOrderCustomCostsTotalCount = promiseSettledRemovedCustomCostsCount.status === 'fulfilled' ? promiseSettledRemovedCustomCostsCount.value.count : 1000n;
    const orderCustomsInsuranceFeeTotalCount = promiseSettledOrderCustomsInsuranceFeeCount.status === 'fulfilled' ? promiseSettledOrderCustomsInsuranceFeeCount.value.count : 1000n;
    const removedOrderCustomsInsuranceFeeTotalCount = promiseSettledRemovedCustomsInsuranceFeeCount.status === 'fulfilled' ? promiseSettledRemovedCustomsInsuranceFeeCount.value.count : 1000n;
    const orderCustomsRKOCountTotalCount = promiseSettledOrderCustomsRKOCount.status === 'fulfilled' ? promiseSettledOrderCustomsRKOCount.value.count : 1000n;
    const removedOrderCustomsRKOCountTotalCount = promiseSettledRemovedOrderCustomsRKOCount.status === 'fulfilled' ? promiseSettledRemovedOrderCustomsRKOCount.value.count : 1000n;
    const orderCustomsPaymentToExecutorTotalCount = promiseSettledOrderCustomsPaymentToExecutorCount.status === 'fulfilled' ? promiseSettledOrderCustomsPaymentToExecutorCount.value.count : 1000n;
    const removedOrderCustomsPaymentToExecutorTotalCount = promiseSettledRemovedOrderCustomsPaymentToExecutorCount.status === 'fulfilled' ? promiseSettledRemovedOrderCustomsPaymentToExecutorCount.value.count : 1000n;

    for (let i = 0; i < FETCH_PRESETS_MAX_ITERATION; i++) {
        const offset = i * FETCH_PRESETS_ITERATION_LIMIT;

        try {
            const nonArchivedUsers = fetchedNonArchivedUsersCount < nonArchivedUsersTotalCount
                ? await UsersStore.fetchUsers({isArchived: false, limit: FETCH_PRESETS_ITERATION_LIMIT, offset})
                : [];
            fetchedNonArchivedUsersCount += nonArchivedUsers.length ? BigInt(nonArchivedUsers.length) : nonArchivedUsersTotalCount;
        } catch (ex) {
            fetchedNonArchivedUsersCount += nonArchivedUsersTotalCount;
        }
        try {
            const archivedUsers = fetchedArchivedUsersCount < archivedUsersTotalCount
                ? await UsersStore.fetchUsers({isArchived: true, limit: FETCH_PRESETS_ITERATION_LIMIT, offset})
                : [];
            fetchedArchivedUsersCount += archivedUsers.length ? BigInt(archivedUsers.length) : archivedUsersTotalCount;
        } catch (ex) {
            fetchedArchivedUsersCount += archivedUsersTotalCount;
        }

        try {
            const warehouses = fetchedWarehousesCount < warehousesTotalCount
                ? await WarehousesStore.fetchWarehouses({isRemoved: false, limit: FETCH_PRESETS_ITERATION_LIMIT, offset})
                : [];
            fetchedWarehousesCount += warehouses.length ? BigInt(warehouses.length) : warehousesTotalCount;
        } catch (ex) {
            fetchedWarehousesCount += warehousesTotalCount;
        }
        try {
            const removedWarehouses = fetchedRemovedWarehousesCount < removedWarehousesTotalCount
                ? await WarehousesStore.fetchWarehouses({isRemoved: true, limit: FETCH_PRESETS_ITERATION_LIMIT, offset})
                : [];
            fetchedRemovedWarehousesCount += removedWarehouses.length ? BigInt(removedWarehouses.length) : removedWarehousesTotalCount;
        } catch (ex) {
            fetchedRemovedWarehousesCount += removedWarehousesTotalCount;
        }

        try {
            const orderCustomKinds =  fetchedOrderCustomKindsCount < orderCustomKindsTotalCount
                ? await CustomsStore.fetchOrderCustomKinds({isRemoved: false, limit: FETCH_PRESETS_ITERATION_LIMIT, offset})
                : [];
            fetchedOrderCustomKindsCount += orderCustomKinds.length ? BigInt(orderCustomKinds.length) : orderCustomKindsTotalCount;
        } catch (ex) {
            fetchedOrderCustomKindsCount += orderCustomKindsTotalCount;
        }
        try {
            const removedOrderCustomKinds = fetchedRemovedOrderCustomKindsCount < orderRemovedCustomKindsTotalCount
                ? await CustomsStore.fetchOrderCustomKinds({isRemoved: true, limit: FETCH_PRESETS_ITERATION_LIMIT, offset})
                : [];
            fetchedRemovedOrderCustomKindsCount += removedOrderCustomKinds.length ? BigInt(removedOrderCustomKinds.length) : orderRemovedCustomKindsTotalCount;
        } catch (ex) {
            fetchedRemovedOrderCustomKindsCount += orderRemovedCustomKindsTotalCount;
        }

        try {
            const customs = fetchedOrderCustomCostsCount < orderCustomCostsTotalCount
                ? await CustomsStore.fetchOrdersCustomCosts({isRemoved: false, limit: FETCH_PRESETS_ITERATION_LIMIT, offset})
                : [];
            fetchedOrderCustomCostsCount += customs.length ? BigInt(customs.length) : orderCustomCostsTotalCount;
        } catch (ex) {
            fetchedOrderCustomCostsCount += orderCustomCostsTotalCount;
        }
        try {
            const customs = fetchedRemovedOrderCustomCostsCount < removedOrderCustomCostsTotalCount
                ? await CustomsStore.fetchOrdersCustomCosts({isRemoved: true, limit: FETCH_PRESETS_ITERATION_LIMIT, offset})
                : [];
            fetchedRemovedOrderCustomCostsCount += customs.length ? BigInt(customs.length) : removedOrderCustomCostsTotalCount;
        } catch (ex) {
            fetchedRemovedOrderCustomCostsCount += removedOrderCustomCostsTotalCount;
        }

        try {
            const customs = fetchedOrderCustomsInsuranceFeeCount < orderCustomsInsuranceFeeTotalCount
                ? await CustomsStore.fetchOrdersCustomsInsuranceFee({isRemoved: false, limit: FETCH_PRESETS_ITERATION_LIMIT, offset})
                : [];
            fetchedOrderCustomsInsuranceFeeCount += customs.length ? BigInt(customs.length) : orderCustomsInsuranceFeeTotalCount;
        } catch (ex) {
            fetchedOrderCustomsInsuranceFeeCount += orderCustomsInsuranceFeeTotalCount;
        }
        try {
            const customs = fetchedRemovedOrderCustomsInsuranceFeeCount < removedOrderCustomsInsuranceFeeTotalCount
                ? await CustomsStore.fetchOrdersCustomsInsuranceFee({isRemoved: false, limit: FETCH_PRESETS_ITERATION_LIMIT, offset})
                : [];
            fetchedRemovedOrderCustomsInsuranceFeeCount += customs.length ? BigInt(customs.length) : removedOrderCustomsInsuranceFeeTotalCount;
        } catch (ex) {
            fetchedRemovedOrderCustomsInsuranceFeeCount += removedOrderCustomsInsuranceFeeTotalCount;
        }

        try {
            const customs = fetchedOrderCustomsRKOCount < orderCustomsRKOCountTotalCount
                ? await CustomsStore.fetchOrdersCustomsRKO({isRemoved: false, limit: FETCH_PRESETS_ITERATION_LIMIT, offset})
                : [];
            fetchedOrderCustomsRKOCount += customs.length ? BigInt(customs.length) : orderCustomsRKOCountTotalCount;
        } catch (ex) {
            fetchedOrderCustomsRKOCount += orderCustomsRKOCountTotalCount;
        }
        try {
            const customs = fetchedRemovedOrderCustomsRKOCount < removedOrderCustomsRKOCountTotalCount
                ? await CustomsStore.fetchOrdersCustomsRKO({isRemoved: false, limit: FETCH_PRESETS_ITERATION_LIMIT, offset})
                : [];
            fetchedRemovedOrderCustomsRKOCount += customs.length ? BigInt(customs.length) : removedOrderCustomsRKOCountTotalCount;
        } catch (ex) {
            fetchedRemovedOrderCustomsRKOCount += removedOrderCustomsRKOCountTotalCount;
        }

        try {
            const customs = fetchedOrderCustomsPaymentToExecutorCount < orderCustomsPaymentToExecutorTotalCount
                ? await CustomsStore.fetchOrdersCustomsPaymentToExecutor({isRemoved: false, limit: FETCH_PRESETS_ITERATION_LIMIT, offset})
                : [];
            fetchedOrderCustomsPaymentToExecutorCount += customs.length ? BigInt(customs.length) : orderCustomsPaymentToExecutorTotalCount;
        } catch (ex) {
            fetchedOrderCustomsPaymentToExecutorCount += orderCustomsPaymentToExecutorTotalCount;
        }
        try {
            const customs = fetchedRemovedOrderCustomsPaymentToExecutorCount < removedOrderCustomsPaymentToExecutorTotalCount
                ? await CustomsStore.fetchOrdersCustomsPaymentToExecutor({isRemoved: false, limit: FETCH_PRESETS_ITERATION_LIMIT, offset})
                : [];
            fetchedRemovedOrderCustomsPaymentToExecutorCount += customs.length ? BigInt(customs.length) : removedOrderCustomsPaymentToExecutorTotalCount;
        } catch (ex) {
            fetchedRemovedOrderCustomsPaymentToExecutorCount += removedOrderCustomsPaymentToExecutorTotalCount;
        }

        await new Promise(resolve => setTimeout(resolve, FETCH_PRESETS_ITERATION_DELAY));
    }
}
