import {
    DeepPartial,
} from 'backend2/types';
import {
    IFullHistory,
} from 'backend2/interfaces/history';
import {
    StatusesTransitionsConfig,
    OrderStatus,
} from 'backend2/types/orders';
import {
    UserType,
} from 'backend2/types/users';
import {
    IFullOrder,
    IOrderWhy,
    IOrdersInsuranceGroupWhy,
} from 'backend2/interfaces/orders';
import {
    IFullWarehouse,
} from 'backend2/interfaces/warehouses';
import {
    IFullOrderCustomKind,
} from 'backend2/interfaces/orderCustomKind';
import {
    IFullOrderCustomCost,
} from 'backend2/interfaces/orderCustomCost';
import {
    IFullOrderCustomInsuranceFee,
} from 'backend2/interfaces/orderCustomInsuranceFee';
import {
    IFullOrderCustomRKO,
} from 'backend2/interfaces/orderCustomRKO';
import {
    IFullOrderCustomPaymentToExecutor,
} from 'backend2/interfaces/orderCustomPaymentToExecutor';
import FullOrder from 'backend2/dtos/orders/Orders/Order.dto';
import GetOrdersDto from 'backend2/dtos/orders/Orders/GetOrders.dto';
import GetWarehousesDto from 'backend2/dtos/orders/Warehouses/GetWarehouses.dto';
import GetOrdersCustomKindsDto from 'backend2/dtos/orders/CustomKind/GetOrdersCustomKinds.dto';
import OrderIdsDto from 'backend2/dtos/orders/Orders/OrderIds.dto';
import GetHistory from 'backend2/dtos/common/History/GetHistory.dto';
import {
    GetOrdersWhyDto,
    GetOrdersInsuranceGroupsWhyDto,
} from 'backend2/dtos/orders/Orders/GetWhy.dto';
import GetOrdersCustomCostsDto from 'backend2/dtos/orders/CustomCost/GetOrdersCustomCosts.dto';
import GetOrdersCustomsInsuranceFeeDto from 'backend2/dtos/orders/CustomInsuranceFee/GetOrdersCustomsInsuranceFee.dto';
import GetOrdersCustomsRKO from 'backend2/dtos/orders/CustomRKO/GetOrdersCustomsRKO.dto';
import GetOrdersCustomsPaymentToExecutorDto from 'backend2/dtos/orders/CustomPaymentToExecutor/GetOrdersCustomsPaymentToExecutor.dto';
import {
    BackendUrls,
} from 'src/constants/url';
import fetch from 'src/libs/fetch';
export function getOrders(params?: GetOrdersDto) {
    return fetch<IFullOrder[]>({
        method: 'GET',
        url: BackendUrls.GetOrders,
        params,
    });
}
export function getOrdersHistory(params?: GetHistory) {
    return fetch<IFullHistory[]>({
        method: 'GET',
        url: BackendUrls.GetOrdersHistory,
        params,
    });
}
export function calculateOrdersCourierPayment(params?: OrderIdsDto) {
    return fetch<{result: Record<string, number>}>({
        method: 'GET',
        url: BackendUrls.CalculateOrdersCourierPayment,
        params,
    });
}
export function recalculateOrders(body?: GetOrdersDto) {
    return fetch<IFullOrder[]>({
        method: 'POST',
        url: BackendUrls.RecalculateOrders,
        body,
    });
}
export function getWarehouses(params?: GetWarehousesDto) {
    return fetch<IFullWarehouse[]>({
        method: 'GET',
        url: BackendUrls.GetWarehouses,
        params,
    });
}
export function getOrderCustomKinds(params?: GetOrdersCustomKindsDto) {
    return fetch<IFullOrderCustomKind[]>({
        method: 'GET',
        url: BackendUrls.GetOrderCustomKinds,
        params,
    });
}
function constructOrderUpdateUrl(orderId: bigint | string, url: BackendUrls) {
    return url.replace('{orderId}', String(orderId));
}
export function createOrders(body: DeepPartial<FullOrder>[]) {
    return fetch<IFullOrder[]>({
        method: 'POST',
        url: BackendUrls.CreateOrders,
        body,
    });
}
export function preCreateOrders(body: DeepPartial<FullOrder>[]) {
    return fetch<IFullOrder[]>({
        method: 'POST',
        url: BackendUrls.PreCreateOrders,
        body,
    });
}
export function updateOrderSimple(orderId: bigint | string, body: DeepPartial<FullOrder>) {
    return fetch<IFullOrder>({
        method: 'PATCH',
        url: constructOrderUpdateUrl(orderId, BackendUrls.UpdateOrderSimple),
        body,
    });
}
export function updateOrderWeight(orderId: bigint | string, body: DeepPartial<FullOrder>) {
    return fetch<IFullOrder>({
        method: 'PATCH',
        url: constructOrderUpdateUrl(orderId, BackendUrls.UpdateOrderWeight),
        body,
    });
}
export function updateOrderLocation(orderId: bigint | string, body: DeepPartial<FullOrder>) {
    return fetch<IFullOrder>({
        method: 'PATCH',
        url: constructOrderUpdateUrl(orderId, BackendUrls.UpdateOrderLocation),
        body,
    });
}
export function updateOrderTiming(orderId: bigint | string, body: DeepPartial<FullOrder>) {
    return fetch<IFullOrder>({
        method: 'PATCH',
        url: constructOrderUpdateUrl(orderId, BackendUrls.UpdateOrderTiming),
        body,
    });
}
export function updateOrderCashAndAnnouncedValue(orderId: bigint | string, body: DeepPartial<FullOrder>) {
    return fetch<IFullOrder>({
        method: 'PATCH',
        url: constructOrderUpdateUrl(orderId, BackendUrls.UpdateOrderCashAndAnnouncedValue),
        body,
    });
}
export function closeOrder(orderId: bigint | string) {
    return fetch<IFullOrder>({
        method: 'PUT',
        url: constructOrderUpdateUrl(orderId, BackendUrls.CloseOrder),
    });
}
export function openOrder(orderId: bigint | string) {
    return fetch<IFullOrder>({
        method: 'PUT',
        url: constructOrderUpdateUrl(orderId, BackendUrls.OpenOrder),
    });
}
export function updateOrderClose(orderId: bigint | string, body: DeepPartial<FullOrder>) {
    return fetch<IFullOrder>({
        method: 'PUT',
        url: constructOrderUpdateUrl(orderId, BackendUrls.UpdateOrderClose),
        body,
    });
}
export function updateOrderCustomer(orderId: bigint | string, body: DeepPartial<FullOrder>) {
    return fetch<IFullOrder>({
        method: 'PATCH',
        url: constructOrderUpdateUrl(orderId, BackendUrls.UpdateOrderCustomer),
        body,
    });
}
export function updateOrderExecutor(orderId: bigint | string, body: DeepPartial<FullOrder>) {
    return fetch<IFullOrder>({
        method: 'PATCH',
        url: constructOrderUpdateUrl(orderId, BackendUrls.UpdateOrderExecutor),
        body,
    });
}
export function updateOrderKind(orderId: bigint | string, body: DeepPartial<FullOrder>) {
    return fetch<IFullOrder>({
        method: 'PATCH',
        url: constructOrderUpdateUrl(orderId, BackendUrls.UpdateOrderKind),
        body,
    });
}
export function updateOrderManualControl(orderId: bigint | string, body: DeepPartial<FullOrder>) {
    return fetch<IFullOrder>({
        method: 'PATCH',
        url: constructOrderUpdateUrl(orderId, BackendUrls.UpdateOrderManualControl),
        body,
    });
}
export function acceptOrder(orderId: bigint | string) {
    return fetch<IFullOrder>({
        method: 'PUT',
        url: constructOrderUpdateUrl(orderId, BackendUrls.AcceptOrder),
    });
}
export function updateOrderStatus(orderId: bigint | string, body: DeepPartial<FullOrder>) {
    return fetch<IFullOrder>({
        method: 'PUT',
        url: constructOrderUpdateUrl(orderId, BackendUrls.UpdateOrderStatus),
        body,
    });
}
export function removeOrder(orderId: bigint | string) {
    return fetch<IFullOrder>({
        method: 'PUT',
        url: constructOrderUpdateUrl(orderId, BackendUrls.RemoveOrder),
    });
}
export function restoreOrder(orderId: bigint | string) {
    return fetch<IFullOrder>({
        method: 'PUT',
        url: constructOrderUpdateUrl(orderId, BackendUrls.RestoreOrder),
    });
}
export function parseOrdersCsv(body: FormData) {
    return fetch<DeepPartial<FullOrder>[]>({
        method: 'PUT',
        url: BackendUrls.ParseOrdersCsv,
        body,
        timeout: 60 * 1000,
    });
}
export function downloadOrdersCsv(params?: GetOrdersDto) {
    return fetch<BlobPart>({
        responseType: 'blob',
        method: 'GET',
        url: BackendUrls.DownloadOrdersCsv,
        params,
        timeout: 60 * 1000,
    });
}
export function generateOrderInvoices(params?: GetOrdersDto) {
    return fetch<{path: string}>({
        method: 'POST',
        url: BackendUrls.GenerateOrderInvoices,
        body: params,
        timeout: 3 * 60 * 1000,
    });
}
export function generateRegisterInvoice(params?: GetOrdersDto) {
    return fetch<{path: string}>({
        method: 'POST',
        url: BackendUrls.GenerateRegisterInvoice,
        body: params,
        timeout: 2 * 60 * 1000,
    });
}
export function getOrderStatusesConfig() {
    return fetch<{statusesTransitionsConfig: StatusesTransitionsConfig; permissibleUpdateOrderStatusByUserType: Record<UserType, OrderStatus[]>;}>({
        method: 'GET',
        url: BackendUrls.GetOrderStatusesConfig,
    });
}
export function getOrderFastTravelStatuses(orderId: bigint | string) {
    return fetch<OrderStatus[]>({
        method: 'GET',
        url: constructOrderUpdateUrl(orderId, BackendUrls.GetOrderFastTravelStatuses),
    });
}
export function getOrderTransitionStatuses(orderId: bigint | string) {
    return fetch<OrderStatus[]>({
        method: 'GET',
        url: constructOrderUpdateUrl(orderId, BackendUrls.GetOrderTransitionStatuses),
    });
}
export function getOrderWhy(params?: GetOrdersWhyDto) {
    return fetch<IOrderWhy[]>({
        method: 'GET',
        url: BackendUrls.GetOrderWhy,
        params,
    });
}
export function getOrdersInsuranceGroupsWhy(params?: GetOrdersInsuranceGroupsWhyDto) {
    return fetch<IOrdersInsuranceGroupWhy[]>({
        method: 'GET',
        url: BackendUrls.GetOrdersInsuranceGroupsWhy,
        params,
    });
}
export async function getCanIUpdate(orderId: bigint | string) {
    try {
        await fetch<void>({
            method: 'GET',
            url: constructOrderUpdateUrl(orderId, BackendUrls.GetCanIUpdateOrder),
        });
    } catch (ex) {
        if (ex?.response?.status === 403) {
            return false;
        }
    }
    return true;
}
export function getOrdersCustomCosts(params?: GetOrdersCustomCostsDto) {
    return fetch<IFullOrderCustomCost[]>({
        method: 'GET',
        url: BackendUrls.GetOrdersCustomCosts,
        params,
    });
}
export function getOrdersCustomsInsuranceFee(params?: GetOrdersCustomsInsuranceFeeDto) {
    return fetch<IFullOrderCustomInsuranceFee[]>({
        method: 'GET',
        url: BackendUrls.GetOrdersCustomsInsuranceFee,
        params,
    });
}
export function getOrdersCustomsRKO(params?: GetOrdersCustomsRKO) {
    return fetch<IFullOrderCustomRKO[]>({
        method: 'GET',
        url: BackendUrls.GetOrdersCustomsRKO,
        params,
    });
}
export function getOrdersCustomsPaymentToExecutor(params?: GetOrdersCustomsPaymentToExecutorDto) {
    return fetch<IFullOrderCustomPaymentToExecutor[]>({
        method: 'GET',
        url: BackendUrls.GetOrdersCustomsPaymentToExecutor,
        params,
    });
}
