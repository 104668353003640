export enum CustomHeaders {
	// unique id for one request
	RequestId = 'x-request-id',
	// unique id for one group requests
	TraceId = 'x-trace-id',
	// unique id for one session requests
	SessionId = 'x-session-id',
	// for bot
	BehalfUserId = 'x-behalf-user-id',
	// to filter response
	ResponseDataAttrs = 'x-response-data-attrs',
    ClientTimezoneOffset = 'client-timezone-offset',
	// for testing future feature
	SkdStaffV2 = 'skd-staff-v2',
}
