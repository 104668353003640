import {
    createCustomGeozone, getCustomGeozones, getGeozones, removeCustomGeozone, removeGeozone,
    updateCustomGeozone,
    updateGeozoneCoordinates,
    updateGeozoneFields
} from "src/dataProviders/geozones";
import {
    IS_DEVELOPMENT,
} from 'src/libs/env';

export enum FrontendUrls {
    LogIn = '/logIn',
    SignUp = '/signUp',
    Orders = '/orders',
    Accounting = '/accounting',
    AccountingLink = '/accounting/:accountId',
    CustomPrices = '/customPrices',
    Users = '/users',
    User = '/user/:userId',
    Order = '/order/:orderId',
    Account = '/account',
    Analytics = '/analytics',
    Help = '/help',
    Map = '/map',
    NotFind = '*',
    Home = '/',
    Api = '/api',
}

export enum CommonQueryParams {
    BackUrl = 'back_url',
}

export enum BackendUrls {
    UsersSignUp = 'users/signUp/',
    CreateUser = 'users/user/',
    UserLogIn = 'users/logIn/',
    UserLogOut = 'users/logOut/',
    IssueSessionByToken = 'users/issueSession/',
    UserGet = 'users/',
    UserMe = 'users/me/',
    UpdateUser = 'users/{userId}/',
    DisableUser = 'users/{userId}/disable/',
    EnableUser = 'users/{userId}/enable/',
    ArchiveUser = 'users/{userId}/archive/',
    UnarchiveUser = 'users/{userId}/unarchive/',
    ChangeUserType = 'users/{userId}/type/',
    ChangeUserPassword = 'users/{userId}/password/',
    GetUsersHistory = 'users/history/',

    GetOrders = 'orders/',
    RecalculateOrders = '/orders/recalculate/',
    CalculateOrdersCourierPayment = '/orders/calculateCourierPayment/',
    CreateOrders = 'orders/',
    PreCreateOrders = 'orders/pre-create/',
    UpdateOrderSimple = 'orders/{orderId}/',
    UpdateOrderWeight = 'orders/{orderId}/weight/',
    UpdateOrderLocation = 'orders/{orderId}/location/',
    UpdateOrderTiming = 'orders/{orderId}/timing/',
    UpdateOrderCashAndAnnouncedValue = 'orders/{orderId}/cashAndAnnouncedValue/',
    CloseOrder = 'orders/{orderId}/close/',
    OpenOrder = 'orders/{orderId}/open/',
    UpdateOrderClose = 'orders/{orderId}/updateClose/',
    UpdateOrderCustomer = 'orders/{orderId}/customer/',
    UpdateOrderExecutor = 'orders/{orderId}/executor/',
    UpdateOrderKind = 'orders/{orderId}/kind/',
    UpdateOrderManualControl = 'orders/{orderId}/manualControl/',
    AcceptOrder = 'orders/{orderId}/accept/',
    UpdateOrderStatus = 'orders/{orderId}/status/',
    RemoveOrder = 'orders/{orderId}/remove/',
    RestoreOrder = 'orders/{orderId}/restore/',
    GetOrderStatusesConfig = 'orders/configs/orderStatuses/',
    GetOrderFastTravelStatuses = 'orders/{orderId}/fastTravelStatuses/',
    GetOrderTransitionStatuses = 'orders/{orderId}/transitionStatuses/',
    GetOrderWhy = 'orders/why/',
    GetOrdersInsuranceGroupsWhy = 'orders/insuranceGroupsWhy/',
    GetCanIUpdateOrder = 'orders/{orderId}/canIUpdate/',
    GetOrdersHistory = 'orders/history/',

    GetOrdersCustomCosts = 'orders/customization/cost/',
    GetOrdersCustomsInsuranceFee = 'orders/customization/insuranceFee/',
    GetOrdersCustomsRKO = 'orders/customization/RKO/',
    GetOrdersCustomsPaymentToExecutor = 'orders/customization/paymentToExecutor/',

    GetAccountsScheme = '/accounting/accounts/scheme/',
    GetSyntheticAccountsBalanceSheet = '/accounting/accounts/{accountId}/',
    GetAnalyticAccountsBalanceSheet = '/accounting/accounts/users/{accountId}/',

    CreateOperation = '/accounting/operations/',
    UpdateOperation = '/accounting/operations/{operationId}/',
    GetAccountsOperations = '/accounting/operations/',
    RemoveOperation = '/accounting/operations/{operationId}/remove/',
    ApproveOperation = '/accounting/operations/{operationId}/approve/',
    UnapproveOperation = '/accounting/operations/{operationId}/unapprove/',
    UpdateOperationPayments = '/accounting/operations/{operationId}/payments/',
    GetOperationsGroupingData = '/accounting/operations/grouping/date/',
    GetOperationsGroupingType = '/accounting/operations/grouping/date/type/',
    GetOperationsHistory = '/accounting/operations/history/',

    GetСanIUpdateOperation = '/accounting/operations/{operationId}/canIUpdate/',
    GetСanIChangeApproveStateOperation = '/accounting/operations/{operationId}/canIChangeApproveState/',
    GetСanIRemoveOperation = '/accounting/operations/{operationId}/canIRemove/',


    GetWarehouses = 'orders/warehouse/',
    GetOrderCustomKinds = 'orders/customization/kind/',

    GetUserGeoPositions = 'geo/position/user',
    GetCouriersCurrentGeoPosition = 'geo/position/courier/current',
    GetAddressSuggestions = 'geo/address/suggestions',

    CreateGeozones = '/geo/geozones',
    CreateCustomGeozone = '/geo/geozone/custom',
    UpdateGeozoneCoordinates = '/geo/geozone/{geozoneId}/coordinates',
    UpdateGeozoneFields = '/geo/geozone/{geozoneId}',
    UpdateCustomGeozone = '/geo/geozone/custom/{customGeozoneId}',
    RemoveGeozone = '/geo/geozone/{geozoneId}',
    RemoveCustomGeozone = '/geo/geozone/custom/{customGeozoneId}',
    GetGeozones = '/geo/geozones',
    GetCustomGeozones = '/geo/geozones/custom',

    /** frontback */
    FromFrontendReportError = 'frontback/v2/fromFrontend/report/error',

    ParseOrdersCsv = 'frontback/v2/orders/parse/csv',
    DownloadOrdersCsv = 'frontback/v2/orders/download',
    DownloadOperationsCsv = 'frontback/v2/operations/download',
    GenerateOrderInvoices = 'frontback/v2/orders/invoices',
    GenerateRegisterInvoice = 'frontback/v2/orders/register/invoice',

    /** integrations */
    GetVVOrdersGeo = 'integrations/vv/support/orders/candidate',
    GetVVOrderContest = 'integrations/vv/support/order/:vvOrderId/contest',
    GetVVCourierLimits = 'integrations/vv/support/courier/:courierId/limits',
    UpdateVVCourierLimits = 'integrations/vv/support/courier/limits',
    CreateCourierVVOrderRecommendation = 'integrations/vv/courier/order/recommendation',
    GetCourierOrderVVRecommendations = 'integrations/vv/courier/order/recommendation',
    RemoveCourierOrderVVRecommendations = 'integrations/vv/courier/order/recommendation',
}

const devServerHostname = `${location.protocol}//test-skd-delivery.ru`;
const serverHostname = `${location.origin}`;

export const serverHttpHost =
    document.location.search.search('local') === -1
        ?
        IS_DEVELOPMENT
            ? devServerHostname
            : serverHostname
        : `${location.protocol}//localhost:7000`;

export const staticBaseUrl = `${serverHttpHost}/static/`;
export const IMPORT_DELIVERY_TEMPLATE = `${staticBaseUrl}importDeliveryTemplate.csv`;
export const IMPORT_PICKUP_TEMPLATE = `${staticBaseUrl}importPickupTemplate.csv`;
export const IMPORT_FULFILLMENT_TEMPLATE = `${staticBaseUrl}importFulfillmentTemplate.csv`;
