import './CheckTranslatePage.scss';
import locales from './CheckTranslatePage.i18n';

import React, {
    useEffect,
    useState,
} from 'react';

import i18n from 'src/libs/i18n';

const b = require('b_').with('check-translate-page');
const __ = i18n(locales);
const ALLOW_HIDE_BLOCK = false;

function checkIsTranslated() {
    return document.documentElement.classList.value.includes('translated');
}

export default function CheckTranslatePage() {
    const [isTranslated, setIsTranslated] = useState(checkIsTranslated());
    const [isHide, setIsHide] = useState(false);

    useEffect(() => {
        const observer = new MutationObserver(() => {
            setIsTranslated(checkIsTranslated());
        });

        observer.observe(document.documentElement, { attributes: true });

        return () => {
            observer.disconnect();
        };
    }, []);

    if (!isTranslated || isHide) {
        return null;
    }

    return (
        <div className={b()}>
            {__('advice')}
            {ALLOW_HIDE_BLOCK && <div className={b('cancel')} onClick={() => setIsHide(true)}/>}
        </div>
    );
}
