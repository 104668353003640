import {
    IShortOptionValueToFullOptionValues,
} from 'backend2/interfaces/common';

import _ from 'lodash';

export enum UserType {
    Admin = 'admin',
    SkdStaffAccountant = 'skd_staff_accountant',
    SkdStaffRecruiter = 'skd_staff_recruiter',
    SkdStaffAccountManager = 'skd_staff_account_manager',
    SkdStaffSalesManager = 'skd_staff_sales_manager',
    SkdStaffLogistic = 'skd_staff_logistic',
    SkdStaffLawyer = 'skd_staff_lawyer',
    SkdStaffOperator = 'skd_staff_operator',
    Executor = 'executor',
    Customer = 'customer',
}

export const SKD_STAFF_USER_TYPES = [
    UserType.SkdStaffAccountant,
    UserType.SkdStaffRecruiter,
    UserType.SkdStaffAccountManager,
    UserType.SkdStaffSalesManager,
    UserType.SkdStaffLogistic,
    UserType.SkdStaffLawyer,
    UserType.SkdStaffOperator,
];
export const CUSTOMER_AND_EXECUTOR_VALUES = [UserType.Customer, UserType.Executor];

export const VALID_USER_TYPES_VALUES = Object.values(UserType);
export const VALID_USER_TYPES_VALUES_WITHOUT_ADMIN = VALID_USER_TYPES_VALUES.filter((userType) => ![UserType.Admin].includes(userType));
export const VALID_USER_TYPES_VALUES_WITHOUT_CUSTOMER = VALID_USER_TYPES_VALUES.filter(userType => ![UserType.Customer].includes(userType));
export const VALID_USER_TYPES_VALUES_WITHOUT_EXECUTOR = VALID_USER_TYPES_VALUES.filter(userType => ![UserType.Executor].includes(userType));
export const SKD_STAFF_USER_TYPES_AND_ADMIN = SKD_STAFF_USER_TYPES.concat(UserType.Admin);

export enum UserPaperworkType {
    Individual = 'individual',
    SelfEmployed = 'self_employed',
    IndividualEntrepreneur = 'individual_entrepreneur',
    OOO = 'OOO',
    JuridicalPerson = 'juridical_person',
}

export const VALID_USER_PAPERWORK_TYPES_VALUES = Object.values(UserPaperworkType);
export const CUSTOMER_VALID_PAPERWORK_TYPE = [
    UserPaperworkType.Individual,
    UserPaperworkType.IndividualEntrepreneur,
    UserPaperworkType.OOO,
    UserPaperworkType.JuridicalPerson,
];
export const EXECUTOR_VALID_PAPERWORK_TYPE = [
    UserPaperworkType.Individual,
    UserPaperworkType.SelfEmployed,
    UserPaperworkType.IndividualEntrepreneur,
    UserPaperworkType.OOO,
    UserPaperworkType.JuridicalPerson,
];

export enum ExecutorKind {
    CourierPedestrian = 'courier_pedestrian',
    CourierCar = 'courier_car',
    PartnerCompany = 'partner_company',
    CourierBike = 'courier_bike',
}

export const VALID_EXECUTOR_KINDS_VALUES = Object.values(ExecutorKind);

export enum GetUsersDetailType {
    Default = 'all',
    None = 'none',
    Type = 'type',
    PaperworkType = 'paperworkType',
    All = 'all',
}

export const VALID_GET_USERS_DETAIL_TYPES_VALUES = _.uniq(Object.values(GetUsersDetailType));

export enum Field {
    UserId = 'user.id',
    UserTypeEnum = 'user.typeEnum',
    UserPaperworkTypeEnum = 'user.paperworkTypeEnum',
    UserIsDisabled = 'user.isDisabled',
    UserLogin = 'user.login',
    UserPasswordHash = 'user.passwordHash',
    UserEmail = 'user.email',
    UserPhone = 'user.phone',
    UserInvitedByUserId = 'user.invitedByUserId',
    UserIsArchived = 'user.isArchived',
    UserComment = 'user.comment',

    UserCreatedTimestamp = 'user.createdTimestamp',

    CustomerUserId = 'customer.userId',
    CustomerBrandName = 'customer.brandName',
    CustomerTelegramGroupId = 'customer.telegramGroupId',

    ExecutorUserId = 'executor.userId',
    ExecutorKindEnum = 'executor.kindEnum',
    ExecutorSubway = 'executor.subway',
    ExecutorAddress = 'executor.address',
    ExecutorLinkedCustomerUserId = 'executor.linkedCustomerUserId',

    SkdStaffUserId = 'skdStaff.userId',
    SkdStaffSurname = 'skdStaff.surname',
    SkdStaffName = 'skdStaff.name',
    SkdStaffDateOfBirth = 'skdStaff.dateOfBirth',

    AdminUserId = 'admin.userId',
    AdminName = 'admin.name',

    IndividualUserId = 'individual.userId',
    IndividualSurname = 'individual.surname',
    IndividualName = 'individual.name',
    IndividualPatronymic = 'individual.patronymic',
    IndividualDateOfBirth = 'individual.dateOfBirth',
    IndividualBankcardNumber = 'individual.bankcardNumber',

    SelfEmployedUserId = 'selfEmployed.userId',
    SelfEmployedSurname = 'selfEmployed.surname',
    SelfEmployedName = 'selfEmployed.name',
    SelfEmployedPatronymic = 'selfEmployed.patronymic',
    SelfEmployedDateOfBirth = 'selfEmployed.dateOfBirth',
    SelfEmployedINN = 'selfEmployed.inn',
    SelfEmployedBankcardNumber = 'selfEmployed.bankcardNumber',

    IndividualEntrepreneurINN = 'individualEntrepreneur.inn',
    IndividualEntrepreneurOGRN = 'individualEntrepreneur.ogrn',
    IndividualEntrepreneurLegalAddress = 'individualEntrepreneur.legalAddress',
    IndividualEntrepreneurActualLocationAddress = 'individualEntrepreneur.actualLocationAddress',
    IndividualEntrepreneurNameOfTheBank = 'individualEntrepreneur.nameOfTheBank',
    IndividualEntrepreneurRCBIC = 'individualEntrepreneur.rcbic',
    IndividualEntrepreneurCorrespondentAccount = 'individualEntrepreneur.correspondentAccount',
    IndividualEntrepreneurCheckingAccount = 'individualEntrepreneur.checkingAccount',
    IndividualEntrepreneurUserId = 'individualEntrepreneur.userId',
    IndividualEntrepreneurSurname = 'individualEntrepreneur.surname',
    IndividualEntrepreneurName = 'individualEntrepreneur.name',
    IndividualEntrepreneurPatronymic = 'individualEntrepreneur.patronymic',

    OOOINN = 'OOO.inn',
    OOOOGRN = 'OOO.ogrn',
    OOOLegalAddress = 'OOO.legalAddress',
    OOOActualLocationAddress = 'OOO.actualLocationAddress',
    OOONameOfTheBank = 'OOO.nameOfTheBank',
    OOORCBIC = 'OOO.rcbic',
    OOOCorrespondentAccount = 'OOO.correspondentAccount',
    OOOCheckingAccount = 'OOO.checkingAccount',
    OOOUserId = 'OOO.userId',
    OOOName = 'OOO.name',
    OOOKPP = 'OOO.kpp',

    JuridicalPersonINN = 'juridicalPerson.inn',
    JuridicalPersonOGRN = 'juridicalPerson.ogrn',
    JuridicalPersonLegalAddress = 'juridicalPerson.legalAddress',
    JuridicalPersonActualLocationAddress = 'juridicalPerson.actualLocationAddress',
    JuridicalPersonNameOfTheBank = 'juridicalPerson.nameOfTheBank',
    JuridicalPersonRCBIC = 'juridicalPerson.rcbic',
    JuridicalPersonCorrespondentAccount = 'juridicalPerson.correspondentAccount',
    JuridicalPersonCheckingAccount = 'juridicalPerson.checkingAccount',
    JuridicalPersonUserId = 'juridicalPerson.userId',
    JuridicalPersonOrganizationalAndLegalForm = 'juridicalPerson.organizationalAndLegalForm',
    JuridicalPersonName = 'juridicalPerson.name',
    JuridicalPersonKPP = 'juridicalPerson.kpp',
}

export const VALID_USER_FIELD_VALUES = Object.values(Field);

export const USER_FIELD_DICTIONARY: Record<'ru', Record<Field, string>> = {
    ru: {
        [Field.UserId]: 'id',
        [Field.UserTypeEnum]: 'Тип юзера',
        [Field.UserPaperworkTypeEnum]: 'Вид оформления',
        [Field.UserIsDisabled]: 'Заблокирован',
        [Field.UserLogin]: 'Логин',
        [Field.UserPasswordHash]: 'Пароль',
        [Field.UserEmail]: 'Почта',
        [Field.UserPhone]: 'Телефон',
        [Field.UserInvitedByUserId]: 'Пригласивший юзер',
        [Field.UserIsArchived]: 'Активация',
        [Field.UserComment]: 'Комментарий для юзера',
        [Field.UserCreatedTimestamp]: 'Дата создания',
        [Field.CustomerUserId]: 'id',
        [Field.CustomerBrandName]: 'Название бренда',
        [Field.CustomerTelegramGroupId]: 'id чата в telegram',
        [Field.ExecutorUserId]: 'id',
        [Field.ExecutorKindEnum]: 'Вид курьера',
        [Field.ExecutorSubway]: 'Метро',
        [Field.ExecutorAddress]: 'Адрес',
        [Field.ExecutorLinkedCustomerUserId]: 'Привязанный проект',
        [Field.SkdStaffUserId]: 'id',
        [Field.SkdStaffSurname]: 'Фамилия',
        [Field.SkdStaffName]: 'Имя',
        [Field.SkdStaffDateOfBirth]: 'Отчество',
        [Field.AdminUserId]: 'id',
        [Field.AdminName]: 'Имя',
        [Field.IndividualUserId]: 'id',
        [Field.IndividualSurname]: 'Фамилия',
        [Field.IndividualName]: 'Имя',
        [Field.IndividualPatronymic]: 'Отчество',
        [Field.IndividualDateOfBirth]: 'Дата рождения',
        [Field.IndividualBankcardNumber]: 'Номер карты',
        [Field.SelfEmployedUserId]: 'id',
        [Field.SelfEmployedSurname]: 'Фамилия',
        [Field.SelfEmployedName]: 'Имя',
        [Field.SelfEmployedPatronymic]: 'Отчество',
        [Field.SelfEmployedDateOfBirth]: 'Дата рождения',
        [Field.SelfEmployedINN]: 'ИНН',
        [Field.SelfEmployedBankcardNumber]: 'Номер карты',
        [Field.IndividualEntrepreneurINN]: 'ИНН',
        [Field.IndividualEntrepreneurOGRN]: 'ОГРН',
        [Field.IndividualEntrepreneurLegalAddress]: 'Юридический адрес',
        [Field.IndividualEntrepreneurActualLocationAddress]: 'Адрес фактического местонахождения',
        [Field.IndividualEntrepreneurNameOfTheBank]: 'Наименование банка',
        [Field.IndividualEntrepreneurRCBIC]: 'БИК',
        [Field.IndividualEntrepreneurCorrespondentAccount]: 'Корреспондентский счет',
        [Field.IndividualEntrepreneurCheckingAccount]: 'Расчетный счет',
        [Field.IndividualEntrepreneurUserId]: 'id',
        [Field.IndividualEntrepreneurSurname]: 'Фамилия',
        [Field.IndividualEntrepreneurName]: 'Имя',
        [Field.IndividualEntrepreneurPatronymic]: 'Отчество',
        [Field.OOOINN]: 'ИНН',
        [Field.OOOOGRN]: 'ОГРН',
        [Field.OOOLegalAddress]: 'Юридический адрес',
        [Field.OOOActualLocationAddress]: 'Адрес фактического местонахождения',
        [Field.OOONameOfTheBank]: 'Наименование банка',
        [Field.OOORCBIC]: 'БИК',
        [Field.OOOCorrespondentAccount]: 'Корреспондентский счет',
        [Field.OOOCheckingAccount]: 'Расчетный счет',
        [Field.OOOUserId]: 'id',
        [Field.OOOName]: 'Имя',
        [Field.OOOKPP]: 'КПП',
        [Field.JuridicalPersonINN]: 'ИНН',
        [Field.JuridicalPersonOGRN]: 'ОГРН',
        [Field.JuridicalPersonLegalAddress]: 'Юридический адрес',
        [Field.JuridicalPersonActualLocationAddress]: 'Адрес фактического местонахождения',
        [Field.JuridicalPersonNameOfTheBank]: 'Наименование банка',
        [Field.JuridicalPersonRCBIC]: 'БИК',
        [Field.JuridicalPersonCorrespondentAccount]: 'Корреспондентский счет',
        [Field.JuridicalPersonCheckingAccount]: 'Расчетный счет',
        [Field.JuridicalPersonUserId]: 'id',
        [Field.JuridicalPersonOrganizationalAndLegalForm]: 'Организационно-правовая форма',
        [Field.JuridicalPersonName]: 'Имя',
        [Field.JuridicalPersonKPP]: 'КПП',
    },
};

export const USER_TYPE_ENUM_DICTIONARY: Record<'ru', Record<UserType, string>> = {
    ru: {
        [UserType.Admin]: 'Админ',
        [UserType.SkdStaffAccountant]: 'Бухгалтер',
        [UserType.SkdStaffRecruiter]: 'Рекрутер',
        [UserType.SkdStaffAccountManager]: 'Расчетный менеджер',
        [UserType.SkdStaffSalesManager]: 'Менеджер по продажам',
        [UserType.SkdStaffLogistic]: 'Логист',
        [UserType.SkdStaffLawyer]: 'Служба безопасности',
        [UserType.SkdStaffOperator]: 'Оператор',
        [UserType.Executor]: 'Курьер',
        [UserType.Customer]: 'Заказчик',
    },
};

export const USER_PAPERWORK_TYPE_ENUM_DICTIONARY: Record<'ru', Record<UserPaperworkType, string>> = {
    ru: {
        [UserPaperworkType.Individual]: 'Частное лицо',
        [UserPaperworkType.SelfEmployed]: 'Самозанятый',
        [UserPaperworkType.IndividualEntrepreneur]: 'Индивидуальный предприниматель',
        [UserPaperworkType.OOO]: 'OOO',
        [UserPaperworkType.JuridicalPerson]: 'Юридическое лицо',
    },
};

export const EXECUTOR_KIND_ENUM_DICTIONARY: Record<'ru', Record<ExecutorKind, string>> = {
    ru: {
        [ExecutorKind.CourierPedestrian]: 'Пеший',
        [ExecutorKind.CourierCar]: 'Авто',
        [ExecutorKind.PartnerCompany]: 'Партнер',
        [ExecutorKind.CourierBike]: 'Вело',
    },
};

export const FRONTEND_USER_TYPE_SHORT_DROPDOWN_OPTIONS: IShortOptionValueToFullOptionValues[] = [
    {
        name: {
            ru: 'работник SKD',
        },
        values: SKD_STAFF_USER_TYPES,
    },
];
