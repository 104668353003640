import {
    ICount,
} from 'backend2/interfaces/common';
import {
    IFullOrderCustomKind,
} from 'backend2/interfaces/orderCustomKind';
import {
    IFullOrderCustomCost,
} from 'backend2/interfaces/orderCustomCost';
import {
    IFullOrderCustomInsuranceFee,
} from 'backend2/interfaces/orderCustomInsuranceFee';
import {
    IFullOrderCustomRKO,
} from 'backend2/interfaces/orderCustomRKO';
import {
    IFullOrderCustomPaymentToExecutor,
} from 'backend2/interfaces/orderCustomPaymentToExecutor';

import {
    makeAutoObservable,
    runInAction,
} from 'mobx';

import {
    getOrderCustomKinds,
    getOrdersCustomCosts,
    getOrdersCustomsInsuranceFee,
    getOrdersCustomsRKO,
    getOrdersCustomsPaymentToExecutor,
} from 'src/dataProviders/orders';


class ObservableCustomsStore {
    orderCustomKinds: Record<string, IFullOrderCustomKind> = {};
    fullOrderCustomCosts: Record<string, IFullOrderCustomCost> = {};
    fullOrderCustomsInsuranceFee: Record<string, IFullOrderCustomInsuranceFee> = {};
    fullOrderCustomsRKO: Record<string, IFullOrderCustomRKO> = {};
    fullOrderCustomsPaymentToExecutor: Record<string, IFullOrderCustomPaymentToExecutor> = {};

    constructor() {
        makeAutoObservable(this, null, {autoBind: true});
    }

    get customs() {
        return {
            orderCustomKinds: Object.values(this.orderCustomKinds),
            fullOrderCustomCosts: Object.values(this.fullOrderCustomCosts),
            fullOrderCustomsInsuranceFee: Object.values(this.fullOrderCustomsInsuranceFee),
            fullOrderCustomsRKO: Object.values(this.fullOrderCustomsRKO),
            fullOrderCustomsPaymentToExecutor: Object.values(this.fullOrderCustomsPaymentToExecutor),
        };
    }

    get notRemovedCustoms() {
        const customs = this.customs;
        return {
            orderCustomKinds: customs.orderCustomKinds.filter(({orderCustomKind}) => !orderCustomKind.isRemoved),
            fullOrderCustomCosts: customs.fullOrderCustomCosts.filter(({commonCustom}) => !commonCustom.isRemoved),
            fullOrderCustomsInsuranceFee: customs.fullOrderCustomsInsuranceFee.filter(({commonCustom}) => !commonCustom.isRemoved),
            fullOrderCustomsRKO: customs.fullOrderCustomsRKO.filter(({commonCustom}) => !commonCustom.isRemoved),
            fullOrderCustomsPaymentToExecutor: customs.fullOrderCustomsPaymentToExecutor.filter(({commonCustom}) => !commonCustom.isRemoved),
        };
    }

    getCustomKind(id: number) {
        return this.orderCustomKinds[id];
    }

    getCustomerCustomKinds(customerUserId: number) {
        return this.customs.orderCustomKinds.filter(orderCustomKind => orderCustomKind.orderCustomKind.customerUserId === customerUserId);
    }

    async fetchOrderCustomKinds(...params: Parameters<typeof getOrderCustomKinds>) {
        const customs = await getOrderCustomKinds(...params);
        runInAction(() => {
            const patch: typeof this.orderCustomKinds = {};
            for (const custom of customs) {
                patch[custom.orderCustomKind.id] = custom;
            }
            this.orderCustomKinds = {...this.orderCustomKinds, ...patch};
        });
        return customs;
    }
    async fetchOrderCustomKindsCount(params: Parameters<typeof getOrderCustomKinds>[0]) {
        const count = await getOrderCustomKinds({
            ...params,
            isCountMode: true,
        });
        // @ts-ignore
        count.count = BigInt(count.count);
        return count as unknown as ICount;
    }

    async fetchOrdersCustomCosts(...params: Parameters<typeof getOrdersCustomCosts>) {
        const customs = await getOrdersCustomCosts(...params);
        runInAction(() => {
            const patch: typeof this.fullOrderCustomCosts = {};
            for (const custom of customs) {
                patch[custom.orderCustomCost.id] = custom;
            }
            this.fullOrderCustomCosts = {...this.fullOrderCustomCosts, ...patch};
        });
        return customs;
    }
    async fetchOrdersCustomCostsCount(params: Parameters<typeof getOrdersCustomCosts>[0]) {
        const count = await getOrdersCustomCosts({
            ...params,
            isCountMode: true,
        });
        // @ts-ignore
        count.count = BigInt(count.count);
        return count as unknown as ICount;
    }

    async fetchOrdersCustomsInsuranceFee(...params: Parameters<typeof getOrdersCustomsInsuranceFee>) {
        const customs = await getOrdersCustomsInsuranceFee(...params);
        runInAction(() => {
            const patch: typeof this.fullOrderCustomsInsuranceFee = {};
            for (const custom of customs) {
                patch[custom.orderCustomInsuranceFee.id] = custom;
            }
            this.fullOrderCustomsInsuranceFee = {...this.fullOrderCustomsInsuranceFee, ...patch};
        });
        return customs;
    }
    async fetchOrdersCustomsInsuranceFeeCount(params: Parameters<typeof getOrdersCustomsInsuranceFee>[0]) {
        const count = await getOrdersCustomsInsuranceFee({
            ...params,
            isCountMode: true,
        });
        // @ts-ignore
        count.count = BigInt(count.count);
        return count as unknown as ICount;
    }

    async fetchOrdersCustomsRKO(...params: Parameters<typeof getOrdersCustomsRKO>) {
        const customs = await getOrdersCustomsRKO(...params);
        runInAction(() => {
            const patch: typeof this.fullOrderCustomsRKO = {};
            for (const custom of customs) {
                patch[custom.orderCustomRKO.id] = custom;
            }
            this.fullOrderCustomsRKO = {...this.fullOrderCustomsRKO, ...patch};
        });
        return customs;
    }
    async fetchOrdersCustomsRKOCount(params: Parameters<typeof getOrdersCustomsRKO>[0]) {
        const count = await getOrdersCustomsRKO({
            ...params,
            isCountMode: true,
        });
        // @ts-ignore
        count.count = BigInt(count.count);
        return count as unknown as ICount;
    }

    async fetchOrdersCustomsPaymentToExecutor(...params: Parameters<typeof getOrdersCustomsPaymentToExecutor>) {
        const customs = await getOrdersCustomsPaymentToExecutor(...params);
        runInAction(() => {
            const patch: typeof this.fullOrderCustomsPaymentToExecutor = {};
            for (const custom of customs) {
                patch[custom.orderCustomPaymentToExecutor.id] = custom;
            }
            this.fullOrderCustomsPaymentToExecutor = {...this.fullOrderCustomsPaymentToExecutor, ...patch};
        });
        return customs;
    }
    async fetchOrdersCustomsPaymentToExecutorCount(params: Parameters<typeof getOrdersCustomsPaymentToExecutor>[0]) {
        const count = await getOrdersCustomsPaymentToExecutor({
            ...params,
            isCountMode: true,
        });
        // @ts-ignore
        count.count = BigInt(count.count);
        return count as unknown as ICount;
    }

}

const CustomsStore = new ObservableCustomsStore();
export default CustomsStore;
