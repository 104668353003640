import React from 'react';
import Loadable from 'react-loadable';

import Loading from 'skd-ui/Loading/Loading';

export default Loadable({
    loader: () => {
        // for scrollIntoView in componentDidMount css must load first
        require('src/controllers/Help/Help.scss');
        return import(/*webpackChunkName: 'Help'*/'src/controllers/Help/Help');
    },
    loading: Loading,
});
