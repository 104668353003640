import get from 'lodash-es/get';

type Data = Record<string, any>;

const DEFAULT_LOCALE = 'ru';

const declension: Record<string, (count: number, variants: string[]) => string> = {

    /**
     * @param count
     * @param {string[]} variants (Nominative, Genetive, Plural)
     * @returns {string}
     */
    ru(count, variants) {
        count = Math.abs(count);
        count %= 100;

        if (count >= 5 && count <= 20) {
            return variants[2];
        }

        count %= 10;

        if (count === 1) {
            return variants[0];
        }

        if (count >= 2 && count <= 4) {
            return variants[1];
        }

        return variants[2];
    },

    en(count, variants) {
        const i = Math.floor(Math.abs(count));
        const v = count.toString().replace(/^[^.]*\.?/, '').length;

        if (i === 1 && v === 0) {
            return variants[0];
        }

        return variants[1];
    },

};

export default function i18n(data: Data = ({} as Data), locale: string = DEFAULT_LOCALE) {
    return (key: string, replacer: any = {}, multiplier: number = 1, skipError = false) => {
        let value = get(data[locale], key, undefined);
        replacer = replacer || {};

        if (typeof multiplier === 'number' && Array.isArray(value)) {
            value = declension[locale](multiplier, value);
        }

        if (typeof value === 'undefined' && !skipError) {
            return null;
        }

        if (value && Object.keys(replacer).length) {
            value = value.replace(/{([^{}]*)}/ig, (match: string, p1: number) => replacer[p1]);
        }

        return prepare(value);
    };
}

/**
 * [‘str1’, ‘str2’, <jsx/>, ‘str3’, ‘str4’] -> [‘str1str2’, <jsx/>, ‘str3str4’]
 * [‘str1’, ‘str2’, ‘str3’] -> ‘str1str2str3’
 */

function prepare(value: any) {
    if (!Array.isArray(value)) {
        return value;
    }

    const acc = value.reduce((accum, cur, ind) => {
        const lastElemInd = accum.length - 1;
        if (typeof cur === 'string'
            && typeof accum[lastElemInd] === 'string'
            && ind !== 0
        ) {
            accum.splice(lastElemInd, 1, `${accum[lastElemInd]}${cur}`);
            return accum;
        } else {
            return [
                ...accum,
                cur,
            ];
        }
    }, []);

    return (acc.length === 1 && typeof acc[0] === 'string')
        ? acc[0]
        : acc;
}
