import {
    DeepPartial,
} from 'backend2/types';

import {
    MutableRefObject,
} from 'react';

import isEmpty from 'lodash-es/isEmpty';
import _merge from 'lodash-es/merge';
import mergeWith from 'lodash-es/mergeWith';
import cloneDeep from 'lodash-es/cloneDeep';
import isArray from 'lodash-es/isArray';
import omitBy from 'lodash-es/omitBy';
import isNil from 'lodash-es/isNil';
import transform from 'lodash-es/transform';
import isObject from 'lodash-es/isObject';
import isEqual from 'lodash-es/isEqual';

export function constructImmutabilityQueryAddArrayOfObjects<T = any>(entities: T[], calculateIndex: (entity: T, state: any) => number, state: any) {
    const entityPatch: Record<number, {'$set': T}> = {};
    const willPushedEntities = [];
    for (const entity of entities) {
        const indexInStore = calculateIndex(entity, state);
        if (indexInStore !== -1) {
            entityPatch[indexInStore] = {
                $set: entity,
            };
        } else {
            willPushedEntities.push(entity);
        }
    }
    return {
        ...entityPatch,
        ...(willPushedEntities.length && {$push: willPushedEntities}),
    };
}

export function constructImmutabilityQueryUpdateArrayOfObjects<T = any>(entities: T[], calculateIndex: (entity: T, state: any) => number, state: any) {
    const entityPatch: Record<number, {'$set': T}> | {'$none': {}} = {};
    for (const entity of entities) {
        const indexInStore = calculateIndex(entity, state);
        if (indexInStore !== -1) {
            entityPatch[indexInStore] = {
                $set: entity,
            };
        }
    }

    if (isEmpty(entityPatch)) {
        return {
            '$none': {},
        };
    }

    return entityPatch;
}


export function mergeEntities<T extends {id?: number}>(entitiesBefore: T[], newEntities: T[]) {
    // начинаем заполнение нового массива энтити, со старых энтити
    const mergedEntities: T[] = entitiesBefore.map(entityBefore => {
        const newEntity = newEntities.find(({id}) => id === entityBefore.id);
        // новая энтити не меняет старую
        if (!newEntity) {
            return entityBefore;
        }
        // новая энтити меняет старую
        return mergeWith(entityBefore, newEntity, (oldValue, newValue) => {
            if (oldValue && newValue && oldValue.id && newValue.id) {
                return newValue;
            }
            if (isArray(oldValue)) {
                return oldValue.concat(newValue);
            }
        });
    });
    newEntities.forEach(newEntity => {
       if (mergedEntities.findIndex(({id}) => id === newEntity.id) === -1) {
           mergedEntities.push(newEntity);
       }
    });

    return mergedEntities;
}

/**
 * Deep diff between two object, using lodash return a new object who represent the diff
 * @link https://gist.github.com/Yimiprod/7ee176597fef230d1451
 */
export function difference<T extends object>(objectToInspect: T, objectToExclude: T, fieldsNotRemoved: string[] = []) {
    if (!objectToInspect || !objectToExclude) {
        return {};
    }
    const changes = (object: any, base: any) => transform(object, (result, value, key) => {
        if (typeof key === 'string' && fieldsNotRemoved.includes(key)) {
            result[key] = value;
            return;
        }
        if (!isEqual(value, base[key])) {
            // @ts-ignore
            result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
        }
    });
    return changes(objectToInspect, objectToExclude);
}

export function objectToPaths(object: object): string[] {
    return Object.keys(object).reduce((accum, key) => {
        // @ts-ignore
        const value = object[key];
        if (isObject(value)) {
            return accum.concat(objectToPaths(value).map(nestedKey => `${key}.${nestedKey}`));
        }
        return accum.concat(key);
    }, []);
}

export function merge(obj1: Record<string, any>, obj2: Record<string, any>) {
    return _merge(cloneDeep(obj1), cloneDeep(obj2));
}

export function omitObject<T extends object>(value: T) {
    // todo: return type
    return omitBy<DeepPartial<T>>(value, omitFieldsByPredicate) as unknown as T;
}

export function omitObjectNil<T extends object>(value: T) {
    return omitBy<DeepPartial<T>>(value, isNil) as unknown as T;
}

export function omitObjectNull<T extends object>(value: T) {
    return omitBy<DeepPartial<T>>(value, v => v === null) as unknown as T;
}

function omitFieldsByPredicate(value: unknown) {
    if (isObject(value)) {
        return isEmpty(value);
    }
    if (isArray(value)) {
        return Boolean(value.length);
    }

    return typeof value === 'undefined';
}

export function clearTimeoutIfExist(timeout: MutableRefObject<NodeJS.Timeout>) {
    if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
    }
}
