import {
    IFetchOptions,
} from 'src/types/fetch';
import {
    CommonQueryParams,
} from 'src/constants/url';
import {
    CustomHeaders,
} from 'backend2/types/network';

import axios, {
    AxiosError,
} from 'axios';
    import qs from 'qs';
import isEmpty from 'lodash-es/isEmpty';
import isArray from 'lodash-es/isArray';
import isPlainObject from 'lodash-es/isPlainObject';

import {
    FrontendUrls,
    serverHttpHost,
} from 'src/constants/url';

import {
    getLocation,
    pushUrl,
} from 'src/libs/history';
import {
    fetchLoggerProcess,
    fetchLoggerSuccess,
    fetchLoggerFailed,
} from 'src/libs/logger';
import reportError from 'src/libs/reportError';

const DEFAULT_RETRIES = 120;
const NETWORK_ERROR_TIMEOUT = 3 * 1000;

const axiosInstance = axios.create({
    withCredentials: true,
});

// user?ids[]=1&ids[]=2 => user?ids=1&ids=2
axiosInstance.defaults.paramsSerializer = params => qs.stringify(params, {
    indices: false,
});

const timezoneOffset = new Date().getTimezoneOffset();

import gererateUniqId from 'uniqid';

const sessionId = gererateUniqId();

export default function fetch<T>(options: IFetchOptions, parseData = true): Promise<T> {
    fetchLoggerProcess(options);

    const requestId = gererateUniqId();

    const wrapFetch = async (options: IFetchOptions, retries: number = 0): Promise<any> => {
        try {
            if (retries < 0) {
                throw new Error('retries < 0');
            }
            retries--;

            const isRequestToFrontback = options.url.includes('frontback');
            const isRequestToIntegrations = options.url.includes('integrations');
            const {
                method,
                url,
                body,
                params,
                timeout = 120000,
                baseURL = (isRequestToFrontback || isRequestToIntegrations)
                    ? serverHttpHost
                    : `${serverHttpHost}/api/v2`,
                headers = {},
                ...optionsPart
            } = options;

            // to update request when apply filter
            if (params && 'ts' in params) {
                delete params.ts;
            }

            const response = await axiosInstance.request({
                baseURL,
                url,
                data: body,
                method,
                params,
                timeout,
                ...optionsPart,
                headers: {
                    'Content-type': 'application/json',
                    [CustomHeaders.ClientTimezoneOffset]: timezoneOffset,
                    [CustomHeaders.RequestId]: requestId,
                    [CustomHeaders.SessionId]: sessionId,
                    ...headers,
                },
            });
            if (
                (isPlainObject(response.data) && isEmpty(response.data))
                || isArray(response.data) && response.data.length !== 0 && response.data.every(isPlainObject) && response.data.every(isEmpty)
            ) {
                throw {
                    response: {
                        data: 'ERROR access rights!',
                    }
                };
            }
            fetchLoggerSuccess(options, response);

            if (parseData && !(isRequestToFrontback || isRequestToIntegrations)) {
                return response.data.data;
            }

            return response.data;
        } catch (error) {
            error.reqId = requestId;

            if (error.message === 'Network Error' || error.response.status === 502) {
                await new Promise(resolve => setTimeout(resolve, NETWORK_ERROR_TIMEOUT));
                return wrapFetch(options, retries);
            }
            if (error.code === 'ECONNABORTED') {
                reportError(error, constructExtraMessage({
                    ...error,
                    response: error.response || {},
                }));
                throw new Error(`Запрос отменен: ${error.message.replace(/timeout of (\d+ms) exceeded/, 'истек таймаут запроса в $1. Но он всё равно выполнился, проверьте.')}`);
            }
            if (error.response && error.response.status === 504) {
                reportError(error, constructExtraMessage(error));
                throw new Error('Истекло время ожидания сервера. Попробуйте повторить запрос.');
            }
            fetchLoggerFailed(options, error);

            if (error.response.status === 401 && getLocation().pathname !== FrontendUrls.LogIn) {
                const prevHref = window.location.toString();
                const url = new URL(prevHref);
                url.pathname = FrontendUrls.LogIn;
                url.search = '';
                url.searchParams.set(CommonQueryParams.BackUrl, window.location.pathname);
                window.location.assign(url.toString());
                throw error;
            }

            throw error;
        }
    };

    return wrapFetch(options, DEFAULT_RETRIES);
}

function constructExtraMessage(error: AxiosError) {
    try {
        const {
            config: {
                method,
                url,
                data: requestData = {},
                params = '',
                headers: requestHeaders = {},
                timeout,
            },
            response: {
                status,
                statusText,
                data: responseData = {},
                headers: responseHeaders = {},
            },
            code,
        } = error;

        return [
            `**[${method}] ${url}**`,
            `\`status\`: ${status} ${statusText}`,
            `\`code\`: ${code}`,
            `\`axios timeout\`: ${timeout}`,
            `requestData: ${JSON.stringify(requestData, null, 2)}`,
            `params: ${JSON.stringify(params, null, 2)}`,
            `responseData: ${JSON.stringify(responseData, null, 2)}`,
            `requestHeaders: ${JSON.stringify(requestHeaders, null, 2)}`,
            `responseHeaders: ${JSON.stringify(responseHeaders, null, 2)}`,
        ].join('\n');
    } catch (ex) {
        console.error(ex);
        return 'fetch';
    }
}
