import {
    combineReducers,
} from 'redux'
import {
    RouterState,
} from 'redux-first-history';
import {
    configureStore,
} from '@reduxjs/toolkit';
import {
    createReduxHistoryContext,
} from 'redux-first-history'
import thunkMiddleware from 'redux-thunk';
import {
    createHistory,
} from 'src/libs/history';

import accountingStore, {
    IAccountingReducer
} from 'src/reduxStore/reducers/accounting';

export interface IReduxState {
    router: RouterState;
    accountingStore: IAccountingReducer;
}

const {
    createReduxHistory,
    routerMiddleware,
    routerReducer
} = createReduxHistoryContext({
    history: createHistory(),
    basename: '/',
});

const store = configureStore({
    reducer: combineReducers({
        router: routerReducer,
        accountingStore,
    }),
    middleware: [thunkMiddleware, routerMiddleware],
});
// @ts-ignore
window.store = store;

export const history = createReduxHistory(store);

export default store;
