import {
    AxiosResponse,
    AxiosError,
} from 'axios';

import {
    IFetchOptions,
} from 'src/types/fetch';

const {
    NODE_ENV,
} = process.env;

const disableLoggers = NODE_ENV !== 'development';
const disableFetchLoggers = true;
const disableStorePatchLoggers = true;
const disableStoreStateLoggers = true;

export function reduxLoggerPatch(type: string, patch: any) {
    if (disableLoggers || disableStorePatchLoggers) {
        return;
    }
    console.log(`%c${type.toUpperCase()} PATCH: `, 'color: #a99cc8; font-weight:bold; font-size: 16px;', patch._data);
}

export function reduxLoggerState(prevState: any, newState: any) {
    if (disableLoggers || disableStoreStateLoggers) {
        return;
    }
    console.log('%cSTATE: ', 'color: #ff9f33; font-weight:bold; font-size: 16px;', {
        prevState: prevState._data,
        newState: newState._data,
    });
}

export function fetchLoggerProcess(options: IFetchOptions) {
    if (disableLoggers || disableFetchLoggers) {
        return;
    }
    const baseLog = `%c start request ${options.method} ${options.url}`;
    const logStyle = 'color: #12C9DC; font-weight:bold;';
    if (options.method === 'GET') {
        if (options.params) {
            console.log(baseLog, logStyle, options.params);
        } else {
            console.log(baseLog, logStyle);
        }
    } else if (options.method === 'POST' || options.method === 'PATCH') {
        if (options.body) {
            console.log(baseLog, logStyle, options.body);
        } else {
            console.log(baseLog, logStyle);
        }
    } else if (options.method === 'PUT') {
        console.log(baseLog, logStyle);
    }
}

export function fetchLoggerSuccess(options: IFetchOptions, response: AxiosResponse) {
    if (disableLoggers || disableFetchLoggers) {
        return;
    }
    console.log(`%c${options.method} ${options.url} ${response.status}`, 'color: #3ABD32; font-weight:bold;', response.data);
}

export function fetchLoggerFailed(options: IFetchOptions, error: AxiosError) {
    if (disableLoggers || disableFetchLoggers) {
        return;
    }
    console.log(`%c${options.method} ${options.url} ${error.response.status}`, 'color: #DC1212; font-weight:bold;', error.response.data);
}
