import {
    IFullUser,
} from 'backend2/interfaces/users';
import {
    IFullOperation,
} from 'backend2/interfaces/operations';
import {
    IFullOrder,
} from 'backend2/interfaces/orders';
import {
    IFullWarehouse,
} from 'backend2/interfaces/warehouses';
import {
    IFullOrderCustomKind,
} from 'backend2/interfaces/orderCustomKind';
import {
    DeepPartial,
} from 'backend2/types';
import {
    UserType,
    UserPaperworkType,
    SKD_STAFF_USER_TYPES,
} from 'backend2/types/users';
import {
    ORDER_KIND_ENUM_DICTIONARY,
    OrderType,
} from 'backend2/types/orders';

import FullOrder from 'backend2/dtos/orders/Orders/Order.dto';

import SkdMoment from 'backend2/libs/skdMoment';
import i18n from 'src/libs/i18n';

const LOCALE_ORDER_KIND_ENUM_DICTIONARY = ORDER_KIND_ENUM_DICTIONARY.ru;

// ===> access rights
export function checkIsCustomer(fullUser: IFullUser, typeEnum?: UserType): boolean {
    if (typeEnum) {
        return typeEnum === UserType.Customer;
    }
    return fullUser?.user?.typeEnum === UserType.Customer;
}

export function checkIsExecutor(fullUser: IFullUser, typeEnum?: UserType): boolean {
    if (typeEnum) {
        return typeEnum === UserType.Executor;
    }
    return fullUser?.user?.typeEnum === UserType.Executor;
}

export function checkIsWorkerSkd(fullUser: IFullUser, typeEnum?: UserType): boolean {
    if (typeEnum) {
        return SKD_STAFF_USER_TYPES.includes(typeEnum);
    }
    return SKD_STAFF_USER_TYPES.includes(fullUser?.user?.typeEnum);
}

export function checkIsAccountant(fullUser: IFullUser, typeEnum?: UserType): boolean {
    if (typeEnum) {
        return typeEnum === UserType.SkdStaffAccountant;
    }
    return fullUser?.user?.typeEnum === UserType.SkdStaffAccountant;
}

export function checkIsAccountManager(fullUser: IFullUser, typeEnum?: UserType): boolean {
    if (typeEnum) {
        return typeEnum === UserType.SkdStaffAccountManager;
    }
    return fullUser?.user?.typeEnum === UserType.SkdStaffAccountManager;
}

export function checkIsLogist(fullUser: IFullUser, typeEnum?: UserType): boolean {
    if (typeEnum) {
        return typeEnum === UserType.SkdStaffLogistic;
    }
    return fullUser?.user?.typeEnum === UserType.SkdStaffLogistic;
}

export function checkIsAdmin(fullUser: IFullUser, typeEnum?: UserType): boolean {
    if (typeEnum) {
        return typeEnum === UserType.Admin;
    }
    return fullUser?.user?.typeEnum === UserType.Admin;
}

export function checkIsSkdStaffRecruiter(fullUser: IFullUser, typeEnum?: UserType): boolean {
    if (typeEnum) {
        return typeEnum === UserType.SkdStaffRecruiter;
    }
    return fullUser?.user?.typeEnum === UserType.SkdStaffRecruiter;
}

export function checkIsSkdStaffSalesManager(fullUser: IFullUser, typeEnum?: UserType): boolean {
    if (typeEnum) {
        return typeEnum === UserType.SkdStaffSalesManager;
    }
    return fullUser?.user?.typeEnum === UserType.SkdStaffSalesManager;
}

export function checkIsSkdStaffLogistic(fullUser: IFullUser, typeEnum?: UserType): boolean {
    if (typeEnum) {
        return typeEnum === UserType.SkdStaffLogistic;
    }
    return fullUser?.user?.typeEnum === UserType.SkdStaffLogistic;
}

export function checkIsSkdStaffLawyer(fullUser: IFullUser, typeEnum?: UserType): boolean {
    if (typeEnum) {
        return typeEnum === UserType.SkdStaffLawyer;
    }
    return fullUser?.user?.typeEnum === UserType.SkdStaffLawyer;
}

export function checkIsSkdStaffOperator(fullUser: IFullUser, typeEnum?: UserType): boolean {
    if (typeEnum) {
        return typeEnum === UserType.SkdStaffOperator;
    }
    return fullUser?.user?.typeEnum === UserType.SkdStaffOperator;
}

export function checkIsWorkerSkdOrHigher(fullUser: IFullUser, typeEnum?: UserType) {
    return checkIsWorkerSkd(fullUser, typeEnum) || checkIsAdmin(fullUser, typeEnum);
}
// <=== access rights

// ===> parse entity name
function normalizeMainName(name: string) {
    if (!name) {
        return '';
    }
    return `${name} `;
}

function normalizeAuxiliaryName(name: string) {
    if (!name) {
        return '';
    }
    return `${name.slice(0, 1)}.`;
}

interface IParseUserNameOptions {
    fallbackValue: string;
    isAccounting: boolean;
    shouldNotUseBrand: boolean;
}

export function parseUserName(fullUser: IFullUser, options: Partial<IParseUserNameOptions> = {}): string {
    const {
        fallbackValue = '',
        isAccounting = false,
        shouldNotUseBrand = false,
    } = options;
    try {
        const {
            id,
            paperworkTypeEnum,
        } = fullUser.user;

        if (checkIsAdmin(fullUser)) {
            return `${fullUser.admin.name}(${id})`;
        }

        if (checkIsWorkerSkd(fullUser)) {
            const {
                surname,
                name,
            } = fullUser.skdStaff;

            return `${normalizeMainName(surname)}${normalizeAuxiliaryName(name)}(${id})`;
        }

        if (checkIsCustomer(fullUser) && !isAccounting && !shouldNotUseBrand) {
            if (fullUser.customer?.brandName) {
                return `${fullUser.customer.brandName} (${id})`;
            }
        }

        let paperworkName: string;
        switch (paperworkTypeEnum) {
            case UserPaperworkType.Individual: {
                const {
                    name,
                    surname,
                    patronymic,
                } = fullUser.individual;
                paperworkName = `${normalizeMainName(surname)}${normalizeAuxiliaryName(name)}${normalizeAuxiliaryName(patronymic)}`;
                break;
            }

            case UserPaperworkType.SelfEmployed: {
                const {
                    name,
                    surname,
                    patronymic,
                } = fullUser.selfEmployed;
                paperworkName = `${normalizeMainName(surname)}${normalizeAuxiliaryName(name)}${normalizeAuxiliaryName(patronymic)}`;
                break;
            }

            case UserPaperworkType.IndividualEntrepreneur: {
                const {
                    name,
                    surname,
                    patronymic,
                } = fullUser.individualEntrepreneur;
                paperworkName = `${normalizeMainName(surname)}${normalizeAuxiliaryName(name)}${normalizeAuxiliaryName(patronymic)}`;
                break;
            }

            case UserPaperworkType.OOO: {
                const {
                    name,
                } = fullUser.OOO;
                paperworkName = `${name}`;
                break;
            }

            case UserPaperworkType.JuridicalPerson: {
                const {
                    name,
                } = fullUser.juridicalPerson;
                paperworkName = `${name}`;
                break;
            }
        }

        if (paperworkName) {
            if (fullUser.customer?.brandName && !shouldNotUseBrand) {
                return `${paperworkName} | ${fullUser.customer?.brandName} (${id})`;
            }
            return `${paperworkName}(${id})`;
        }

        return fallbackValue;
    } catch (ex) {
        return fallbackValue;
    }
}

export function parseOperationName(fullOperation: IFullOperation, fallbackValue: string = ''): string {
    try {
        const {
            operation: {
                id,
            },
            operationDetailed: {
                comment,
            },
        } = fullOperation;

        return String(id);
    } catch (ex) {
        return fallbackValue;
    }
}

export function parseOrderName(fullOrder: IFullOrder, fallbackValue: string = ''): string {
    try {
        const {
            order: {
                id,
                typeEnum,
                settlementDate,
            },
        } = fullOrder;

        return id;
    } catch (ex) {
        return fallbackValue;
    }
}

export function parseWarehouseName(fullWarehouse: IFullWarehouse, fallbackValue: string = ''): string {
    try {
        const {
            warehouse: {
                name,
            },
        } = fullWarehouse;

        return `${name}`;
    } catch (ex) {
        return fallbackValue;
    }
}

export function parseOrderCustomKindName(fullOrderCustomKind: IFullOrderCustomKind, fallbackValue: string = ''): string {
    try {
        const {
            orderCustomKind: {
                name,
            },
        } = fullOrderCustomKind;

        return name;
    } catch (ex) {
        return fallbackValue;
    }
}
// <=== parse entity name

// ===> parse orders fields
export function parseExecutorUserId(fullOrder: DeepPartial<IFullOrder>) {
    switch (fullOrder?.order?.typeEnum) {
        case OrderType.Delivery:
            return fullOrder?.delivery?.executorUserId;
        case OrderType.Pickup:
            return fullOrder?.pickup?.issuedByUserId
        case OrderType.Fulfillment:
            return fullOrder?.fulfillment?.issuedByUserId
    }
}

export function parseSenderName(fullOrder: DeepPartial<IFullOrder & FullOrder>) {
    switch (fullOrder?.order?.typeEnum) {
        case OrderType.Delivery:
            return fullOrder?.delivery?.senderName ?? fullOrder?.delivery?.takingAddressData?.senderOrReceiverName;
        case OrderType.Pickup:
            break;
        case OrderType.Fulfillment:
            break;
    }
}

export function parseSenderPhone(fullOrder: DeepPartial<IFullOrder & FullOrder>) {
    switch (fullOrder?.order?.typeEnum) {
        case OrderType.Delivery:
            return fullOrder?.delivery?.senderPhone ?? fullOrder?.delivery?.takingAddressData?.senderOrReceiverPhone;
        case OrderType.Pickup:
            break;
        case OrderType.Fulfillment:
            break;
    }
}

export function parseReceiverName(fullOrder: DeepPartial<IFullOrder & FullOrder>) {
    switch (fullOrder?.order?.typeEnum) {
        case OrderType.Delivery:
            return fullOrder?.delivery?.receiverName ?? fullOrder?.delivery?.deliveryAddressData?.senderOrReceiverName;
        case OrderType.Pickup:
            return fullOrder?.pickup?.clientName;
        case OrderType.Fulfillment:
            return fullOrder?.fulfillment?.receiverName;
    }
}

export function parseReceiverPhone(fullOrder: DeepPartial<IFullOrder & FullOrder>) {
    switch (fullOrder?.order?.typeEnum) {
        case OrderType.Delivery:
            return fullOrder?.delivery?.receiverPhone ?? fullOrder?.delivery?.deliveryAddressData?.senderOrReceiverPhone;
        case OrderType.Pickup:
            return fullOrder?.pickup?.clientPhone;
        case OrderType.Fulfillment:
            return fullOrder?.fulfillment?.receiverPhone;
    }
}

export function parseDeliveryAddress(fullOrder: DeepPartial<IFullOrder & FullOrder>) {
    switch (fullOrder?.order?.typeEnum) {
        case OrderType.Delivery:
            return fullOrder?.delivery?.deliveryAddress ?? fullOrder?.delivery?.deliveryAddressData?.address;
        case OrderType.Pickup:
            return;
        case OrderType.Fulfillment:
            return fullOrder?.fulfillment?.deliveryAddress;
    }
}

export function parseDeliveryAddressComment(fullOrder: DeepPartial<IFullOrder & FullOrder>) {
    switch (fullOrder?.order?.typeEnum) {
        case OrderType.Delivery:
            return fullOrder?.delivery?.deliveryAddressComment ?? fullOrder?.delivery?.deliveryAddressData?.addressComment;
        case OrderType.Pickup:
            return;
        case OrderType.Fulfillment:
            return;
    }
}

export function parseTakingWarehouse(fullOrder: DeepPartial<IFullOrder>) {
    switch (fullOrder?.order?.typeEnum) {
        case OrderType.Delivery:
            return fullOrder?.delivery?.takingWarehouseId;
        case OrderType.Pickup:
            return fullOrder?.pickup?.warehouseId;
        case OrderType.Fulfillment:
            return fullOrder?.fulfillment?.warehouseId;
    }
}

export function parseDeliveryWarehouse(fullOrder: DeepPartial<IFullOrder>) {
    switch (fullOrder?.order?.typeEnum) {
        case OrderType.Delivery:
            return fullOrder?.delivery?.deliveryWarehouseId;
        case OrderType.Pickup:
            return;
        case OrderType.Fulfillment:
            return;
    }
}

export function checkIsOrderOnVerification(fullOrder: DeepPartial<IFullOrder>) {
    return !fullOrder?.order?.isClose && (
        checkIsOrderStatusCancelled(fullOrder)
        || checkIsOrderStatusRefusal(fullOrder)
        || checkIsOrderStatusCouldNotReach(fullOrder)
    );
}

export function checkIsOrderStatusCancelled(fullOrder: DeepPartial<IFullOrder>) {
    const orderStatus = fullOrder?.order?.statusEnum
    if (!orderStatus) {
        return false;
    }
    return orderStatus.includes('cancel');
}

export function checkIsOrderStatusRefusal(fullOrder: DeepPartial<IFullOrder>) {
    const orderStatus = fullOrder?.order?.statusEnum
    if (!orderStatus) {
        return false;
    }
    return orderStatus.includes('refusal');
}

export function checkIsOrderStatusCouldNotReach(fullOrder: DeepPartial<IFullOrder>) {
    const orderStatus = fullOrder?.order?.statusEnum
    if (!orderStatus) {
        return false;
    }
    return orderStatus.includes('could_not_reach');
}
// <=== parse orders fields

// ===> accounts
export function checkIsAllowAnalyticAccounts(userType: UserType) {
    const isCurrentUserAccountManager = checkIsAccountManager(null, userType);
    const isCurrentUserAccountant = checkIsAccountant(null, userType);
    const isCurrentUserAdmin = checkIsAdmin(null, userType);
    return isCurrentUserAccountManager || isCurrentUserAccountant || isCurrentUserAdmin;
}
// <=== accounts

export function shortizeFromTo(from: string, to: string) {
    if (from && !to) {
        return new SkdMoment(from).toHumanString();
    }
    if (!from && to) {
        return new SkdMoment(to).toHumanString();
    }
    if (!from && !to) {
        return '';
    }

    return SkdMoment.momentRangeToHumanString(from, to);
}
