export enum WarehouseType {
	PVZ = 'pvz',
	Skd = 'skd',
	Customer = 'customer',
}

export const VALID_WAREHOUSE_TYPE_VALUES = Object.values(WarehouseType);
export const VALID_ORDER_DELIVERY_WAREHOUSE_TYPES_VALUES = [
	WarehouseType.Skd,
	WarehouseType.Customer,
];
export const VALID_ORDER_PICKUP_WAREHOUSE_TYPES_VALUES = [
	WarehouseType.PVZ,
	WarehouseType.Skd,
];
export const VALID_ORDER_FULFILLMENT_WAREHOUSE_TYPES_VALUES = [
	WarehouseType.Skd,
];

export enum Field {
	WarehouseId = 'warehouse.id',
	WarehouseTypeEnum = 'warehouse.typeEnum',
	WarehouseName = 'warehouse.name',
	WarehouseLocationEnum = 'warehouse.locationEnum',
	WarehouseAddress = 'warehouse.address',
	WarehouseSubway = 'warehouse.subway',
	WarehousePhone = 'warehouse.phone',
	WarehouseComment = 'warehouse.comment',
	WarehouseIsRemoved = 'warehouse.isRemoved',

	WarehousePvzWarehouseId = 'pvz.warehouseId',

	WarehouseSKDWarehouseId = 'skd.warehouseId',

	WarehouseCustomerWarehouseId = 'customer.warehouseId',
	WarehouseCustomerCustomerUserId = 'customer.customerUserId',
}

export const VALID_WAREHOUSE_FIELD_VALUES = Object.values(Field);
