import './NotFind.scss';

import {
    navigateToPrevUrl,
} from 'src/libs/history';

import React from 'react';

const b = require('b_').with('not-find');

export default function NotFind() {
    return (
        <div className={b()} onClick={navigateToPrevUrl} data-test="not-found-page">
            {'Красивая заглушка. Страница не найдена. Кликните чтобы вернуться.'}
        </div>
    );
}
