import './Toolbar.scss';
import locales from './Toolbar.i18n';

import {
    ToolbarEnv,
} from './Toolbar.types';

import React, {
    useEffect,
} from 'react';
import { observer } from 'mobx-react-lite';
import {
    Link,
    useNavigate,
} from 'react-router-dom';
import {
    isMobile,
} from 'react-device-detect';

import {
    useForceUpdate,
} from 'src/hooks/react';
import UsersStore from 'src/stores/Users';

import {
    FrontendUrls,
} from 'src/constants/url';

import SkdMoment from 'backend2/libs/skdMoment';

import {
    SKD_FRONTEND_VERSION,
    SKD_FRONTEND_BUILD_TIME,
} from 'src/libs/env';
import i18n from 'src/libs/i18n';
import history, {
    applyLocationQuery,
    pushUrl,
} from 'src/libs/history';
import {
    parseUserName,
    checkIsExecutor,
    checkIsWorkerSkd,
    checkIsAdmin,
    checkIsSkdStaffRecruiter,
    checkIsSkdStaffOperator,
    checkIsSkdStaffSalesManager,
    checkIsSkdStaffLogistic,
    checkIsSkdStaffLawyer,
} from 'src/libs/backend2/parser';

import Button from 'skd-ui/Button/Button';
import Dropdown, {
    DropdownProps,
} from 'skd-ui/Dropdown/Dropdown';

const b = require('b_').with('toolbar');
const __ = i18n(locales);

export {
    ToolbarEnv,
}

const BASE_DOCUMENT_TITLE = 'SKD-CRM-2' ;

const nowMoment = new Date();
const nowMonth = nowMoment.getMonth();
const nowDay = nowMoment.getDate();
let IS_HAPPY_NEW_YEAR = nowMonth === 11 || (nowMonth === 0 && nowDay < 22);

function _Toolbar() {
    const forceUpdate = useForceUpdate();
    const navigate = useNavigate();

    useEffect(() => {
        /*
        setInterval(() => {
            IS_HAPPY_NEW_YEAR = !IS_HAPPY_NEW_YEAR;
            forceUpdate();
        }, 500);
        */

        setDocumentTitle();
        const unregisterHistoryListenCallback = history.listen(() => {
            forceUpdate();
            setDocumentTitle();
        });

        return () => {
            document.title = BASE_DOCUMENT_TITLE;
            unregisterHistoryListenCallback();
        };
    }, []);

    const env = calculateEnv();

    const currentUser = UsersStore.viewer;
    const currentUserType = currentUser?.user?.typeEnum;
    const currentUserId = currentUser?.user?.id;
    const isAuthorized = Boolean(currentUser);
    const isUserCourier = checkIsExecutor(null, currentUserType);
    const isUserWorkerSkd = checkIsWorkerSkd(null,currentUserType);
    const isUserAdmin = checkIsAdmin(null,currentUserType);
    const isUserSkdStaffRecruiter = checkIsSkdStaffRecruiter(null, currentUserType);
    const isUserSkdStaffOperator = checkIsSkdStaffOperator(null, currentUserType);
    const isUserSkdStaffSalesManager = checkIsSkdStaffSalesManager(null, currentUserType);
    const isUserSkdStaffLogistic = checkIsSkdStaffLogistic(null, currentUserType);
    const isUserSkdStaffLawyer = checkIsSkdStaffLawyer(null, currentUserType);

    async function onLogOutClick() {
        applyLocationQuery({}, true);
        await UsersStore.logOut();
        try {
            await UsersStore.getCurrentUser();
        } catch (ex) {

        }
    }

    function onUsersClick() {
        navigate(FrontendUrls.Users);
        applyLocationQuery({}, true);
        pushUrl(FrontendUrls.Users);
    }

    function onOrdersClick() {
        navigate(FrontendUrls.Orders);
        applyLocationQuery({}, true);
        pushUrl(FrontendUrls.Orders);
    }

    function onAccountingClick() {
        navigate(FrontendUrls.Accounting);
        applyLocationQuery({}, true);
        pushUrl(FrontendUrls.Accounting);
    }

    function onHelpClick() {
        navigate(FrontendUrls.Help);
        applyLocationQuery({}, true);
        pushUrl(FrontendUrls.Help);
    }

    function onMapClick() {
        navigate(FrontendUrls.Map);
        applyLocationQuery({}, true);
        pushUrl(FrontendUrls.Map);
    }

    function onAPIClick() {
        navigate(FrontendUrls.Api);
        applyLocationQuery({}, true);
        pushUrl(FrontendUrls.Api);
    }

    function onLogInClick() {
        navigate(FrontendUrls.LogIn);
        applyLocationQuery({}, true);
        pushUrl(FrontendUrls.LogIn);
    }

    function onSignUpClick() {
        navigate(FrontendUrls.SignUp);
        applyLocationQuery({}, true);
        pushUrl(FrontendUrls.SignUp);
    }

    const buttonOrdersProps = {
        onClick: onOrdersClick,
        text: __(ToolbarEnv.Orders.toString()),
        mods: {
            active: env === ToolbarEnv.Orders,
        },
        dataTest: 'toolbar-orders',
    };
    const buttonAccountingProps = {
        onClick: onAccountingClick,
        text: __(ToolbarEnv.Accounting.toString()),
        mods: {
            active: env === ToolbarEnv.Accounting,
        },
        dataTest: 'toolbar-accounting',
    };
    const buttonUsersProps = {
        onClick: onUsersClick,
        text: __(ToolbarEnv.Users.toString()),
        mods: {
            active: env === ToolbarEnv.Users,
        },
        dataTest: 'toolbar-users',
    };
    const buttonHelpProps = {
        onClick: onHelpClick,
        text: __(ToolbarEnv.Help.toString()),
        mods: {
            active: env === ToolbarEnv.Help,
        },
        dataTest: 'toolbar-help',
    };
    const buttonMapProps = {
        onClick: onMapClick,
        text: __(ToolbarEnv.Map.toString()),
        mods: {
            active: env === ToolbarEnv.Map,
        },
        dataTest: 'toolbar-map',
    };
    const buttonAPIProps = {
        onClick: onAPIClick,
        text: __(ToolbarEnv.API.toString()),
        mods: {
            active: env === ToolbarEnv.API,
            disabled: true,
        },
        dataTest: 'toolbar-api',
    };

    const buttonLogInProps = {
        onClick: onLogInClick,
        text: 'Авторизоваться',
        mods: {
            active: env === ToolbarEnv.LogIn,
        },
        dataTest: 'toolbar-login',
    };
    const buttonSignUpProps = {
        onClick: onSignUpClick,
        text: 'Зарегистрироваться',
        mods: {
            active: env === ToolbarEnv.SignUp,
        },
        dataTest: 'toolbar-sign-up',
    };
    const buttonLogOutProps = {
        onClick: onLogOutClick,
        text: 'Выйти',
        dataTest: 'toolbar-log-out',
    };

    const mobileDropdownOptions: DropdownProps = {
        value: env,
        onChange: section => {
            if (section === env) {
                return;
            }

            switch (section as ToolbarEnv) {
                case ToolbarEnv.Orders:
                    onOrdersClick();
                    break;
                case ToolbarEnv.Accounting:
                    onAccountingClick();
                    break;
                case ToolbarEnv.Users:
                    onUsersClick();
                    break;
                case ToolbarEnv.Help:
                    onHelpClick();
                    break;
                case ToolbarEnv.LogOut:
                    onLogOutClick();
                    break;
            }

            window.document.location.reload();
        },
        options: [
            {
                value: ToolbarEnv.Home,
                name: __(ToolbarEnv.Home.toString()),
            },
            {
                value: ToolbarEnv.Orders,
                name: __(ToolbarEnv.Orders.toString()),
            },
            {
                value: ToolbarEnv.Accounting,
                name: __(ToolbarEnv.Accounting.toString()),
            },
            {
                value: ToolbarEnv.Users,
                name: __(ToolbarEnv.Users.toString()),
            },
            {
                value: ToolbarEnv.Help,
                name: __(ToolbarEnv.Help.toString()),
            },
            {
                value: ToolbarEnv.LogOut,
                name: __(ToolbarEnv.LogOut.toString()),
            },
        ],
    };

    const currentSectionName = env !== ToolbarEnv.Home ? __(env.toString()) : '';

    return (
        <div className={b({'is-mobile': isMobile})}>
            <div className={b('project-name')}>
                    <span className={b('version-container')}>
                        <Link to={FrontendUrls.Home}>
                           <div className={b('icons', {
                               ...(IS_HAPPY_NEW_YEAR && {'skd-crm-ny': true}),
                               ...(!IS_HAPPY_NEW_YEAR && {'skd-crm': true}),
                           })}/>
                        </Link>
                        <VersionMeta/>
                    </span>
                {Boolean(currentSectionName) && currentSectionName}
            </div>
            <div className={b('actions')}>
                {isAuthorized && (
                    <>
                        {isMobile && (
                            <span className={b('action')}>
                                <Dropdown {...mobileDropdownOptions} />
                            </span>
                        )}
                        {!isMobile && (
                            <>
                                 <span className={b('action')}>
                                    <Button {...buttonOrdersProps}/>
                                </span>
                                {(!(isUserSkdStaffRecruiter || isUserSkdStaffSalesManager || isUserSkdStaffLogistic || isUserSkdStaffLawyer)) && (
                                    <span className={b('action')}>
                                        <Button {...buttonAccountingProps}/>
                                    </span>
                                )}
                                {(isUserWorkerSkd || isUserAdmin) && (
                                    <span className={b('action')}>
                                         <Button {...buttonUsersProps}/>
                                    </span>
                                )}
                                <span className={b('action')}>
                                    <Button {...buttonHelpProps}/>
                                </span>
                                {(isUserAdmin || isUserSkdStaffRecruiter || isUserSkdStaffOperator) && (
                                    <span className={b('action')}>
                                        <Button {...buttonMapProps}/>
                                    </span>
                                )}
                                <span className={b('action')}>
                                    <Button {...buttonLogOutProps}/>
                                </span>
                            </>
                        )}
                        <span className={b('user-name', {'is-mobile': isMobile})}>
                            <Link to={FrontendUrls.User.replace(':userId', String(currentUserId))} style={{color: '#000'}} data-test="toolbar-viewer">
                                {isMobile ? `id: ${String(currentUserId)}` : `${parseUserName(currentUser)}`}
                            </Link>
                        </span>
                    </>
                )}
                {!isAuthorized && (
                    <span className={b('action')}>
                         <Button {...buttonLogInProps}/>
                    </span>
                )}

            </div>
        </div>
    ) ;
}
const Toolbar = observer(_Toolbar);

export default Toolbar;
function VersionMeta() {
    try {
        return (
            <span className={b('version')} title={new SkdMoment(SKD_FRONTEND_BUILD_TIME, true).toHumanString()}>
                {SKD_FRONTEND_VERSION}
            </span>
        );
    } catch (ex) {
        console.error(ex);
        return null;
    }
}

function calculateEnv(): ToolbarEnv {
    const {
        pathname,
    } = history.location;

    switch (true) {
        case pathname.startsWith(FrontendUrls.LogIn):
            return ToolbarEnv.LogIn;
        case pathname.startsWith(FrontendUrls.SignUp):
            return ToolbarEnv.SignUp;
        case pathname.startsWith(FrontendUrls.Orders):
            return ToolbarEnv.Orders;
        case pathname.startsWith(FrontendUrls.Users):
            return ToolbarEnv.Users;
        case pathname.startsWith(FrontendUrls.Accounting):
            return ToolbarEnv.Accounting;
        case pathname.startsWith(FrontendUrls.Help):
            return ToolbarEnv.Help;
        case pathname.startsWith(FrontendUrls.Map):
            return ToolbarEnv.Map;
        default:
            return ToolbarEnv.Home;
    }
}

function setDocumentTitle() {
    const env = calculateEnv();
    const currentSectionName = __(env.toString());
    document.title = `${BASE_DOCUMENT_TITLE}${Boolean(currentSectionName) ? ` ${currentSectionName}` : ''}`;
}

// latitude, longitude
// [[55.678143, 37.686079],[55.680703, 37.690919],[55.680718, 37.690885],[55.680397, 37.69307],[55.680696, 37.69253],[55.680696, 37.69253],[55.68068, 37.692564],[55.680412, 37.692533],[55.6803, 37.692714],[55.680615, 37.691918],[55.680761, 37.691221],[55.679076, 37.689305],[55.692994, 37.717402],[55.68958, 37.709753],[55.707725, 37.740762],[55.725236, 37.714695],[55.681633, 37.690078],[55.681633, 37.690078],[55.680432, 37.692401],[55.680786, 37.692384]]
