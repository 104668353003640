import {
    createBrowserHistory,
} from 'history';
import historyWithQuery from 'qhistory';
import {
    parse,
    stringify,
} from 'querystring';

import qs from 'qs';
import isObject from 'lodash-es/isObject';

import {
    FrontendUrls,
} from 'src/constants/url';
import {
    merge,
} from 'src/libs/utils';
import {
    zipQuery,
    unzipQuery,
} from 'src/libs/zip';

export function createHistory() {
    return historyWithQuery(
        createBrowserHistory(),
        stringify,
        parse,
    );
}

import {
    history,
} from 'src/reduxStore/reducers';

export function pushUrl(url: string) {
    if (getLocation().pathname !== url) {
        history.push(url);
    }
}

export function replaceUrl(url: string) {
    if (getLocation().pathname !== url) {
        history.replace(url);
    }
}

export function pushPathname(url: string) {
    if (getLocation().pathname !== url) {
        history.push({
            pathname: url,
        });
    }
}

export function navigateToPrevUrl() {
    history.back();
}

export function navigateToNextUrl() {
    history.forward();
}

export function getLocation() {
    return history.location;
}

export function navigateToLogIn() {
    return pushUrl(FrontendUrls.LogIn);
}

export function parseLocationQuery() {
    const locationQuery = qs.parse(history.location.search, {
        delimiter: /\?|&/,
    });
    const unzippedLocationQuery = unzipQuery(locationQuery);

    // isApproved: 'true'
    function castBoolean(obj: Record<string, any>) {
        for (const key of Object.keys(obj)) {
            const value = obj[key];
            if (isObject(value)) {
                castBoolean(value);
                continue;
            }
            if (typeof value === 'string' && /^is[A-Z]/.test(key)) {
                if (value === 'true') {
                    obj[key] = true;
                } else if (value === 'false') {
                    obj[key] = false;
                }
            }
        }
    }

    function castNumber(obj: Record<string, any>) {
        for (const key of Object.keys(obj)) {
            const value = obj[key];
            if (isObject(value)) {
                castNumber(value);
                continue;
            }
            if (typeof value === 'string' && /^\d+$/.test(value)) {
                const numberedValue = parseInt(value);
                if (String(numberedValue) === value) {
                    obj[key] = numberedValue;
                }
            }
        }
    }

    castBoolean(unzippedLocationQuery);
    castNumber(unzippedLocationQuery);

    return unzippedLocationQuery;
}

export function applyLocationQuery(queryPatch: Record<string, any>, replace = false) {
    const search = constructSearch(queryPatch, replace);

    history.replace({
        search,
    });
}

export function constructSearch(queryPatch: Record<string, any>, replace = false, onlyPatch = false) {
    const prevQuery = parseLocationQuery();
    const nextQuery = onlyPatch ? queryPatch : merge(prevQuery, queryPatch);
    if (!onlyPatch && replace) {
        Object.assign(nextQuery, queryPatch);
    }

    const zippedQuery = zipQuery(nextQuery);
    return qs.stringify(zippedQuery, {
        skipNulls: true,
    });
}

export default history;
