import {
    Action,
} from 'redux';

import {
    IFullOperation,
    IOperationsGroupingDate,
    IOperationsGroupingType,
} from 'backend2/interfaces/operations';
import {
    IScheme,
} from 'backend2/interfaces/accounts';
import {
    SyntheticBalanceSheet,
    AnalyticBalanceSheet,
} from 'backend2/types/accounts';
import {
    IFullHistory,
} from 'backend2/interfaces/history';

import {
    applyPatch,
} from 'src/libs/structure';
import {
    constructImmutabilityQueryAddArrayOfObjects,
    constructImmutabilityQueryUpdateArrayOfObjects,
} from 'src/libs/utils';

export interface IData {
    scheme: IScheme;

    syntheticAccountsBalanceSheet: SyntheticBalanceSheet;

    analyticAccountsBalanceSheet: AnalyticBalanceSheet;
    hotAnalyticAccountsBalanceSheet: AnalyticBalanceSheet;

    operations: IFullOperation[];
    hotOperations: IFullOperation[];
    operationsHistory: IFullHistory[];
    hotOperationsHistory: IFullHistory[];

    operationsGroupingDate: IOperationsGroupingDate[];
    operationsGroupingType: IOperationsGroupingType[];
}

export type IAccountingReducer = {
    _data: IData;
};

export enum AccountingActionType {
    SetScheme = '2_accounting_set_scheme',
    SetSyntheticAccountsBalanceSheet = '2_accounting_set_synthetic_accounts_balance_sheet',
    MergeAnalyticAccountsBalanceSheet = '2_accounting_merge_analytic_accounts_balance_sheet',
    SetHotAnalyticAccountsBalanceSheet = '2_accounting_set_hot_analytic_accounts_balance_sheet',
    ResetHotAnalyticAccountsBalanceSheet = '2_accounting_reset_hot_analytic_accounts_balance_sheet',

    MergeOperations = '2_accounting_merge_operations',
    SetHotOperations = '2_accounting_set_hot_operations',
    RemoveOperation = '2_accounting_remove_operation',
    AddOperationsHistory = '2_add_operations_history',
    SetHotOperationsHistory = '2_set_hot_operations_history',

    SetOperationsGroupingDate = '2_set_operations_grouping_date',
    SetOperationsGroupingType = '2_set_operations_grouping_type',

    Clear = '2_accounting_clear',
}

export interface IAccountingAction extends Action<AccountingActionType> {
    payload?: {
        scheme?: IScheme;
        syntheticAccountsBalanceSheet?: SyntheticBalanceSheet;
        analyticAccountsBalanceSheet?: AnalyticBalanceSheet;
        operations?: IFullOperation[];
        operationsHistory?: IFullHistory[];
        operationId?: bigint;
        operationsGroupingDate?: IOperationsGroupingDate[];
        operationsGroupingType?: IOperationsGroupingType[];
    };
}

const initialScheme: IScheme = {
    accounts: [],
    childrenAccountIdsByAccountId: {},
    deepChildrenAccountIdsByAccountId: {},
    readAccessLeafAccountIds: [],
    accountsUserTypeCorrespondence: {},
    operations: [],
    manualOperationAccountsCorrespondenceMap: {},
    accountsGroups: [],
    frontendOperationsGroups: [],
};
const initialSyntheticAccountsBalanceSheet = {};
const initialAnalyticAccountsBalanceSheet = [];
const initialOperations = [];
const initialOperationsHistory = [];
const initialOperationsGroupingDate = [];
const initialOperationsGroupingType = [];

const initialState: IAccountingReducer = {
    _data: {
        scheme: initialScheme,

        syntheticAccountsBalanceSheet: initialSyntheticAccountsBalanceSheet,

        analyticAccountsBalanceSheet: initialAnalyticAccountsBalanceSheet,
        hotAnalyticAccountsBalanceSheet: initialAnalyticAccountsBalanceSheet,

        operations: initialOperations,
        hotOperations: initialOperations,
        operationsHistory: initialOperationsHistory,
        hotOperationsHistory: initialOperationsHistory,

        operationsGroupingDate: initialOperationsGroupingDate,
        operationsGroupingType: initialOperationsGroupingType,
    } as IData,
};

function getOperationIndexInStore(fullOperation: IFullOperation, state: IAccountingReducer) {
    const operationId = fullOperation.operation.id;
    return state._data.operations.findIndex((fullOperation: IFullOperation) => fullOperation.operation.id === operationId);
}

function getHotOperationIndexInStore(fullOperation: IFullOperation, state: IAccountingReducer) {
    const operationId = fullOperation.operation.id;
    return state._data.hotOperations.findIndex((fullOperation: IFullOperation) => fullOperation.operation.id === operationId);
}

function getOperationHistoryIndexInStore(fullHistory: IFullHistory, state: IAccountingReducer) {
    const historyId = `${fullHistory.actionId}__${fullHistory.historyFieldId}`;
    return state._data.operationsHistory.findIndex((fullHistory: IFullHistory) => `${fullHistory.actionId}__${fullHistory.historyFieldId}` === historyId);
}

export default function(state = initialState, {payload = {}, type}: IAccountingAction): IAccountingReducer {
    if (!type) {
        console.error('ignore action because type not specified.');
        return state;
    }

    let patch: any;

    switch (type) {
        case AccountingActionType.SetScheme:
            patch = {
                scheme: {
                    $set: payload.scheme,
                },
            };
            break;

        case AccountingActionType.SetSyntheticAccountsBalanceSheet:
            patch = {
                syntheticAccountsBalanceSheet: {
                    $set: payload.syntheticAccountsBalanceSheet,
                },
            };
            break;

        case AccountingActionType.MergeAnalyticAccountsBalanceSheet:
            patch = {
                analyticAccountsBalanceSheet: {
                    $set: {
                        ...state._data.analyticAccountsBalanceSheet,
                        ...payload.analyticAccountsBalanceSheet,
                    },
                },
            };
            break;

        case AccountingActionType.SetHotAnalyticAccountsBalanceSheet:
            patch = {
                hotAnalyticAccountsBalanceSheet: {
                    $set: payload.analyticAccountsBalanceSheet,
                },
            };
            break;

        case AccountingActionType.ResetHotAnalyticAccountsBalanceSheet:
            patch = {
                hotAnalyticAccountsBalanceSheet: {
                    $set: initialAnalyticAccountsBalanceSheet,
                },
            };
            break;

        case AccountingActionType.MergeOperations:
            patch = {
                operations: constructImmutabilityQueryAddArrayOfObjects<IFullOperation>(payload.operations, getOperationIndexInStore, state),
                hotOperations: constructImmutabilityQueryUpdateArrayOfObjects<IFullOperation>(payload.operations, getHotOperationIndexInStore, state),
            };
            break;

        case AccountingActionType.SetHotOperations:
            patch = {
                hotOperations: {
                    $set: payload.operations,
                },
            };
            break;

        case AccountingActionType.AddOperationsHistory:
            patch = {
                operationsHistory: constructImmutabilityQueryAddArrayOfObjects<IFullHistory>(payload.operationsHistory, getOperationHistoryIndexInStore, state),
            };
            break;

        case AccountingActionType.SetHotOperationsHistory:
            patch = {
                hotOperationsHistory: {
                    $set: payload.operationsHistory,
                },
            };
            break;

        case AccountingActionType.RemoveOperation:
            patch = {
                operations: {
                    $set: state._data.operations.filter(fullOperation => fullOperation.operation.id !== payload.operationId),
                },
                hotOperations: {
                    $set: state._data.hotOperations.filter(fullOperation => fullOperation.operation.id !== payload.operationId),
                },
            };

        case AccountingActionType.SetOperationsGroupingDate:
            patch = {
                operationsGroupingDate: {
                    $set: payload.operationsGroupingDate,
                },
            };
            break;

        case AccountingActionType.SetOperationsGroupingType:
            patch = {
                operationsGroupingType: {
                    $set: payload.operationsGroupingType,
                },
            };
            break;

        case AccountingActionType.Clear:
            patch = {
                scheme: {
                    $set: initialScheme,
                },

                syntheticAccountsBalanceSheet: {
                    $set: initialSyntheticAccountsBalanceSheet,
                },
                analyticAccountsBalanceSheet: {
                    $set: initialAnalyticAccountsBalanceSheet,
                },
                hotAnalyticAccountsBalanceSheet: {
                    $set: initialAnalyticAccountsBalanceSheet,
                },

                operations: {
                    $set: initialOperations,
                },
                hotOperations: {
                    $set: initialOperations,
                },
                operationsHistory: {
                    $set: initialOperationsHistory,
                },
                hotOperationsHistory: {
                    $set: initialOperationsHistory,
                },

                operationsGroupingDate: {
                    $set: initialOperationsGroupingDate,
                },
                operationsGroupingType: {
                    $set: initialOperationsGroupingType,
                },
            };
            break;
    }

    if (!patch) {
        return state;
    }

    return applyPatch(state, {
        _data: patch,
    }, type);
}
