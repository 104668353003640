import {
    IAlert,
} from 'src/types/alerts';

import {
    makeObservable,
    observable,
    action,
} from 'mobx';

import gererateUniqId from 'uniqid';

class ObservableAlertsStore {
    _alerts: IAlert[] = [];

    constructor() {
        makeObservable(this, {
            _alerts: observable,

            addAlert: action,
            removeAlert: action,
        });
    }

    addAlert(alert: IAlert) {
        const id = gererateUniqId();
        this._alerts = this._alerts.concat(alert);
        return id;
    }

    removeAlert(id: string) {
        this._alerts = this._alerts.filter(alert => alert.id !== id)
    }

    get alerts() {
        return this._alerts;
    }
}

const AlertsStore = new ObservableAlertsStore();
export default AlertsStore;
