import {
    ICount,
} from 'backend2/interfaces/common';
import {
    IFullWarehouse,
} from 'backend2/interfaces/warehouses';
import uniq from 'lodash-es/uniq';

import {
    makeAutoObservable,
    runInAction,
} from 'mobx';

import {
    getWarehouses,
} from 'src/dataProviders/orders';

class ObservableWarehousesStore {
    warehouses: Record<string, IFullWarehouse> = {};

    constructor() {
        makeAutoObservable(this, null, {autoBind: true});
    }

    get allWarehouses() {
        return Object.values(this.warehouses);
    }

    getWarehouse(warehouseId: number) {
        return this.warehouses[warehouseId];
    }

    async fetchWarehouses(params: Parameters<typeof getWarehouses>[0]) {
        if (params.ids) {
            params.ids = uniq(params.ids);
            if (!params.ids) {
                return [];
            }
        }
        const fullWarehouses = await getWarehouses(params);
        runInAction(() => {
            for (const fullWarehouse of fullWarehouses) {
                this.warehouses[fullWarehouse.warehouse.id] = fullWarehouse;
            }
        });
        return fullWarehouses;
    }

    async fetchWarehouse(warehouseId: number) {
        const warehouses = this.fetchWarehouses({
            ids: [warehouseId],
        });
        return warehouses[0];
    }

    async fetchWarehousesCount(params: Parameters<typeof getWarehouses>[0]) {
        const warehousesCount = await getWarehouses({
            ...params,
            isCountMode: true,
        });
        // @ts-ignore
        warehousesCount.count = BigInt(warehousesCount.count);
        return warehousesCount as unknown as ICount;
    }
}

const WarehousesStore = new ObservableWarehousesStore();
export default WarehousesStore;
