import isObject from 'lodash-es/isObject';

const ALPHABET = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

const CONFIG_SHOULD_ZIP = true;

export function zipQuery(obj: Record<string, any>) {
    if (!CONFIG_SHOULD_ZIP) {
        return obj;
    }

    let alphabetPointer = 0;
    const zip: Record<string, any> = {};
    for (const [key, value] of Object.entries(obj)) {
        if (alphabetPointer > ALPHABET.length - 1) {
            break;
        }
        if (isObject(value)) {
            const zippedName = ALPHABET[alphabetPointer++];
            zip[zippedName] = key;
            obj[zippedName] = value;
            delete obj[key];
        }
    }

    return {
        zip,
        ...obj,
    };
}

export function unzipQuery(obj: Record<string, any>) {
    if (!CONFIG_SHOULD_ZIP) {
        return obj;
    }

    const zip: Record<string, string> = obj['zip'];
    if (!zip) {
        return obj;
    }

    for (const [zippedKey, originalKey] of Object.entries(zip)) {
        obj[originalKey] = obj[zippedKey];
        delete obj[zippedKey];
    }
    delete obj['zip'];

    return obj;
}
