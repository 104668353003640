import 'skd-ui/Loading/Loading.scss';

import React from 'react';

const b = require('b_').with('loading');

export default function Loading() {
    return (
        <div className={b()}>
            <div/>
            <div/>
            <div/>
            <div/>
        </div>
    );
}
