export enum Duration {
    Year = 'year',
    Month = 'month',
    Week = 'week',
    Day = 'day',
    Hour = 'hour',
    Minute = 'minute',
    Second = 'second',
}

export enum Period {
    CurrentWeek = 'currentWeek',
    PrevWeek = 'prevWeek',
    CurrentMonth = 'currentMonth',
    PrevMonth = 'prevMonth',
}

const clientTimezoneOffset = new Date().getTimezoneOffset();

/**
 * moment = date + time
 * Существует 3 часовых пояса.
 * 1) Часовой пояс БД(UTC)
 * 2) Часовой пояс сервера где запущен бекенд(UTC)
 * 3) Часовой пояс клиент(любой)
 * При работе с объектами Date и работе с БД проблем нет.
 * Проблемы начинаются при переводе момента в строковой формат.
 * Нужно всегда в БД писать объектом Date, тогда проблем не будет, база распарсит.
 * Но когда мы пишем date(yyyy-MM-dd) вот тут уже никто ничего не распарсит.
 * Нужно следить за тем, чтобы те редкие места, где бекенд не пользовательские данные пишет в БД(после валидации), а вычисляемые самостоятельно писал в UTC.
 * Поэтому для всех переводов в строки, необходимо явно указывать с каким смещением.
 */
export class SkdMoment {
    public static MINUTE = 1000 * 60;
    public static HOUR = 60 * SkdMoment.MINUTE;
    public static DAY = 24 * SkdMoment.HOUR;
    // unix timestamp 31525199 (in seconds) is Dec 31 1970 20:59:59 GMT+0000
    public static LAST_1970_TIMESTAMP = 31525199 * 1000;
    public static MAX_DATES_ITERATIONS_COUNT = 400;
    private jsDate: Date = undefined;
    public timezoneOffset = 0;

	/**
	 * if something more than a Date call will done - log it
	 */
	constructor(value?: unknown, notSetCurrent = false, shouldNormalize = false) {
        if (!value && notSetCurrent) {
			return;
		}
		if (typeof value === 'undefined') {
			this.jsDate = new Date();
			return;
		}
		if (value instanceof SkdMoment) {
			this.jsDate = value.getDate();
			this.timezoneOffset = value.timezoneOffset;
			return;
		}
		if (value instanceof Date) {
			this.jsDate = value;
			return;
		}
        if (typeof value === 'number' || typeof value === 'bigint') {
            let normalizedValue = Number(value);
            if (shouldNormalize) {
                // support unix timestamp
                if (normalizedValue < SkdMoment.LAST_1970_TIMESTAMP) {
                    normalizedValue *= 1000;
                }
            } else {
                if (normalizedValue < SkdMoment.LAST_1970_TIMESTAMP) {
                    return;
                }
            }
            const valueDated = new Date(normalizedValue);
            if (SkdMoment.isValidJsDate(valueDated)) {
                this.jsDate = valueDated;
            }
            return;
        }
		if (typeof value === 'string' && value !== '') {
			const valueDated = new Date(value);
			if (SkdMoment.isValidJsDate(valueDated)) {
				// default behavior of Date when moment not exist: new Date('2021-02-31 18:00:00+00') => '2021-03-03 18:00:00+00'
				if (!shouldNormalize) {
					/**
					 * The work is carried out exactly with the date passed
					     and if it was converted, then the original date is not valid.
					 * But if we want to hook the date at any cost, that is, we are ready for user errors, then this is good
					 */
					const timezoneOffset = SkdMoment.pullOutTimezoneOffset(value);
					const date = new SkdMoment(valueDated).toDateString(timezoneOffset ?? 0);
					if (date !== value.slice(0, 10)) {
						return;
					}
				}
				this.jsDate = valueDated;
				this.timezoneOffset = SkdMoment.pullOutTimezoneOffset(value);
				return;
			}

			if (shouldNormalize) {
				const normalizedValue = SkdMoment.normalizeMoment(value);
				const normalizedValueDated = new Date(normalizedValue);
				if (SkdMoment.isValidJsDate(normalizedValueDated)) {
					this.jsDate = normalizedValueDated;
					return;
				}

				const {
					date: rawDate,
					time: rawTime,
				} = SkdMoment.splitMomentToDateTime(normalizedValue);
				const date = SkdMoment.parseRawDate(rawDate);
				const time = SkdMoment.parseRawTime(rawTime);
				const normalizedParsedValue = SkdMoment.constructCalendarDate(date, time);
				const normalizedParsedValueDated = new Date(normalizedParsedValue);
				if (SkdMoment.isValidJsDate(normalizedParsedValueDated)) {
					this.jsDate = normalizedParsedValueDated;
				}
				return;
			}
		}
	}

	/**
	 * X.Y.ZTHH:MM:SS:MS\n -> X-Y-Z HH:MM:SS:MS
	 * X/Y/Z HH:MM -> X-Y-Z HH:MM
	 * X\Y\Z HH:MM:SS -> X-Y-Z HH:MM:SS
	 * todo: change in backend 2.0
	 */
	private static normalizeMoment(raw: string) {
		return raw
			.trim()
			.replace(/T/g, ' ')
			.replace(/[./\\]/g, '-');
	}

	private static splitMomentToDateTime(normalizedCalendarDate: string) {
		const [
			date,
			time,
		] = normalizedCalendarDate.split(' ');

		return {
			date,
			time,
		};
	}

	/**
	 * yyyy-MM-dd / yyyy-dd-MM
	 * MM-yyyy-dd / MM-dd-yyyy
	 * dd-yyyy-dd / dd-MM-yyyy
	 * yyyy may be yy
	 * MM may be M
	 * dd may be d
	 * -> yyyy-MM-dd
	 * throws an exception in case of uncertainty
	 */
	private static parseRawDate(rawDate: string): string {
		const [
			originalA,
			originalB,
			originalC,
		]: number[] = rawDate.split('-').map(Number);

		const maxDay = 31;
		const maxMonth = 12;

		// any parameter can be a year (the year is not a four-digit number)
		if (originalA <= maxDay && originalB <= maxDay && originalC <= maxDay) {
			throw new Error(`uncertainty: any parameter can be a year (${[originalA, originalB, originalC].join(', ')})`);
		}

		// only the order of the first argument matters
		const checkParametersCombination = (a: number, b: number, c: number) => {
			let day: string, month: string, year: string;
			// first parameter year & the other two parameters are not a year & one of them month
			if (a > maxDay && b <= maxDay && c <= maxDay && (b <= maxMonth || c <= maxMonth)) {
				const currentYear = new Date().getFullYear();
				// a may not be four digits
				year = String(a).padStart(4, String(currentYear));

				// both parameters can be month
				if (b <= maxMonth && c <= maxMonth) {
					throw new Error(`uncertainty: both parameters can be month (${[b,c].join(', ')})`);
				}

				// only one parameter can be a day, which means another month
				if (b <= maxMonth) {
					day = String(c).padStart(2, '0');
					month = String(b).padStart(2, '0');
				}
				if (c <= maxMonth) {
					day = String(b).padStart(2, '0');
					month = String(c).padStart(2, '0');
				}

				return {
					year,
					month,
					day,
				};
			}
			return null;
		};

		const variant1 = checkParametersCombination(originalA, originalB, originalC);
		const variant2 = checkParametersCombination(originalB, originalA, originalC);
		const variant3 = checkParametersCombination(originalC, originalB, originalA);

		const successVariantsCount = [
			variant1,
			variant2,
			variant3,
		].filter(Boolean).length;

		if (successVariantsCount === 0) {
			throw new Error(`no combination of parameters worked (${[originalA, originalB, originalC].join(', ')})`);
		}

		const variants = [
			variant1,
			variant2,
			variant3,
		].filter(Boolean).map(({year, month, day}) => `${year}-${month}-${day}`);
		if (successVariantsCount > 1) {
			throw new Error(`uncertainty: several combinations are suitable (${variants.join(', ')})`);
		}

		return variants[0];
	}

	/**
	 * 16:00 -> 16:00:00
	 * 16 -> 16:00:00
	 * 9:14 -> 09:14:00
	 * null/undefined/'' -> 00:00:00
	 */
	// todo: parse 16.00, 16,00, 16;00
	private static parseRawTime(rawTime: string): string {
		if (!rawTime) {
			return '00:00:00';
		}
		const [
			hours = '00',
			minutes = '00',
			seconds = '00',
		] = rawTime.split(':');

		return [hours, minutes, seconds].map(value => value.padStart(2, '0')).join(':');
	}

	private static constructCalendarDate(date: string, time: string) {
		return `${date} ${time}`;
	}

	private addLeadingZero(value: number) {
		return String(value).padStart(2, '0');
	}

	public static isValidJsDate(date: Date) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return date instanceof Date && !isNaN(date) && isFinite(date);
	}

	// yyyy-MM-dd HH:mm:ss+00:00 or yyyy-MM-dd HH:mm:ss+00
	public static pullOutTimezoneOffset(value: string) {
		if (!value) {
			return null;
		}
		if (value.length < 22) {
			return null
		}
		// ...+00:00 the length of timezone is 6
		const plusIndex = value.indexOf('+', value.length - 6);
		const minusIndex = value.indexOf('-', value.length - 6);
		const validIndexes = [plusIndex, minusIndex].filter(index => index !== -1);
		if (validIndexes.length !== 1) {
			return null;
		}
		const index = validIndexes[0];

		const [
			hours,
			minutes = 0,
		]: number[] = value.slice(index + 1).split(':').map(Number);
		const sign = value.charAt(index) === '+' ? -1 : 1;
		const timezoneOffset = sign * (hours * 60 + minutes);
		// ternary operator to convert -0 to 0
		return timezoneOffset === 0 ? 0 : timezoneOffset;
	}

	public getDate() {
		if (!SkdMoment.isValidJsDate(this.jsDate)) {
			return null;
		}
		return this.jsDate;
	}

	// yyyy-MM-dd HH:mm:ss+00:00
	// timezoneOffset is the difference in minutes between UTC and the time on the client computer(+03 => -180)
	toString(timezoneOffset = clientTimezoneOffset) {
		if (!SkdMoment.isValidJsDate(this.jsDate)) {
			return '';
		}

		const dateWithTimezoneOffset = new Date(this.jsDate);
		dateWithTimezoneOffset.setTime(dateWithTimezoneOffset.getTime() - 60 * timezoneOffset * 1000);

		const year = dateWithTimezoneOffset.getUTCFullYear();
		const month = dateWithTimezoneOffset.getUTCMonth() + 1;
		const day = dateWithTimezoneOffset.getUTCDate();
		const hour = dateWithTimezoneOffset.getUTCHours();
		const minute = dateWithTimezoneOffset.getUTCMinutes();
		const second = dateWithTimezoneOffset.getUTCSeconds();

		const date = `${year}-${this.addLeadingZero(month)}-${this.addLeadingZero(day)}`;
		const time = `${this.addLeadingZero(hour)}:${this.addLeadingZero(minute)}:${this.addLeadingZero(second)}`;

		const timezoneSign = timezoneOffset * -1 >= 0 ? '+' : '-';
		const timezoneHours = Math.floor(Math.abs(timezoneOffset) / 60);
		const timezoneMinutes = Math.abs(timezoneOffset) - timezoneHours * 60;
		const timezone = `${timezoneSign}${this.addLeadingZero(timezoneHours)}:${this.addLeadingZero(timezoneMinutes)}`;

		return `${date} ${time}${timezone}`;
	}

	public static toString(date: Date, timezoneOffset = clientTimezoneOffset) {
		return new SkdMoment(date).toString(timezoneOffset);
	}

    // yyyy-MM-dd HH:mm:ss.fff
    public toStringWithoutTimeZone(timezoneOffset  = clientTimezoneOffset) {
        if (!SkdMoment.isValidJsDate(this.jsDate)) {
            return '';
        }

        const dateWithTimezoneOffset = new Date(this.jsDate);
        dateWithTimezoneOffset.setTime(dateWithTimezoneOffset.getTime() - 60 * timezoneOffset * 1000);

        const milliseconds = dateWithTimezoneOffset.getUTCMilliseconds();

        return `${this.toString(timezoneOffset).slice(0, 19)}.${String(milliseconds).padStart(3, '0')}`;
    }

	// yyyy-MM-dd
	public toDateString(timezoneOffset = clientTimezoneOffset) {
		if (!SkdMoment.isValidJsDate(this.jsDate)) {
			return '';
		}

		return this.toString(timezoneOffset).slice(0, 10);
	}

	// HH:mm
	public toShortTimeString(timezoneOffset = clientTimezoneOffset) {
		if (!SkdMoment.isValidJsDate(this.jsDate)) {
			return '';
		}

		return this.toString(timezoneOffset).slice(11, 16);
	}

	// yyyy-MM-dd HH:mm
	public toHumanString(timezoneOffset = clientTimezoneOffset) {
		if (!SkdMoment.isValidJsDate(this.jsDate)) {
			return '';
		}

		return `${this.toDateString(timezoneOffset)} ${this.toShortTimeString(timezoneOffset)}`;
	}

	public getMomentParams(timezoneOffset = clientTimezoneOffset) {
		if (!SkdMoment.isValidJsDate(this.jsDate)) {
			return {};
		}
		const momentString = this.toString(timezoneOffset);
		// yyyy-MM-dd HH:mm:ss+00:00
		return {
			year: Number(momentString.slice(0, 4)),
			month: Number(momentString.slice(5, 7)),
			day: Number(momentString.slice(8, 10)),
			hour: Number(momentString.slice(11, 13)),
			minute: Number(momentString.slice(14, 16)),
			second: Number(momentString.slice(17, 19)),
		};
	}

    public getTimestamp() {
        if (!SkdMoment.isValidJsDate(this.jsDate)) {
            return 0;
        }

        return this.getDate().valueOf();
    }

    public getUnixTimestamp() {
        if (!SkdMoment.isValidJsDate(this.jsDate)) {
            return 0;
        }

        return Math.round(this.getTimestamp() / 1000);
    }

    // native Date.getDay return 0 for Sunday, 1 for Monday, etc.
    // normalization return 0 for Monday, 1 for Tuesday, etc.
    private static normalizeDayOfWeek(nativeDay: number) {
        if (nativeDay === 0) {
            return 6;
        }
        return nativeDay - 1;
    }

    // native: 2022-05-31 + 1 => 2022-07-01, 2022-07-31 - 1 => 2022-07-01
    // normalization: 2022-05-31 + 1 => 2022-06-30, 2022-07-31 - 1 => 2022-06-30
    private static normalizedSetUTCMonth(date: Date, month: number) {
        const dateClone = new Date(date);

        date.setUTCMonth(month);
        const skdMoment = new SkdMoment(date);

        const expectedMonth = month < 0
            ? (month % 12) + 13
            : (month + 1) % 12;
        let {
            month: actualMonth,
        } = skdMoment.getMomentParams(0);

        if (expectedMonth !== actualMonth) {
            for (let i = 0; expectedMonth > actualMonth; i++) {
                if (i === 12) {
                    throw new Error(`prevent infinity cycle: '${dateClone.toJSON()}' to month ${month}`);
                }
                date.setUTCMonth(date.getUTCMonth() + 1);
                actualMonth = new SkdMoment(date).getMomentParams(0).month;
            }

            for (let i = 0; expectedMonth < actualMonth; i++) {
                if (i === 12) {
                    throw new Error(`prevent infinity cycle: '${dateClone.toJSON()}' to month ${month}`);
                }
                // last day of the previous month
                date.setUTCDate(0);
                actualMonth = new SkdMoment(date).getMomentParams(0).month;
            }
        }
    }

    public static calculateMomentDiff(moment1: SkdMoment | Date, moment2: SkdMoment | Date) {
        if (moment1 instanceof Date) {
            moment1 = new SkdMoment(moment1);
        }
        if (moment2 instanceof Date) {
            moment2 = new SkdMoment(moment2);
        }

        if (!SkdMoment.isValidJsDate(moment1.getDate())) {
            throw new Error('calculateMomentDiff: invalid moment1');
        }
        if (!SkdMoment.isValidJsDate(moment2.getDate())) {
            throw new Error('calculateMomentDiff: invalid moment2');
        }

        const momentValue = moment1.getTimestamp() - moment2.getTimestamp();
        const absMinutes = Math.abs(momentValue / SkdMoment.MINUTE);
        const absHours = Math.abs(momentValue / SkdMoment.HOUR);
        const absDay = Math.abs(momentValue / SkdMoment.DAY);

        const dateValue = new SkdMoment(moment1.toDateString(moment1.timezoneOffset)).getTimestamp() - new SkdMoment(moment2.toDateString(moment2.timezoneOffset)).getTimestamp();
        const dateAbsDay = Math.abs(dateValue / SkdMoment.DAY);

        return {
            momentValue,
            isMoment1LessThanMoment2: momentValue < 0,
            isMoment2LessThanMoment1: momentValue > 0,
            isEqual: momentValue === 0,
            floorAbsMinutes: Math.floor(absMinutes),
            floorAbsHours: Math.floor(absHours),
            floorAbsDay: Math.floor(absDay),
            ceilAbsMinutes: Math.ceil(absMinutes),
            ceilAbsHours: Math.ceil(absHours),
            ceilAbsDay: Math.ceil(absDay),

            dateValue,
            isDate1LessThanDate2: dateValue < 0,
            isDate2LessThanDate1: dateValue > 0,
            isDateEqual: dateValue === 0,
            floorDateAbsDay: Math.floor(dateAbsDay),
            ceilDateAbsDay: Math.ceil(dateAbsDay),
        };
    }

    public static checkIsMomentsEqual(moment1: string | Date, moment2: string | Date) {
        return new SkdMoment(moment1).toString(0) === new SkdMoment(moment2).toString(0);
    }

    public static changeMoment(moment: SkdMoment, momentOffset: number, duration: Duration): SkdMoment {
        const date = moment.getDate();
        if (!date) {
            return null;
        }

        const dateClone = new Date(date);
        if (momentOffset === 0) {
            return new SkdMoment(dateClone);
        }
        switch (duration) {
            case Duration.Year:
                dateClone.setUTCFullYear(dateClone.getUTCFullYear() + momentOffset);
                break;
            case Duration.Month:
                SkdMoment.normalizedSetUTCMonth(dateClone, dateClone.getUTCMonth() + momentOffset);
                break;
            case Duration.Week:
                dateClone.setUTCDate(dateClone.getUTCDate() + momentOffset * 7);
                break;
            case Duration.Day:
                dateClone.setUTCDate(dateClone.getUTCDate() + momentOffset);
                break;
            case Duration.Hour:
                dateClone.setUTCHours(dateClone.getUTCHours() + momentOffset);
                break;
            case Duration.Minute:
                dateClone.setUTCMinutes(dateClone.getUTCMinutes() + momentOffset);
                break;
            case Duration.Second:
                dateClone.setUTCSeconds(dateClone.getUTCSeconds() + momentOffset);
                break;
        }

        return new SkdMoment(dateClone);
    }

    public static momentToPeriod(moment: SkdMoment, period: Period): [SkdMoment, SkdMoment] {
        const date = moment.getDate();
        if (!date) {
            return null;
        }

        const startDate = new Date(date);
        const endDate = new Date(date);
        switch (period) {
            case Period.CurrentWeek:
            case Period.PrevWeek: {
                const nativeDayOfTheWeek = date.getUTCDay();
                const dayOfTheWeek = SkdMoment.normalizeDayOfWeek(nativeDayOfTheWeek);
                startDate.setDate(startDate.getUTCDate() - dayOfTheWeek);
                endDate.setDate(endDate.getUTCDate() + (6 - dayOfTheWeek));
                if (period === Period.PrevWeek) {
                    startDate.setUTCDate(startDate.getUTCDate() - 7);
                    endDate.setUTCDate(endDate.getUTCDate() - 7);
                }
                break;
            }

            case Period.CurrentMonth:
            case Period.PrevMonth: {
                startDate.setUTCDate(1);
                SkdMoment.normalizedSetUTCMonth(endDate, endDate.getUTCMonth() + 1);
                endDate.setUTCDate(0);
                if (period === Period.PrevMonth) {
                    SkdMoment.normalizedSetUTCMonth(startDate, startDate.getUTCMonth() - 1);
                    SkdMoment.normalizedSetUTCMonth(endDate, endDate.getUTCMonth() - 1);
                }
                break;
            }
        }

        return [
            SkdMoment.changeMomentToMinTime(new SkdMoment(startDate)),
            SkdMoment.changeMomentToMaxTime(new SkdMoment(endDate)),
        ];
    }

    // yyyy-MM-dd HH:mm — yyyy-MM-dd HH:mm
    // yyyy-MM-dd HH:mm — HH:mm
    public static momentRangeToHumanString(moment1: string | Date | SkdMoment, moment2: string | Date | SkdMoment, timezoneOffset: number = clientTimezoneOffset) {
        if (typeof moment1 === 'string') {
            moment1 = new SkdMoment(moment1);
        }
        if (typeof moment2 === 'string') {
            moment2 = new SkdMoment(moment2);
        }
        if (moment1 instanceof Date) {
            moment1 = new SkdMoment(moment1);
        }
        if (moment2 instanceof Date) {
            moment2 = new SkdMoment(moment2);
        }

        if (!SkdMoment.isValidJsDate(moment1.getDate())) {
            throw new Error('dateRangeToHumanString: invalid moment1');
        }
        if (!SkdMoment.isValidJsDate(moment2.getDate())) {
            throw new Error('dateRangeToHumanString: invalid moment2');
        }

        const date1String = moment1.toDateString(timezoneOffset);
        const date2String = moment2.toDateString(timezoneOffset);
        const shortTime1String = moment1.toShortTimeString(timezoneOffset);
        const shortTime2String = moment2.toShortTimeString(timezoneOffset);

        if (date1String === date2String) {
            return `${date1String} ${shortTime1String} — ${shortTime2String}`;
        }

        return `${date1String} ${shortTime1String} — ${date2String} ${shortTime2String}`;
    }

    public static changeMomentToMinTime(moment: SkdMoment) {
        const date = moment.getDate();
        if (!date) {
            return null;
        }

        const dateClone = new Date(date);
        dateClone.setUTCHours(0);
        dateClone.setUTCMinutes(0);
        dateClone.setUTCSeconds(0);
        dateClone.setUTCMilliseconds(0);

        return new SkdMoment(dateClone);
    }

    public static changeMomentToMaxTime(moment: SkdMoment) {
        const date = moment.getDate();
        if (!date) {
            return null;
        }

        const dateClone = new Date(date);
        dateClone.setUTCHours(23);
        dateClone.setUTCMinutes(59);
        dateClone.setUTCSeconds(59);
        dateClone.setUTCMilliseconds(999);

        return new SkdMoment(dateClone);
    }

    public static getMaxMoment(moments: SkdMoment[]) {
        const normalizedMoments = moments?.filter(moment => SkdMoment.isValidJsDate(moment?.getDate()));
        if (!normalizedMoments?.length) {
            return new SkdMoment(null, true);
        }
        let maxMoment = normalizedMoments[0];
        for (let i = 1; i < normalizedMoments.length; i++) {
            const moment = normalizedMoments[i];
            if (SkdMoment.calculateMomentDiff(maxMoment, moment).isMoment1LessThanMoment2) {
                maxMoment = moment;
            }
        }
        return maxMoment;
    }

    public static getMinMoment(moments: SkdMoment[]) {
        const normalizedMoments = moments?.filter(moment => SkdMoment.isValidJsDate(moment?.getDate()));
        if (!normalizedMoments?.length) {
            return new SkdMoment(null, true);
        }
        let minMoment = normalizedMoments[0];
        for (let i = 1; i < normalizedMoments.length; i++) {
            const moment = normalizedMoments[i];
            if (SkdMoment.calculateMomentDiff(minMoment, moment).isMoment2LessThanMoment1) {
                minMoment = moment;
            }
        }
        return minMoment;
    }

    // inclusive iterate with iteration limit
    public static async iterateByDates(startDate: SkdMoment | string, endDate: SkdMoment | string, callback: (dateSkdMoment: SkdMoment, index: number) => void | Promise<void>) {
        const startDateSkdMoment = new SkdMoment(startDate, true);
        const endDateSkdMoment = new SkdMoment(endDate, true);
        const momentDiff = SkdMoment.calculateMomentDiff(startDateSkdMoment, endDateSkdMoment);
        if (momentDiff.ceilDateAbsDay > SkdMoment.MAX_DATES_ITERATIONS_COUNT) {
            throw new Error(`iterations('${momentDiff.ceilDateAbsDay}') for ${startDateSkdMoment.toDateString(0)}—${endDateSkdMoment.toDateString(0)} more than limit('${SkdMoment.MAX_DATES_ITERATIONS_COUNT}')`);
        }

        for (let dateSkdMoment = startDateSkdMoment, i = 0; !SkdMoment.calculateMomentDiff(dateSkdMoment, endDateSkdMoment).isDate2LessThanDate1; dateSkdMoment = SkdMoment.changeMoment(dateSkdMoment, 1, Duration.Day), i++) {
            await callback(dateSkdMoment, i);
        }
    }
}

export default SkdMoment;
