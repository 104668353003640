import 'skd-ui/Button/Button.scss';

import {
    IProps,
    IState,
} from 'skd-ui/Button/Button.types';

import React, {
    PureComponent,
} from 'react';

import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';

const b = require('b_').with('button');

export {
    IProps as IButtonProps,
};

export default class _Button extends PureComponent<IProps, IState> {
    private isComponentMounted: boolean = false;

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
        };

        this.onClick = this.onClick.bind(this);
    }

    componentDidMount() {
        this.isComponentMounted = true;
    }

    componentWillUnmount() {
        this.isComponentMounted = false;
    }

    render() {
        const {
            id,
            text,
            mods = {},
            type = 'button',
            dataTest,
        } = this.props;
        const {
            loading,
        } = this.state;

        return (
            <Button
                id={id}
                className={b(mods)}
                onClick={this.onClick}
                variant="primary"
                disabled={mods.disabled || loading}
                active={mods.active}
                type={type}
                data-test={dataTest ? `button-${dataTest}` : undefined}
            >
                {loading && (
                    <Spinner
                        as={'span'}
                        animation={'border'}
                        role={'status'}
                        aria-hidden={'true'}
                        variant={'light'}
                    />
                )}
                <span>{text}</span>
            </Button>
        );
    }

    private async onClick() {
        const {
            mods = {},
        } = this.props;
        const {
            loading,
        } = this.state;

        if (mods.disabled || mods.active || loading) {
            return;
        }

        this.setState({
            loading: true,
        }, async () => {
            try {
                if (typeof this.props.onClick === 'function') {
                    await this.props.onClick();
                }
            } finally {
                if (this.isComponentMounted) {
                    this.setState({
                        loading: false,
                    });
                }
            }
        });
    }
}
